import { Button } from 'antd';
import { useSearchParams } from 'react-router-dom';

export const ClearIdFilter = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClick = (): void => {
    if (searchParams.get('installBaseIdEquals')) {
      searchParams.delete('installBaseIdEquals');
      setSearchParams(searchParams);
    }
    if (searchParams.get('intangibleAssetIdEquals')) {
      searchParams.delete('intangibleAssetIdEquals');
      setSearchParams(searchParams);
    }
  };

  return <Button onClick={handleClick}>Clear Id Filter</Button>;
};
