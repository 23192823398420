import { Button, Row, Tooltip } from 'antd';
import { FC, useState } from 'react';
import Lottie from 'react-lottie-player';
import { useSearchParams } from 'react-router-dom';
import partsLottie from '../../../../assets/parts.json';
import serviceLottie from '../../../../assets/service.json';

export const ProductTypeFilter: FC = () => {
  const [hover, setHover] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const isPhysicalParts = searchParams.get('productType') === 'physical part';

  return (
    <Row>
      <Button
        onMouseLeave={(): void => setHover('')}
        onMouseEnter={(): void => setHover('physicalParts')}
        style={{
          color: '#136538',
          backgroundColor: hover === 'physicalParts' || isPhysicalParts ? '#E5EEFF' : undefined,
          height: 40,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0
        }}
        value="physicalParts"
        onClick={(): void => {
          const params = Object.fromEntries([...searchParams]);

          setSearchParams({ ...params, productType: 'physical part' });
          //   setSelectedFilter('physicalParts');
        }}>
        <Tooltip title="Physical Parts">
          <Lottie animationData={partsLottie} loop={true} goTo={50} play={hover === 'physicalParts'} style={{ width: 25, height: 25 }} />
        </Tooltip>
      </Button>

      <Button
        onMouseLeave={(): void => setHover('')}
        onMouseEnter={(): void => setHover('intangibleItems')}
        style={{
          color: '#C70039',
          backgroundColor: hover === 'intangibleItems' || !isPhysicalParts ? '#E5EEFF' : undefined,
          height: 40,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0
        }}
        value="intangibleItems"
        onClick={(): void => {
          //   setSelectedFilter('intangibleItems');
          const params = Object.fromEntries([...searchParams]);

          setSearchParams({ ...params, productType: 'intangible asset' });
        }}>
        <Tooltip title="Intangible Items">
          <Lottie animationData={serviceLottie} loop={true} goTo={50} play={hover === 'intangibleItems'} style={{ width: 25, height: 25 }} />
        </Tooltip>
      </Button>
    </Row>
  );
};
