import { Input } from 'antd';
import { InputProps } from 'antd/lib';
import { FC } from 'react';
import { FormLabel } from './FormLabel';

interface Props extends InputProps {
  label: string;
}

export const ReadOnlyField: FC<Props> = ({ label, ...rest }) => {
  return (
    <>
      <FormLabel label={label} />
      <Input disabled {...rest} />
    </>
  );
};
