import { IntangibleItemControls } from 'components/molecules/IntangibleItemControls';
import { IntangibleAssetsTable } from 'components/organisms/IntangibleAssetsTable';
import { FC } from 'react';

export const IntangibleAssets: FC = () => {
  return (
    <>
      <IntangibleItemControls />
      <IntangibleAssetsTable />
    </>
  );
};
