import { Button, Tooltip } from 'antd';
import { FC, useState } from 'react';
import Lottie from 'react-lottie-player';
import { useNavigate } from 'react-router-dom';
import { InstallBaseResponse } from 'redux/services/captainBreakfast/install-base/installBaseApi';
import { IntAssetResponse } from 'redux/services/captainBreakfast/intangible-assets/intangibleAssetsApi';
import linkLottie from '../../assets/link.json';

type Props = {
  record: IntAssetResponse | InstallBaseResponse;
  path: string;
};

export const AssetLinkButton: FC<Props> = ({ record, path }) => {
  const nav = useNavigate();
  const [iconHover, setIconHover] = useState('');

  return (
    <Tooltip title="Assigned Assets Link">
      <Button
        style={{ backgroundColor: iconHover === record.id ? '#E5EEFF' : undefined }}
        onMouseLeave={(): void => setIconHover('')}
        onMouseEnter={(): void => setIconHover(record.id)}
        icon={<Lottie loop goTo={65} play={iconHover === record.id} style={{ width: 22, height: 22 }} animationData={linkLottie} />}
        onClick={(): void => nav(path)}
      />
    </Tooltip>
  );
};
