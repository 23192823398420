import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib';
import { Field, FieldProps, useField } from 'formik';
import { FC } from 'react';
import { FormLabel } from './FormLabel';

interface Props extends InputNumberProps {
  fieldName: string;
  placeholder?: string;
  label: string;
}

export const FieldInputNumber: FC<Props> = ({ fieldName, placeholder, label, ...rest }) => {
  const [{ value: unitCost }] = useField('unitCost');

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.code.includes('Key')) {
      event.preventDefault();
    }
  };

  return (
    <Field name={fieldName} placeholder={placeholder}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => {
        return (
          <div>
            <FormLabel label={label} />
            <InputNumber
              onKeyDown={handleKeyDown}
              min={1}
              value={field.value}
              controls={false}
              onFocus={() => setFieldTouched(fieldName, false)}
              style={{ borderColor: meta.error && meta.touched ? '#dc3545' : undefined, width: '100%' }}
              onChange={(val) => {
                if (!val) setFieldValue('totalCost', 0);
                // if (!val) setFieldValue('unitCost', 0);
                if (fieldName === 'quantity' && typeof val === 'number') {
                  setFieldValue('totalCost', (unitCost * val).toFixed(2));
                }
                setFieldValue(fieldName, val);
              }}
              {...rest}
              // {...field}
            />
            <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
          </div>
        );
      }}
    </Field>
  );
};
