import { Col, Form, Row } from 'antd';
import { FieldInputSelect } from 'components/ui/FormItems/FieldInputSelect';
import { ReadOnlyField } from 'components/ui/FormItems/ReadOnlyField';
import dayjs from 'dayjs';
import { useField } from 'formik';
import { useGetInstallBasesQuery } from 'redux/services/captainBreakfast/install-base/installBaseApi';

export const AssetDetailsForm = (): JSX.Element => {
  const { data, isLoading } = useGetInstallBasesQuery({ overrideSkipTake: true });
  const [{ value }] = useField('installBaseId');

  const installBase = data?.data.find((item) => item.id === value);

  const formatedAddress = installBase ? dayjs(installBase.inServiceDate).format('MM-DD-YYYY') : '';

  return (
    <Form layout="vertical">
      <Row gutter={[16, 32]}>
        <Col span={24}>
          <FieldInputSelect loading={isLoading} installBaseOptionsArr={data?.data} fieldName="installBaseId" label={'Install Base Id'} />
        </Col>
        <Col span={24}>
          <ReadOnlyField label="Location Code" value={installBase?.location.code} />
        </Col>
        <Col span={24}>
          <ReadOnlyField label={'Serial Number'} value={installBase?.serialNumber} />
        </Col>
        <Col span={24}>
          <ReadOnlyField value={installBase?.macAddress} label={'MAC Address'} />
        </Col>
        <Col span={24}>
          <ReadOnlyField value={installBase?.ipAddress} label={'IP Address'} />
        </Col>
        <Col span={24}>
          <ReadOnlyField label={'In Service Date'} value={formatedAddress} />
        </Col>
      </Row>
    </Form>
  );
};
