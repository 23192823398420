import { Card, Col, Form, Row, Typography } from 'antd';
import AssetStatusDropdown from 'components/atoms/AssetStatusDropdown';
import AssetTypeDropdown from 'components/atoms/AssetTypeDropdown';
import { TextInput } from 'components/ui/FormItems';
import { NoteInput } from 'components/ui/FormItems/NoteInput';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { IntangibleAssetLabels } from 'models/IntangibleAssets';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const styles: InlineStylesModel = {
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#84859a',
    justifyContent: 'space-between',
    width: '100%',
    padding: 24,
    borderBottom: '2px solid rgba(0,0,0,.1)'
  },
  headerTitle: {
    marginBottom: 0,
    fontWeight: 'normal'
  },
  container: {
    color: 'rgba(0,0,0, 0.85)',
    width: '100%'
  }
};

export const MoreDetails: React.FC = () => {
  const nav = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const isEditPage = pathname.includes('/edit');

  return (
    <>
      <div style={styles.headerContainer}>
        <Typography.Title level={4} style={styles.headerTitle}>
          More Details
        </Typography.Title>
      </div>
      <Card style={styles.container}>
        <Form layout="vertical">
          <Row gutter={[16, 32]}>
            <Col span={12}>
              <AssetTypeDropdown />
            </Col>
            <Col span={12}>
              <TextInput fieldName="manufacturer" label={IntangibleAssetLabels.manufacturer} placeholder={IntangibleAssetLabels.manufacturer} />
            </Col>
            <Col span={12}>
              <AssetStatusDropdown />
            </Col>
            <Col span={12}>
              <TextInput fieldName="contractId" label={IntangibleAssetLabels.contractId} placeholder={IntangibleAssetLabels.contractId} />
            </Col>
            <Col span={24}>
              <NoteInput fieldName="note" placeholder={IntangibleAssetLabels.description} label={IntangibleAssetLabels.description} />
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
};
