import { Select } from 'antd';
import { FormLabel } from 'components/ui/FormItems/FormLabel';
import { useFormikContext } from 'formik';
import { IntangibleAssetPayload } from 'models/IntangibleAssets';
import React from 'react';
import { useGetAssetStatusesQuery } from 'redux/services/captainBreakfast/captainBreakfastApi';

const { Option } = Select;

const AssetStatusDropdown: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<IntangibleAssetPayload>();
  const { data, isLoading } = useGetAssetStatusesQuery();

  const onChange = (value: string) => {
    setFieldValue('assetStatus', value);
  };

  const onSearch = (value: string) => {
    console.log('status:', value);
  };

  return (
    <>
      <FormLabel label={'Status'} />
      <Select
        loading={isLoading}
        showSearch
        value={values.assetStatus}
        style={{ width: '100%', paddingTop: 1 }}
        placeholder="Select a Status"
        optionFilterProp="children"
        onChange={onChange}
        onSearch={onSearch}
        filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}>
        {data?.data.map((each, index) => {
          return (
            <Option key={index} value={each.value}>
              {`${each.description}`}
            </Option>
          );
        })}
      </Select>
    </>
  );
};

export default AssetStatusDropdown;
