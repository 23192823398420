import { Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { InstallBaseCheckBox } from 'components/atoms/InstallBaseCheckBox';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import dayjs from 'dayjs';
import { useGetParams } from 'hooks/useGetParams';
import { rest } from 'lodash';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InstallBaseResponse, useGetInstallBasesQuery } from 'redux/services/captainBreakfast/install-base/installBaseApi';
import { setSelectedInstallBases, setSelectedRowKeys } from 'redux/slices/selectedEntitiesToAssign';
import { useAppSelector } from 'redux/store';

type Props = {
  quantity?: number;
};

export const AssignInstallBaseTable: FC<Props> = ({ quantity }) => {
  const { params } = useGetParams(['productNumberContains', 'nameContains', 'locationCodeContains', 'manufacturerNameContains', 'categoryNameContains', 'alternateItemIdContains']);
  const { data: installBaseData, isLoading: isInstallBaseLoading, isFetching: isInstallBaseFetching } = useGetInstallBasesQuery({ ...params, overrideSkipTake: true });
  const dispatch = useDispatch();
  const { selectedRowKeys, selectedInstallBases } = useAppSelector((state) => state.selectedEntitiesToAssign);
  const [isCellDisabled, setIsCellDisbaled] = useState<string[]>([]);

  const columns: ColumnsType<InstallBaseResponse> = [
    {
      title: 'Install Base Id',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Product Number',
      dataIndex: 'productNumber',
      key: 'productNumber',
      render: (_, record) => <div>{record.product.productNumber}</div>
    },
    {
      title: 'Alternate Item Id',
      dataIndex: 'alternateItemId',
      key: 'alternateItemId',
      render: (_, record) => <div>{record.product.alternateItemId}</div>
    },
    {
      title: 'Manufacturer',
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      render: (_, record) => <div>{record.product.manufacturer.name}</div>
    },
    {
      title: 'Location Code',
      dataIndex: 'locationCode',
      key: 'locationCode',
      render: (_, record): JSX.Element => {
        const formatedAddress = record.location ? `Address: ${record.location?.street1} ${record.location?.city}, ${record?.location.state} ${record.location?.postalCode}` : 'No Address Found';

        return (
          <Tooltip placement="topLeft" title={formatedAddress}>
            <div>{record.location.code}</div>
          </Tooltip>
        );
      }
    },
    {
      title: 'Serial Number',
      dataIndex: 'serialNumber',
      key: 'serialNumber'
    },
    {
      title: 'In Service Date',
      dataIndex: 'inServiceDate',
      key: 'inServiceDate',
      render: (_, record): JSX.Element => {
        const formatedAddress = dayjs(record.inServiceDate).format('MM-DD-YYYY');

        return <Typography>{formatedAddress}</Typography>;
      }
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity'
    }
  ];

  const rowSelection: TableRowSelection<InstallBaseResponse> = {
    checkStrictly: true,
    onChange: (selectedRowKeys: React.Key[], selectedRows: InstallBaseResponse[]): void => {
      dispatch(setSelectedInstallBases(selectedRows));
      dispatch(setSelectedRowKeys(selectedRowKeys));
    },
    getCheckboxProps: (record: InstallBaseResponse): { disabled: boolean; name: string } => ({
      disabled: (quantity === selectedInstallBases.length && !selectedInstallBases.some((base) => base.id === record.id)) || isCellDisabled?.includes(record.id),
      name: record.name
    }),
    hideSelectAll: true,
    renderCell: (value, record, index, originNode) => {
      return <InstallBaseCheckBox setIsCellDisabled={setIsCellDisbaled} record={record} originNode={originNode} />;
    },
    selectedRowKeys
  };

  if (isInstallBaseLoading) return <LoaderWithMessage loadingMessage="Loading" />;

  return (
    <Table
      loading={{ spinning: isInstallBaseFetching, indicator: <LoaderWithMessage loadingMessage="Loading" /> }}
      rowSelection={rowSelection}
      dataSource={installBaseData?.data}
      size="small"
      style={{ width: '100%' }}
      columns={columns}
      rowKey={(item): string => item.id}
      {...rest}
    />
  );
};
