import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { ApiMultipleResponse } from 'models/ApiModels';
import { partSchema } from 'models/Product';
import { setImportLoadingMessage, setIsImporting } from 'redux/slices/importSlice';
import { ReduxState } from 'redux/store';
import { CategoryResponse } from '../categories/categoriesApi';
import { LineItemErrorObjectProduct, QueryErrorModel } from '../install-base/installBaseApi';
import { ManufacturerResponse } from '../manufacturers/manufacturersApi';

/* ******************** Base Query ******************** */
const baseUrl = process.env.REACT_APP_CAPTAIN_BREAKFAST_BASE_URL;
const functionsKey = process.env.REACT_APP_API_HOST_KEY_CAPTAIN_BREAKFAST;

export type ProductPayload = {
  productNumber: string;
  description?: string;
  categoryId: string;
  manufacturerId: string;
  productType: string;
  alternateItemId?: string;
  isActive?: boolean;
  isDeleted?: boolean;
};

export type ProductParams = {
  productNumberContains?: string;
  descriptionContains?: string;
  manufacturerNameContains?: string;
  categoryNameContains?: string;
  alternateItemIdContains?: string;
  includeInactiveData?: boolean;
  returnInactiveDataOnly?: boolean;
  offset?: number;
  take?: number;
  productNumberEquals?: string;
  overrideSkipTake?: boolean;
};

export type ProductResponse = {
  alternateItemId: string;
  category: {
    description: string;
    documentType: string;
    id: string;
    name: string;
  };
  createdByUserFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  division: {
    business: {
      dataAreaId: string;
      erpId: string;
      id: string;
      name: string;
    };
    erpId: string;
    id: string;
    name: string;
  };
  documentType: string;
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  manufacturer: {
    description: string;
    documentType: string;
    id: string;
    name: string;
  };
  modifiedByUserFullname: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  partitionKey: string;
  partitionKeyDescription: string;
  description: string;
  productNumber: string;
  productType: string;
};

export const captainBreakfastBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const divisionId = (api.getState() as ReduxState).app.acuityContext?.selectedCustomer.id;
  const isDiagnostic = typeof args !== 'string' && (args.url === '/diagnostics/version' || args.url === '/diagnostics/apiName');
  const isDivisionNeeded =
    typeof args !== 'string' && (args.url === '/diagnostics/version' || args.url === '/diagnostics/apiName' || args.url === '/intangibleAssetStatuses' || args.url === '/intangibleAssetTypes');

  if (!divisionId && !isDivisionNeeded) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No division ID received'
      }
    };
  }

  const urlEnd = typeof args === 'string' ? args : args.url;
  const adjustedUrl = isDiagnostic ? args.url : `divisions/${divisionId}/${urlEnd}`;
  const adjustedArgs = typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };

  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as ReduxState).app.accessToken;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('x-functions-key', functionsKey);
        headers.set('Content-Type', 'application/json');
      }

      return headers;
    }
  })(adjustedArgs, api, extraOptions);
};

export const productsApi = createApi({
  reducerPath: 'productsApi',
  baseQuery: captainBreakfastBaseQuery,
  tagTypes: ['Products', 'Product'],
  endpoints: (builder) => ({
    getProducts: builder.query<ApiMultipleResponse<ProductResponse>, ProductParams>({
      providesTags: ['Products'],
      query: (params) => ({
        url: 'products',
        params,
        responseHandler: (response): Promise<ApiMultipleResponse<ProductResponse> | string> => (response.status >= 300 ? response.text() : response.json())
      })
    }),

    getProduct: builder.query<ProductResponse, string>({
      providesTags: ['Product'],
      query: (id) => ({
        url: `products/${id}`,
        responseHandler: (response): Promise<ApiMultipleResponse<ProductResponse> | string> => (response.status >= 300 ? response.text() : response.json())
      })
    }),
    createProduct: builder.mutation<ProductResponse, ProductPayload>({
      invalidatesTags: ['Products'],
      query: (payload) => ({
        url: 'products',
        method: 'POST',
        body: payload
      })
    }),
    updateProduct: builder.mutation<ProductResponse, { id: string; payload: ProductPayload }>({
      invalidatesTags: ['Products'],
      query: ({ id, payload }) => ({
        url: `products/${id}`,
        method: 'PUT',
        body: payload
      })
    }),
    deleteProduct: builder.mutation<void, string>({
      invalidatesTags: ['Products'],
      query: (assetID) => ({
        url: `products/${assetID}/?wouldYouLikeToPlayAGame=true`,
        method: 'DELETE'
      })
    }),
    importProduct: builder.mutation<{ data: string; lineErrors: LineItemErrorObjectProduct[] }, ProductPayload[]>({
      invalidatesTags: ['Products'],
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        queryApi.dispatch(setIsImporting(true));

        const lineErrors: LineItemErrorObjectProduct[] = [];

        for await (const [idx, payloadLine] of arg.entries()) {
          let payload: ProductPayload = { ...payloadLine, isActive: true, isDeleted: false };

          queryApi.dispatch(setImportLoadingMessage(`Importing Product Line Item ${idx + 1} of ${arg.length}`));

          try {
            await partSchema.validate(payloadLine);
          } catch (err) {
            console.log(err);
            lineErrors.push({ productNumber: payloadLine.productNumber ?? 'Error', errorMessage: (err as { message: string }).message });
            continue;
          }

          if (payloadLine.productType.toLowerCase() === 'physical part') {
            payload = { ...payload, productType: 'Physical Part' };
          } else if (payloadLine.productType.toLowerCase() === 'intangible asset') {
            payload = { ...payload, productType: 'Intangible Asset' };
          } else lineErrors.push({ productNumber: payloadLine.productNumber, errorMessage: 'Invalid product type' });

          const getManufacturer = await baseQuery(`/manufacturers`);

          if (getManufacturer.error) {
            const err = getManufacturer.error as QueryErrorModel;

            console.log(getManufacturer.error, 'manufacturer error');

            lineErrors.push({ productNumber: payloadLine.productNumber, errorMessage: err.data.errorMessage });

            continue;
          }

          const getCategory = await baseQuery(`/categories`);

          if (getCategory.error) {
            const err = getCategory.error as QueryErrorModel;

            console.log(getCategory.error, 'category error');

            lineErrors.push({ productNumber: payloadLine.productNumber, errorMessage: err.data.errorMessage });

            continue;
          }

          const manufacturer = (getManufacturer.data as ApiMultipleResponse<ManufacturerResponse>).data.find((man) => man.name === payloadLine.manufacturerId);
          const category = (getCategory.data as ApiMultipleResponse<CategoryResponse>).data.find((man) => man.name === payloadLine.categoryId);

          if (!manufacturer) {
            lineErrors.push({ productNumber: payloadLine.productNumber, errorMessage: 'Invalid manufacturer' });
            continue;
          }

          if (!category) {
            lineErrors.push({ productNumber: payloadLine.productNumber, errorMessage: 'Invalid category' });
            continue;
          }

          payload = { ...payload, categoryId: category.id, manufacturerId: manufacturer.id };

          const createResult = await baseQuery({ url: `/products`, method: 'POST', body: payload });

          if (createResult.error) {
            const err = createResult.error as QueryErrorModel;

            console.log(createResult.error, 'create error');

            lineErrors.push({ productNumber: payloadLine.productNumber, errorMessage: err.data.errorMessage });

            continue;
          }
        }

        queryApi.dispatch(setIsImporting(false));

        return { data: { data: 'Success', lineErrors } };
      }
    })
  })
});

export const { useCreateProductMutation, useDeleteProductMutation, useGetProductQuery, useGetProductsQuery, useUpdateProductMutation, useLazyGetProductsQuery, useImportProductMutation } = productsApi;
