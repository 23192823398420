import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Spin, message } from 'antd';
import { ColoredCard } from 'components/common/ColoredCard';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { IntangibleDetailsForm } from 'components/molecules/IntangibleDetailsForm';
import dayjs from 'dayjs';
import { FormikProvider, useFormik } from 'formik';
import { useState } from 'react';

import { IntAssetPayload, intangibleAssetBaseSchema, useCreateIntangibleAssetMutation, useLazyGetIntangibleAssetsQuery } from 'redux/services/captainBreakfast/intangible-assets/intangibleAssetsApi';

export const AddIntangibleAssetButton = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [create] = useCreateIntangibleAssetMutation();
  const [getLazy] = useLazyGetIntangibleAssetsQuery();
  const [isUpdating, setIsUpdating] = useState(false);

  const formik = useFormik<IntAssetPayload>({
    enableReinitialize: true,
    validationSchema: intangibleAssetBaseSchema,
    initialValues: {
      name: '',
      productId: '',
      status: '',
      type: '',
      contract: '',
      quantity: 1,
      unitCost: 0,
      totalCost: 0,
      startDate: '',
      endDate: ''
    },
    onSubmit: async (values) => {
      console.log(values);

      // if (installBaseData?.data.some((installBase) => installBase.name.toUpperCase() === values.name.toUpperCase())) return message.error('Install base already exists');

      const startDate = dayjs(values.startDate).utc().format('YYYY-MM-DD HH:mm:ss[Z]');
      const endDate = dayjs(values.endDate).utc().format('YYYY-MM-DD HH:mm:ss[Z]');

      const payload = { ...values, startDate, endDate };

      try {
        setIsUpdating(true);
        const get = await getLazy({ nameContains: values.name }).unwrap();

        if (get.data.some((intangibleAsset) => intangibleAsset.name.toUpperCase() === values.name.toUpperCase())) {
          setIsUpdating(false);

          return message.error('Intangible asset already exists');
        }

        const resp = await create(payload).unwrap();

        message.success(`Intangible asset ${resp.name} was created successfully`);
        formik.resetForm();
        setIsOpen(false);
        setIsUpdating(false);
      } catch (err) {
        console.log(err, 'err');
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
        setIsOpen(false);
        formik.resetForm();
        setIsUpdating(false);
      }
    }
  });

  return (
    <>
      <Button onClick={(): void => setIsOpen(true)} icon={<PlusCircleOutlined />}>
        Add Intangible Asset
      </Button>
      <Modal
        okText="Submit"
        width={800}
        onOk={() => formik.submitForm()}
        onCancel={(): void => {
          setIsOpen(false);
          formik.resetForm();
        }}
        closable={false}
        open={isOpen}>
        <Spin style={{ display: 'flex', alignItems: 'center' }} spinning={isUpdating} indicator={<LoaderWithMessage loadingMessage="Creating intangible asset" />}>
          <ColoredCard title="Add Intangible Asset" color="rgb(255, 234, 193)">
            <FormikProvider value={formik}>
              <IntangibleDetailsForm />
            </FormikProvider>
          </ColoredCard>
        </Spin>
      </Modal>
    </>
  );
};
