import { InfoCircleOutlined } from '@ant-design/icons';
import { Space, Tooltip, Typography } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title';
import { InlineStylesModel } from 'models/InlineStylesModel';
import React from 'react';

const styles: InlineStylesModel = {
  formLabel: {
    marginBottom: 0
  }
};

interface Props extends TitleProps {
  label: string;
  helpIcon?: boolean;
}

export const FormLabel: React.FC<Props> = ({ label, helpIcon, ...rest }) => {
  return (
    <Space>
      <Typography.Text {...rest} style={styles.formLabel}>
        {label}
      </Typography.Text>
      {helpIcon && (
        <Tooltip title="Search filter only works on location code, not the full address.">
          <InfoCircleOutlined style={{ color: '#42A8F5' }} />
        </Tooltip>
      )}
    </Space>
  );
};
