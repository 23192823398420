import { FancyInput } from 'components/atoms/Inputs';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

export const ManufacturerFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const [searchParams, setSearchParams] = useSearchParams();

  /* ******************** Functions ******************** */
  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const params = Object.fromEntries([...searchParams]);

    setSearchParams({ ...params, manufacturerNameContains: value });
    if (!value) {
      searchParams.delete('manufacturerNameContains');
      setSearchParams(searchParams);
    }
  }, 500);

  return <FancyInput defaultValue={searchParams.get('manufacturerNameContains') || ''} placeholder="Manufacturer" onChange={handleChange} />;
};
