import { Col, Form, Row, Spin } from 'antd';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { FieldInput } from 'components/ui/FormItems/FieldInput';
import { FieldInputDatePicker } from 'components/ui/FormItems/FieldInputDatePicker';
import { FieldInputNumber } from 'components/ui/FormItems/FieldInputNumber';
import { FieldInputSelect } from 'components/ui/FormItems/FieldInputSelect';
import { FC } from 'react';
import { useGetProductsQuery } from 'redux/services/captainBreakfast/products/productsApi';
import { useGetAddressesQuery } from 'redux/services/chuckieSue/addressesApi';
import { useAppSelector } from 'redux/store';

type Props = {
  isEdit?: boolean;
};

export const InstallBaseForm: FC<Props> = ({ isEdit }) => {
  const { chuckieSueAddressesParams } = useAppSelector((state) => state);
  const { data, isLoading, isFetching } = useGetAddressesQuery({ ...chuckieSueAddressesParams, overrideSkipTake: true });
  const { data: productsData, isLoading: isProdLoading } = useGetProductsQuery({ overrideSkipTake: true });

  const filteredData = productsData?.data.filter((address) => address.productType === 'Physical Part');

  return (
    <Form layout="vertical" style={{ marginBottom: 32 }}>
      <Spin spinning={isLoading || isFetching || isProdLoading} indicator={<LoaderWithMessage loadingMessage="Loading" />}>
        <Row gutter={[16, 32]}>
          <Col span={24}>
            <FieldInput forceCaps fieldName="name" label={'Install Base Id'} placeholder={'Install Base Id'} />
          </Col>
          <Col span={24}>
            <FieldInputSelect disabled={isEdit} productsOptionsArr={filteredData} fieldName="productId" label={'Product Number'} placeholder={'Product Number'} />
          </Col>
          {/* <Col span={24}>
            <TenantSelect fieldName="tenantId" label={'Tenant'} placeholder={'Tenant'} />
          </Col> */}
          <Col span={24}>
            <FieldInputSelect disabled={isEdit} helpIcon addressOptionsArr={data?.data} fieldName="locationId" label="Location Code" placeholder={'Location Code'} />
          </Col>
          <Col span={24}>
            <FieldInput forceCaps disabled={isEdit} fieldName="serialNumber" label={'Serial Number'} placeholder={'Serial Number'} />
          </Col>
          <Col span={24}>
            <FieldInput fieldName="macAddress" label={'MAC Address'} placeholder={'MAC Address'} />
          </Col>
          <Col span={24}>
            <FieldInput fieldName="ipAddress" label={'IP Address'} placeholder={'IP Address'} />
          </Col>
          <Col span={24}>
            <FieldInputNumber disabled fieldName="quantity" label={'Quantity'} placeholder={'Quantity'} />
          </Col>
          <Col span={24}>
            <FieldInputDatePicker fieldName="inServiceDate" label={'In Service Date'} />
          </Col>
          {/* <Col span={24}>
            <FieldInputSelect optionsArr={['Active', 'Inactive']} fieldName="status" label={'Status'} placeholder={'Status'} />
          </Col> */}
        </Row>
      </Spin>
    </Form>
  );
};
