import { Button, ButtonProps, Tooltip } from 'antd';
import { FC, useState } from 'react';
import Lottie from 'react-lottie-player';
import checkLottie from '../../assets/checkmark.json';
import hourglassLottie from '../../assets/hourglass.json';
import cautionLottie from '../../assets/warning.json';

type Props = ButtonProps & {
  setIsOpen: (isOpen: boolean) => void;
  status: 'Covered' | 'Expiring Soon' | 'Expired';
};

export const CoveredButton: FC<Props> = ({ setIsOpen, status, ...rest }) => {
  const [hover, setHover] = useState(false);

  const backgroundColor = status === 'Covered' ? '#ECFFF4' : status === 'Expired' ? '#FFEBEB' : status === 'Expiring Soon' ? '#FFFDD7' : '#FFF7F7';
  const animation = status === 'Covered' ? checkLottie : status === 'Expired' ? cautionLottie : hourglassLottie;

  return (
    <Tooltip title={status}>
      <Button
        //   loading={isLoading}
        //   disabled={!filteredAssignments?.length}
        onMouseEnter={(): void => setHover(true)}
        onMouseLeave={(): void => setHover(false)}
        style={{ backgroundColor: hover ? backgroundColor : undefined }}
        onClick={(): void => setIsOpen(true)}
        icon={
          <Lottie
            // segments={[5, 63]}
            color="black"
            animationData={animation}
            loop={true}
            goTo={50}
            play={hover}
            style={{ width: 22, height: 22 }}
          />
        }
        {...rest}
      />
    </Tooltip>
  );
};
