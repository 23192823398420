import { InfoCircleOutlined } from '@ant-design/icons';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Button, Popover, Row, Space, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { AssetLinkButton } from 'components/atoms/AssetLinkButton';
import { AssignIntangibleAssetButton } from 'components/atoms/AssignIntangibleAssetButton';
import { DeleteInstallBaseButton } from 'components/atoms/DeleteInstallBasrButton';
import { EditInstallBaseButton } from 'components/atoms/EditInstallBaseButton';
import { StatusInfoButton } from 'components/atoms/StatusInfoButton';
import WimsicalError from 'components/atoms/WimsicalError/WimsicalError';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { PageLoader } from 'components/common/PageLoader';
import dayjs from 'dayjs';
import { useGetParams } from 'hooks/useGetParams';
import { rest } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InstallBaseResponse, useGetInstallBasesQuery } from 'redux/services/captainBreakfast/install-base/installBaseApi';
import { useAppSelector } from 'redux/store';

export const InstallBaseTable = (): JSX.Element => {
  const [take, setTake] = useState(25);
  const [offset, setOffset] = useState(0);
  const nav = useNavigate();

  const { isImporting, importLoadingMessage } = useAppSelector((state) => state.import);

  const handlePageChange = (page: number, pageSize: number): void => {
    setTake(pageSize);
    setOffset((page - 1) * pageSize);
  };

  const { params } = useGetParams([
    'productNumberContains',
    'nameContains',
    'locationCodeContains',
    'includeInactiveData',
    'returnInactiveDataOnly',
    'manufacturerNameContains',
    'categoryNameContains',
    'inServiceStartDate',
    'inServiceEndDate'
  ]);
  const { data: installBaseData, isLoading: isInstallBaseLoading, isFetching: isInstallBaseFetching, isError, error } = useGetInstallBasesQuery({ ...params, offset, take });

  const totalCount = installBaseData?.totalCount ?? 0;

  const columns: ColumnsType<InstallBaseResponse> = [
    {
      title: 'Install Base Id',
      dataIndex: 'installBaseId',
      key: 'installBaseId',
      render: (_, record) => <EditInstallBaseButton record={record} />
    },
    {
      title: 'Product Number',
      dataIndex: 'productNumber',
      key: 'productNumber',
      render: (_, record) => (
        <Row align={'middle'}>
          <Popover
            content={
              <Space direction="vertical">
                <Typography>Alternate Item Id: {record.product.alternateItemId}</Typography>
                <Typography>Manufacturer: {record.product.manufacturer?.name}</Typography>
                <Typography>Category: {record.product.category?.name}</Typography>
              </Space>
            }>
            <InfoCircleOutlined style={{ color: 'black' }} />
          </Popover>
          <Button style={{ marginLeft: 3, paddingLeft: 3 }} type="link" onClick={(): void => nav(`/parts-master?productNumberContains=${record.product.productNumber}&isEdit=true`)}>
            {record.product.productNumber}
          </Button>
        </Row>
      )
    },
    // {
    //   title: 'Tenant',
    //   dataIndex: 'tenantId',
    //   key: 'tenantId'
    // },
    {
      title: 'Location Code',
      dataIndex: 'locationCode',
      key: 'locationCode',
      render: (_, record): JSX.Element => {
        const formatedAddress = record.location ? `Address: ${record.location?.street1} ${record.location?.city}, ${record?.location.state} ${record.location?.postalCode}` : 'No Address Found';

        return (
          <Tooltip placement="topLeft" title={formatedAddress}>
            <div>{record.location.code}</div>
          </Tooltip>
        );
      }
    },
    {
      title: 'Mac Address',
      dataIndex: 'macAddress',
      key: 'macAddress'
    },
    {
      title: 'IP Address',
      dataIndex: 'ipAddress',
      key: 'ipAddress'
    },
    {
      title: 'Serial Number',
      dataIndex: 'serialNumber',
      key: 'serialNumber'
    },
    {
      title: 'In Service Date',
      dataIndex: 'inServiceDate',
      key: 'inServiceDate',
      render: (_, record): JSX.Element => {
        const formatedAddress = dayjs(record.inServiceDate).format('MM-DD-YYYY');

        return <Typography>{formatedAddress}</Typography>;
      }
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => <Typography>{record.isActive ? 'Active' : 'Inactive'}</Typography>
    },
    {
      title: 'Actions',
      align: 'center',
      render(_, record): JSX.Element {
        return (
          <Space>
            <StatusInfoButton record={record} />
            <AssignIntangibleAssetButton record={record} />
            <AssetLinkButton record={record} path={`/assets?installBaseIdEquals=${record.id}`} />
            <DeleteInstallBaseButton installBaseId={record.id} />
          </Space>
        );
      }
    }
  ];

  if (isInstallBaseLoading) return <PageLoader loadingMessage="Loading install bases" />;

  if (isError) {
    const errData = error as FetchBaseQueryError;

    return (
      <WimsicalError
        title={(errData.data as string) || 'Oops something went wrong'}
        statusCode={errData.status}
        subTitle={'Please try to reload the app and try again'}
        redirectText={'Reload'}
        redirectUrl={process.env.REACT_APP_REDIRECT_URL}
      />
    );
  }

  return (
    <Table
      loading={{ spinning: isInstallBaseFetching || isImporting, indicator: <LoaderWithMessage loadingMessage={isImporting ? importLoadingMessage : 'Loading'} /> }}
      pagination={{ total: totalCount, defaultPageSize: take, onChange: handlePageChange, pageSizeOptions: ['10', '20', '25', '50', '100'] }}
      dataSource={installBaseData?.data}
      size="small"
      columns={columns}
      rowKey={(item): string => item.name}
      scroll={{ x: 'max-content' }}
      {...rest}
    />
  );
};
