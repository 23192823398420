import { Input } from 'antd';
import { InputProps } from 'antd/lib';
import { Field, FieldProps, FormikErrors } from 'formik';
import { FC } from 'react';
import { FormLabel } from './FormLabel';

interface Props extends InputProps {
  fieldName: string;
  placeholder?: string;
  type?: string;
  label: string;
  forceCaps?: boolean;
}

export const FieldInput: FC<Props> = ({ fieldName, placeholder, type, label, forceCaps, ...rest }) => {
  return (
    <Field name={fieldName} placeholder={placeholder}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => {
        return (
          <div>
            <FormLabel label={label} />
            <Input
              {...field}
              onChange={(e): void => {
                if (forceCaps) e.target.value = e.target.value.toUpperCase();
                field.onChange(e); // Using Formik's handler after our transformation
              }}
              onFocus={(): Promise<void | FormikErrors<any>> => setFieldTouched(fieldName, false)}
              style={{ borderColor: meta.error && meta.touched ? '#dc3545' : undefined }}
              type={type}
              {...rest}
            />
            <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
          </div>
        );
      }}
    </Field>
  );
};
