import { CaretRightOutlined, SaveOutlined } from '@ant-design/icons';
import { Card, Col, Dropdown, Input, List, Menu, Modal, Row, Space, Spin, Switch, Typography, notification } from 'antd';
import { ModalProps } from 'antd/lib';
import { FC, Fragment, useState } from 'react';

type UpdateUserPayload = {
  email?: string;
  givenName?: string;
  familyName?: string;
  phoneNumber?: string | null;
  nickname?: string;
  enableStandardOrderVerificationSMSNotifications?: boolean;
  enableStandardOrderShipmentSMSNotifications?: boolean;
  enableSparingSMSNotifications?: boolean;
  standardOrderVerificationComplianceText?: string;
  sparingNotificationsComplianceText?: string;
  standardOrderShipmentNotificationsComplianceText?: string;
};

export type User = {
  name?: string;
  given_name?: string;
  family_name?: string;
  middle_name?: string;
  nickname?: string;
  preferred_username?: string;
  profile?: string;
  picture?: string;
  website?: string;
  email?: string;
  email_verified?: boolean;
  gender?: string;
  birthdate?: string;
  zoneinfo?: string;
  locale?: string;
  phone_number?: string;
  phone_number_verified?: boolean;
  address?: string;
  updated_at?: string;
  sub?: string;
  [key: string]: any;
};
const compliance = {
  text: 'By clicking Confirm, you agree to receive text messages from MDSi to the phone number you provided. Messages will include alerts and updates. Unsubscribe: You can reply STOP at any time to cancel. Assistance: Reply HELP for help. Fees: Message and data rates may apply.',
  terms: ['Unsubscribe: You can reply STOP at any time to cancel.', 'Assistance: Reply HELP for help.', 'Fees: Message and data rates may apply.']
};

type ComplianceModalProps = ModalProps & {
  label: string;
  fieldName: string;
  fieldNameComplianceText: string;
  setFormState: (state: UpdateUserPayload) => void;
  formState: UpdateUserPayload;
};

type Props = {
  user?: User;
  token: string | null;
  baseUrl: string;
  apiKey: string;
  loadingIndicator?: JSX.Element;
  navigate: (path: string) => void;
  updateReduxUser: (user: User) => void;
};
const ComplianceModal: FC<ComplianceModalProps> = ({ label, fieldName, fieldNameComplianceText, setFormState, formState, ...rest }) => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <Fragment>
      <Space size={2} direction="vertical" style={{ width: '100%' }}>
        <Typography.Text strong>{label}</Typography.Text>
        <Switch
          onClick={(checked): void => {
            if (checked) {
              setVisible(true);
            } else {
              setFormState({ ...formState, [fieldName]: false, [fieldNameComplianceText]: '' });
            }
          }}
          checked={formState[fieldName as keyof UpdateUserPayload] as boolean}
        />
      </Space>
      <Modal
        open={visible}
        width={500}
        okText="Confirm"
        onCancel={(): void => setVisible(false)}
        title="A2P 10LP Compliance"
        onOk={(): void => {
          setFormState({ ...formState, [fieldName]: true, [fieldNameComplianceText]: compliance.text });
          setVisible(false);
        }}
        {...rest}>
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <Typography.Text>{compliance.text}</Typography.Text>
          </Col>
          <Col span={24}>
            <List
              size="small"
              dataSource={compliance.terms}
              renderItem={(item): JSX.Element => {
                return (
                  <Space>
                    <CaretRightOutlined />
                    <Typography.Text>{item}</Typography.Text>
                  </Space>
                );
              }}
            />
          </Col>
        </Row>
      </Modal>
    </Fragment>
  );
};

export const UserProfile: FC<Props> = ({ user, token, baseUrl, apiKey, loadingIndicator, navigate, updateReduxUser }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [formState, setFormState] = useState<UpdateUserPayload>(() => ({
    email: user?.email ?? '',
    givenName: user?.given_name ?? '',
    familyName: user?.family_name ?? '',
    phoneNumber: user ? user['https://acuity.mdsiinc.com/user/user_metadata'].phone_number : '',
    enableStandardOrderVerificationSMSNotifications:
      user && user['https://acuity.mdsiinc.com/user/user_metadata'].enableStandardOrderVerificationSMSNotifications
        ? user['https://acuity.mdsiinc.com/user/user_metadata'].enableStandardOrderVerificationSMSNotifications
        : false,
    standardOrderVerificationComplianceText:
      user && user['https://acuity.mdsiinc.com/user/user_metadata'].standardOrderVerificationComplianceText
        ? user['https://acuity.mdsiinc.com/user/user_metadata'].standardOrderVerificationComplianceText
        : '',
    enableStandardOrderShipmentSMSNotifications:
      user && user['https://acuity.mdsiinc.com/user/user_metadata'].enableStandardOrderShipmentSMSNotifications
        ? user['https://acuity.mdsiinc.com/user/user_metadata'].enableStandardOrderShipmentSMSNotifications
        : false,
    standardOrderShipmentNotificationsComplianceText:
      user && user['https://acuity.mdsiinc.com/user/user_metadata'].standardOrderShipmentNotificationsComplianceText
        ? user['https://acuity.mdsiinc.com/user/user_metadata'].standardOrderShipmentNotificationsComplianceText
        : '',
    enableSparingSMSNotifications:
      user && user['https://acuity.mdsiinc.com/user/user_metadata'].enableSparingSMSNotifications ? user['https://acuity.mdsiinc.com/user/user_metadata'].enableSparingSMSNotifications : false,
    sparingNotificationsComplianceText:
      user && user['https://acuity.mdsiinc.com/user/user_metadata'].sparingNotificationsComplianceText ? user['https://acuity.mdsiinc.com/user/user_metadata'].sparingNotificationsComplianceText : ''
  }));

  const handleSave = async (exit: boolean): Promise<void> => {
    if (!user) {
      return notification.error({ message: 'User not found' });
    }

    if (!token) {
      return notification.error({ message: 'Access token not found. Please refresh the page and try again.' });
    }

    try {
      setIsLoading(true);
      const repsonse = await fetch(`${baseUrl}/users/${user.sub}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'x-functions-key': `${apiKey}`,
          authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(formState)
      });

      const data: User = await repsonse.json();

      const {
        given_name,
        user_metadata: { phone_number },
        family_name,
        email,
        name
      } = data;

      notification.success({ message: `User: ${given_name} updated successfully` });
      updateReduxUser({ ...data, ...{ given_name, family_name, phone_number: typeof phone_number === 'string' ? phone_number : undefined, email, name } });
      setIsLoading(false);
      if (exit) {
        navigate('/');

        return;
      }
    } catch (error) {
      console.log(error, 'error');
      notification.error({ message: 'Failed to update user' });
      setIsLoading(false);
    }
  };

  const menuJSX = (
    <Menu disabledOverflow={true}>
      <Menu.Item key="1" onClick={(): Promise<void> => handleSave(false)}>
        Save
      </Menu.Item>
    </Menu>
  );

  return (
    <Fragment>
      <Spin spinning={isLoading} indicator={loadingIndicator ?? undefined}>
        <Row justify="space-between" style={{ padding: 10, background: 'rgb(206, 213, 242)' }}>
          <Col>
            <Typography.Title level={3} style={{ margin: 0, fontWeight: 'normal' }}>
              Edit Profile
            </Typography.Title>
          </Col>
          <Col>
            <Dropdown.Button overlay={menuJSX} onClick={(): Promise<void> => handleSave(true)} type="primary" icon={<SaveOutlined />}>
              Save and Exit
            </Dropdown.Button>
          </Col>
        </Row>
        <Card>
          <Row gutter={[32, 16]}>
            <Col span={12}>
              <Space size={2} direction="vertical" style={{ width: '100%' }}>
                <Typography.Text strong>First Name</Typography.Text>
                <Input
                  placeholder="First Name"
                  value={formState.givenName}
                  onChange={(e): void => {
                    setFormState({ ...formState, givenName: e.target.value });
                  }}
                />
              </Space>
            </Col>
            <Col span={12}>
              <Space size={2} direction="vertical" style={{ width: '100%' }}>
                <Typography.Text strong>Last Name</Typography.Text>
                <Input
                  value={formState.familyName}
                  placeholder="Last Name"
                  onChange={(e): void => {
                    setFormState({ ...formState, familyName: e.target.value });
                  }}
                />
              </Space>
            </Col>
            <Col span={12}>
              <Space size={2} direction="vertical" style={{ width: '100%' }}>
                <Typography.Text strong>Phone Number</Typography.Text>
                <Input
                  value={formState?.phoneNumber ?? ''}
                  placeholder="Phone Number"
                  onChange={(e): void => {
                    setFormState({ ...formState, phoneNumber: e.target.value });
                  }}
                />
              </Space>
            </Col>
            <Col span={12}>
              <Space size={2} direction="vertical" style={{ width: '100%' }}>
                <Typography.Text strong>Email</Typography.Text>
                <Input
                  value={formState.email}
                  placeholder="Email"
                  onChange={(e): void => {
                    setFormState({ ...formState, email: e.target.value });
                  }}
                />
              </Space>
            </Col>
            <Col span={12}>
              <ComplianceModal
                label="Enable SMS Updates for Standard Order Verification"
                setFormState={setFormState}
                fieldName="enableStandardOrderVerificationSMSNotifications"
                fieldNameComplianceText="standardOrderVerificationComplianceText"
                formState={formState}
              />
            </Col>
            <Col span={12}>
              <ComplianceModal
                label="Enable SMS Updates for Standard Order Shipment Notifications"
                setFormState={setFormState}
                fieldName="enableStandardOrderShipmentSMSNotifications"
                fieldNameComplianceText="standardOrderShipmentNotificationsComplianceText"
                formState={formState}
              />
            </Col>
            <Col span={12}>
              <ComplianceModal
                label="Enable SMS Updates for Sparing Notifications"
                setFormState={setFormState}
                fieldName="enableSparingSMSNotifications"
                fieldNameComplianceText="sparingNotificationsComplianceText"
                formState={formState}
              />
            </Col>
          </Row>
        </Card>
      </Spin>
    </Fragment>
  );
};
