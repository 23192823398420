import * as yup from 'yup';
import { Division } from './Division';
export enum IntangibleAssetLabels {
  intangibleAssetNumber = 'Intangible Asset Number',
  assetSku = 'SKU/Part Number',
  assetOwner = 'Asset Owner',
  contractId = 'Contract ID',
  description = 'Description',
  manufacturer = 'Manufacturer',
  serial = 'Serial No.',
  partNumber = 'Part Number',
  port = 'Port Number',
  location = 'Location',
  totalQuantity = 'Total Quantity',
  unitCost = 'Unit Cost',
  subscriptionType = 'Type',
  startDate = 'Start Date',
  endDate = 'End Date',
  status = 'Status',
  children = 'Children'
}

export interface AssetAssignmentIntangibleAsset {
  id: string;
  intangibleAssetNumber: string;
}

export interface AssetAssignment {
  assignedQuantity: number;
  auditMessages: string[];
  createdByUserFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  division: Division;
  documentType: string;
  id: string;
  intangibleAsset: AssetAssignmentIntangibleAsset;
  location: string | null;
  modifiedByUserFullName: string;
  modifiedByUserId: string;
  modifiedByDateTime: string;
  note: string;
  partDescription: string | null;
  partitionKey: string;
  partitionKeyDescription: string;
  partNumber: string;
  port: string;
  serialNumber: string;
}

export interface AssetAssignmentResponse {
  totalCount: number;
  data: AssetAssignment[];
}

export interface AssetAssignmentPayload {
  key?: string;
  assetId?: string;
  id?: string;
  serialNumber: string;
  partNumber: string;
  port: string;
  location: string | null;
  assignedQuantity: number;
  note: string;
}

export interface IntangibleAssetsResponse {
  assetAssignments?: AssetAssignmentPayload[];
  children?: AssetAssignmentPayload[];
  assetOwner: string;
  assetSku: string;
  assetStatus: string;
  assetType: string;
  auditMessages: string[];
  assignedQuantity: number;
  availableQuantity: number;
  contractId: string;
  createdByUserFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  division: Division;
  documentType: string;
  endDate: string;
  id: string;
  intangibleAssetNumber: string;
  isActive: boolean;
  isDeleted: boolean;
  lastValidatedByUserFullName: string;
  lastValidatedByUserId: string;
  lastValidatedDate: string;
  location: string;
  manufacturer: string;
  modifiedByUserFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  note: string;
  partitionKey: string;
  partitionKeyDescription: string;
  startDate: string;
  totalQuantity: number;
  unitCost: number;
}

export interface IntangibleAssetPayload {
  id?: string | null;
  assetAssignments?: AssetAssignmentPayload[];
  assetOwner?: string | null;
  assetSku?: string | null;
  assetStatus: string;
  assetType: string;
  location?: string | null;
  contractId?: string | null;
  endDate?: string | null;
  intangibleAssetNumber: string;
  manufacturer?: string | null;
  startDate?: string | null;
  totalQuantity: number;
  unitCost: number;
  note?: string | null;
}
export interface AssetPayload {
  locationCode: string;
  itemId: string;
  serialNumber: string;
  macAddress: string;
  ipAddress: string;
  inServiceDate: string;
  status: string;
  intangibleItem?: IntangibleAssetPayload;
}

export interface IntangibleItemPayload {
  intangibleItemId: string;
  type: string;
  assetStartDate: string;
  assetEndDate: string;
  contract: string;
  unitCost: number;
}

export interface IntangibleAssetImportPayload {
  assetAssignments?: AssetAssignmentPayload[];
  assetOwner: string;
  assetStatus: string;
  assetSKU_partNumber: string;
  assetAssignmentLocation: string;
  physicalPartNumber: string;
  headerNote: string;
  assetAssignmentNote: string;
  assetType: string;
  contractId: string;
  endDate: string;
  intangibleAssetNumber: string;
  manufacturer: string;
  startDate: string;
  totalQuantity: number;
  unitCost: number;
  serialNumber: string;
  partNumber: string;
  port: string;
  unitPrice: number;
  locationId: string;
  assignedQuantity: number;
  note: string;
  uniqueIdentifier?: string;
}

export interface IntangibleAssetHeaderPayload {
  intangibleAssetNumber: string;
  assetOwner: string;
  assetSku: string;
  contractId: string;
  note: string;
  endDate: string;
  isActive: true;
  manufacturer: string;
  startDate: string;
  assetStatus: string;
  totalQuantity: number;
  assetType: string;
  unitCost: number;
}

export interface IntangibleAssetParams {
  assetType?: string;
  assetSkuContains?: string;
  assetStatus?: string;
  assetOwnerContains?: string;
  manufacturerContains?: string;
  includeDeletedData?: boolean;
  returnDeletedDataOnly?: boolean;
  includeInactiveData?: boolean;
  returnInactiveDataOnly?: boolean;
  expirationStartDate?: string;
  expirationEndDate?: string;
  offset: number;
  orderByField?: string;
  assetId?: string;
  intangibleAssetNumberContains?: string;
  orderByDirection?: string;
  locationContains?: string;
  serialNumberContains?: string;
  partNumberContains?: string;
  take?: string;
}

export interface AssetStatuses {
  description: string;
  id: string;
  value: string;
}

export type IntangibleAssetUpdated = {
  intangibleAssetId: string;
  intangibleItemId: string;
  type: string;
  status: string;
  contract: string;
  quantity: number;
  unitCost: number;
  totalCost: number;
  startDate: string;
  endDate: string;
};

export interface AssetStatusesResponse {
  totalCount: number;
  data: AssetStatuses[];
}

export type IntangibleItem = {
  intangibleItemId: string;
  itemDescription: string;
  manufacturerName: string;
  categoryName: string;
};

export const createIntangibleAssetPayload: yup.SchemaOf<IntangibleAssetPayload> = yup.object({
  id: yup.string().label('Asset Owner').nullable(),
  assetAssignments: yup.array(),
  manufacturer: yup.string().label('Manufacturer').nullable(),
  location: yup.string().label('Location ID').nullable(),
  unitCost: yup.number().label('Unit Cost').required(),
  totalQuantity: yup.number().label('Total Quantity').required(),
  assetType: yup.string().label('Type').required(),
  startDate: yup.string().label('Start Date').nullable(),
  endDate: yup.string().label('End Date').nullable(),
  assetStatus: yup.string().label('Status').required(),
  contractId: yup.string().label('Contract ID').nullable(),
  intangibleAssetNumber: yup.string().label('Asset ID').required(),
  assetSku: yup.string().label('SKU Number').nullable(),
  note: yup.string().label('Note').nullable(),
  assetOwner: yup.string().label('Asset Owner').nullable()
});
