import { Button, ButtonProps, Space } from 'antd';
import dayjs from 'dayjs';
import { AssetAssignmentResponse } from 'redux/services/captainBreakfast/asset-assignments/assetAssignmentApi';
import { InstallBaseResponse } from 'redux/services/captainBreakfast/install-base/installBaseApi';
import { IntAssetResponse } from 'redux/services/captainBreakfast/intangible-assets/intangibleAssetsApi';
import { ProductResponse } from 'redux/services/captainBreakfast/products/productsApi';
import * as xlsx from 'xlsx';

type Props<T> = ButtonProps & {
  data: T[];
  exportDocumentName: string;
  entityName?: string;
};

function ExportAssetsButton<T>({ data, exportDocumentName, entityName, ...rest }: Props<T>): JSX.Element {
  const designateEntity = () => {
    switch (entityName) {
      case 'installBase': {
        const installBaseData = data as InstallBaseResponse[];

        return installBaseData.map(({ name, product, location, serialNumber, quantity, inServiceDate }) => {
          return {
            ['Install Base Id']: name ?? '',
            ['Product Number']: product?.productNumber,
            ['Location Code']: location?.code,
            ['Serial Number']: serialNumber ?? '',
            ['Qty']: quantity ?? '',
            ['In Service Date']: dayjs(inServiceDate).format('MM-DD-YYYY') ?? ''
          };
        });
      }

      case 'partsMaster': {
        const partsMasterData = data as ProductResponse[];

        return partsMasterData.map(({ productNumber, alternateItemId, manufacturer, category, productType, isActive }) => {
          return {
            ['Product Number']: productNumber ?? '',
            ['Alternate Item Id']: alternateItemId ?? '',
            ['Manufacturer']: manufacturer?.name ?? '',
            ['Category']: category?.name ?? '',
            ['Product Type']: productType ?? '',
            ['Status']: isActive ? 'Active' : 'Inactive' ?? ''
          };
        });
      }

      case 'intangibleAssets': {
        const intangibleData = data as IntAssetResponse[];

        return intangibleData.map(({ name, product, status, type, contract, quantity, unitCost, totalCost, startDate, endDate, isActive }) => {
          return {
            ['Intangible Asset Id']: name ?? '',
            ['Product Number']: product?.productNumber ?? '',
            ['Status']: status ?? '',
            ['Type']: type ?? '',
            ['Contract']: contract ?? '',
            ['Quantity']: quantity ?? '',
            ['Unit Cost']: unitCost ?? '',
            ['Total Cost']: totalCost ?? '',
            ['Start Date']: dayjs(startDate).format('MM-DD-YYYY') ?? '',
            ['End Date']: dayjs(endDate).format('MM-DD-YYYY') ?? '',
            ['Is Active']: isActive ? 'Active' : 'Inactive' ?? ''
          };
        });
      }

      default: {
        const assetData = data as AssetAssignmentResponse[];

        return assetData.map(({ installBase, status, intangibleAsset, startDate, endDate, isActive }) => {
          return {
            ['Product Number']: installBase?.product?.productNumber ?? '',
            ['Alternate Item Id']: installBase?.product?.alternateItemId ?? '',
            ['Location Code']: installBase?.location?.code ?? '',
            ['Install Base Id']: installBase?.name ?? '',
            ['Intangible Asset Id']: intangibleAsset?.name ?? '',
            ['Manufacturer']: installBase?.product?.manufacturer?.name ?? '',
            ['Category']: installBase?.product?.category?.name,
            ['Status']: status ?? '',
            ['Asset Type']: intangibleAsset?.type ?? '',
            ['In Service Date']: dayjs(installBase?.inServiceDate).format('MM-DD-YYYY') ?? '',
            ['Start Date']: dayjs(startDate).format('MM-DD-YYYY') ?? '',
            ['End Date']: dayjs(endDate).format('MM-DD-YYYY') ?? '',
            ['Is Active']: isActive ? 'Active' : 'Inactive'
          };
        });
      }
    }
  };

  // const dataToExport = data.map((item: T) => {
  //   let obj: { [key: string]: T } = {};

  //   Object.keys(item as typeof obj).forEach((key) => {
  //     obj = { ...obj, [camelCaseToFormattedString(key)]: (item as typeof obj)[key] };
  //   });

  //   return obj;
  // });

  const handleExportAssets = async (): Promise<void> => {
    const worksheeet = xlsx.utils.json_to_sheet(designateEntity());
    const workbook = xlsx.utils.book_new();

    xlsx.utils.book_append_sheet(workbook, worksheeet, exportDocumentName);
    xlsx.writeFile(workbook, `${exportDocumentName}.xlsx`);
  };

  return (
    <Space>
      <Button {...rest} onClick={handleExportAssets}>
        Export
      </Button>
    </Space>
  );
}

export default ExportAssetsButton;
