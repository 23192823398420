import { QuestionOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ButtonProps } from 'antd/lib';
import { ColoredCard } from 'components/common/ColoredCard';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import dayjs from 'dayjs';
import React, { FC, useState } from 'react';
import { AssetAssignmentResponse, useDeleteAssetAssignmentMutation, useGetAssetAssignmentsQuery } from 'redux/services/captainBreakfast/asset-assignments/assetAssignmentApi';
import { InstallBaseResponse } from 'redux/services/captainBreakfast/install-base/installBaseApi';
import { CoveredButton } from './CoveredButton';
import { DeleteRecordButton } from './DeleteRecordButton';

type Props = ButtonProps & {
  record: InstallBaseResponse;
};

const checkDate = (dateToCheck: string): boolean => {
  const today = dayjs();
  const dateAfter90Days = today.add(90, 'day');
  const givenDate = dayjs(dateToCheck);

  return givenDate.isAfter(today) && givenDate.isBefore(dateAfter90Days);
};

const checkExpired = (dateToCheck: string): boolean => {
  const today = dayjs();
  const givenDate = dayjs(dateToCheck);

  return givenDate.isBefore(today) || today.format('MM-DD-YYYY') === givenDate.format('MM-DD-YYYY');
};

export const StatusInfoButton: FC<Props> = ({ record }) => {
  const [deleteAssetAssignment] = useDeleteAssetAssignmentMutation();
  const { data, isLoading, isFetching } = useGetAssetAssignmentsQuery({ installBaseIdEquals: record.id }, { skip: !record.id });

  const filteredAssignments = data?.data;
  const [isOpen, setIsOpen] = useState(false);

  const calculateStatus = (): { icon: React.ReactNode } => {
    let obj: { icon: React.ReactNode } = {
      icon: (
        <Tooltip title="No asset assignments">
          <Button loading={isLoading} disabled icon={<QuestionOutlined />} />
        </Tooltip>
      )
    };

    if (filteredAssignments?.some((assignment) => checkDate(assignment.intangibleAsset.endDate))) obj = { icon: <CoveredButton loading={isLoading} status="Expiring Soon" setIsOpen={setIsOpen} /> };
    if (filteredAssignments?.some((assignment) => checkExpired(assignment.intangibleAsset.endDate))) obj = { icon: <CoveredButton loading={isLoading} status="Expired" setIsOpen={setIsOpen} /> };
    if (filteredAssignments?.some((assignment) => !checkDate(assignment.intangibleAsset.endDate) && !checkExpired(assignment.intangibleAsset.endDate)))
      obj = { icon: <CoveredButton loading={isLoading} status="Covered" setIsOpen={setIsOpen} /> };

    return obj;
  };

  const columns: ColumnsType<AssetAssignmentResponse> = [
    {
      title: 'Intangible Asset Id',
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => <div>{record.intangibleAsset.name}</div>
    },
    {
      title: 'Product Number',
      dataIndex: 'productNumber',
      key: 'productNumber',
      render: (value, record) => <div>{record.intangibleAsset.product.productNumber}</div>
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (value, record) => <div>{record.intangibleAsset.type}</div>
    },
    {
      title: 'Manufacturer',
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      render: (value, record) => <div>{record.intangibleAsset.product.manufacturer.name}</div>
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (value, record) => <div>{record.intangibleAsset.quantity}</div>
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (_, record): JSX.Element => {
        const formatedAddress = dayjs(record.intangibleAsset.startDate).format('MM-DD-YYYY');

        return <Typography>{formatedAddress}</Typography>;
      }
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (_, record): JSX.Element => {
        const formatedAddress = dayjs(record.intangibleAsset.endDate).format('MM-DD-YYYY');

        // console.log(isWithin90Days(record.endDate), 'LOL');

        return <Typography>{formatedAddress}</Typography>;
      }
    },
    {
      title: 'Delete',
      render: (_, record): JSX.Element => {
        return <DeleteRecordButton title="Are you sure you want to delete this asset assignment?" deleteRecord={deleteAssetAssignment} recordToDelete={record.id} />;
      },
      align: 'center'
    }
  ];

  return (
    <>
      {calculateStatus().icon}
      <Modal cancelButtonProps={{ style: { visibility: 'hidden' } }} okText="Exit" closable={false} onOk={(): void => setIsOpen(false)} open={isOpen && !!filteredAssignments?.length} width={950}>
        <ColoredCard title={`Asset assignments associated with ${record.name}`} color="rgb(206, 213, 242)">
          {!!filteredAssignments?.length && (
            <Table loading={{ spinning: isFetching, indicator: <LoaderWithMessage loadingMessage="Loading" /> }} pagination={false} dataSource={filteredAssignments} columns={columns} size="small" />
          )}
        </ColoredCard>
      </Modal>
    </>
  );
};
