import { Col, Row, Typography } from 'antd';
import React from 'react';
import '../../common/styles/loaderStyle.less';

interface DirectionProps {
  style?: React.CSSProperties;
  message?: string;
  small?: boolean;
  medium?: boolean;
  loadingMessage?: string;
}

export const SizedLoader = ({ message, small, medium, loadingMessage }: DirectionProps): JSX.Element => {
  return (
    <Row>
      <Col span={24} style={{ height: 65 }}>
        <div
          className="loader loader--slideUp"
          style={message ? { marginBottom: 0, height: 100, marginTop: 100 } : small ? { transform: 'scale(.4)' } : medium ? { transform: 'scale(.7)' } : undefined}>
          <span className="loader-item">1</span>
          <span className="loader-item">2</span>
          <span className="loader-item">3</span>
          <span className="loader-item">4</span>
          <span className="loader-item">5</span>
          <span className="loader-item">6</span>
        </div>
      </Col>
      {loadingMessage && (
        <Col span={24}>
          <Typography.Text style={{ fontSize: 18, fontWeight: 600, color: 'rgba(0,0,0,.8)' }}>{loadingMessage}</Typography.Text>
        </Col>
      )}
    </Row>
  );
};
