import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { ApiMultipleResponse } from 'models/ApiModels';
import { AssetAssignmentPayload, AssetAssignmentResponse, AssetStatusesResponse, IntangibleAssetParams, IntangibleAssetPayload, IntangibleAssetsResponse } from 'models/IntangibleAssets';
import { ReduxState } from 'redux/store';

/* ******************** Base Query ******************** */
const baseUrl = process.env.REACT_APP_CAPTAIN_BREAKFAST_BASE_URL;
const functionsKey = process.env.REACT_APP_API_HOST_KEY_CAPTAIN_BREAKFAST;

export const captainBreakfastBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const divisionId = (api.getState() as ReduxState).app.acuityContext?.selectedCustomer.id;
  const isDivisionNeeded =
    typeof args !== 'string' && (args.url === '/diagnostics/version' || args.url === '/diagnostics/apiName' || args.url === '/intangibleAssetStatuses' || args.url === '/intangibleAssetTypes');

  if (!divisionId && !isDivisionNeeded) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No division ID received'
      }
    };
  }

  const urlEnd = typeof args === 'string' ? args : args.url;
  const adjustedUrl = isDivisionNeeded ? args.url : `divisions/${divisionId}/${urlEnd}`;
  const adjustedArgs = typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };

  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as ReduxState).app.accessToken;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('x-functions-key', functionsKey);
        headers.set('Content-Type', 'application/json');
      }

      return headers;
    }
  })(adjustedArgs, api, extraOptions);
};

export const captainBreakfastApi = createApi({
  reducerPath: 'captainBreakfastApi',
  baseQuery: captainBreakfastBaseQuery,
  tagTypes: ['Assets', 'Asset', 'Assignments', 'ChartData'],
  endpoints: (builder) => ({
    getIntangibleAssets: builder.query<ApiMultipleResponse<IntangibleAssetsResponse>, IntangibleAssetParams>({
      providesTags: ['Assets'],
      query: (params) => ({
        url: 'intangibleAssets',
        params
      })
    }),
    // getIntangibleAssetChartData: builder.query<ChartDataModel, IntangibleAssetParams>({
    //   providesTags: ['ChartData'],
    //   query: (params) => ({
    //     url: 'intangibleAssets',
    //     params
    //   }),
    //   transformResponse: (data: ApiMultipleResponse<IntAssetResponse>) => {
    //     console.log(data.data, 'look');
    //     const assignmentChart = assignmentsChartGenerator(data.data);
    //     const typeChart = typeChartGenerator(data.data);
    //     const statusChart = statusChartGenerator(data.data);
    //     const ownerChart = ownerChartGenerator(data.data);
    //     const expiringChart = expiringChartGenerator(data.data);
    //     const fullData = data.data;

    //     return { assignmentChart, typeChart, statusChart, ownerChart, expiringChart, fullData };
    //   }
    // }),
    getIntangibleAsset: builder.query<IntangibleAssetsResponse, string>({
      providesTags: ['Asset'],
      query: (id) => ({
        url: `intangibleAssets/${id}`
      })
    }),
    getIntangibleAssetAssignments: builder.query<AssetAssignmentResponse, string>({
      providesTags: ['Assignments'],
      query: (id) => ({
        url: `intangibleAssets/${id}/assetAssignments`
      }),
      transformResponse: (data: AssetAssignmentResponse) => ({
        ...data,

        ...{
          data: data.data.map((assignment, index) => {
            const withChildren = { ...assignment, ...{ key: index.toString() } };

            return withChildren;
          })
        }
      })
    }),
    createIntangibleAssetAssignments: builder.mutation<AssetAssignmentResponse, AssetAssignmentPayload>({
      invalidatesTags: ['Assignments', 'ChartData', 'Assets'],
      query: (data) => ({
        url: `intangibleAssets/${data.assetId}/assetAssignments`,
        method: 'POST',
        body: data
      })
    }),
    deleteIntangibleAssetAssignments: builder.mutation<AssetAssignmentResponse, { assetId: string; assignmentId: string }>({
      invalidatesTags: ['Assignments', 'Assets', 'ChartData'],
      query: ({ assetId, assignmentId }) => ({
        url: `intangibleAssets/${assetId}/assetAssignments/${assignmentId}`,
        method: 'DELETE'
      })
    }),
    updateIntangibleAssetAssignments: builder.mutation<AssetAssignmentResponse, AssetAssignmentPayload>({
      invalidatesTags: ['Assignments', 'ChartData'],
      query: (data) => ({
        url: `intangibleAssets/${data.assetId}/assetAssignments/${data.id}`,
        method: 'PUT',
        body: data
      })
    }),
    createIntangibleAsset: builder.mutation<IntangibleAssetsResponse, IntangibleAssetPayload>({
      invalidatesTags: ['Assets', 'ChartData'],
      query: (data) => ({
        url: 'intangibleAssets',
        method: 'POST',
        body: data
      })
    }),
    updateIntangibleAsset: builder.mutation<IntangibleAssetsResponse, IntangibleAssetPayload>({
      invalidatesTags: ['Assets', 'Asset', 'ChartData'],
      query: (data) => ({
        url: `intangibleAssets/${data.id}`,
        method: 'PUT',
        body: data
      })
    }),
    deleteIntangibleAsset: builder.mutation<void, string>({
      invalidatesTags: ['Assets', 'ChartData'],
      query: (assetID) => ({
        url: `intangibleAssets/${assetID}/?wouldYouLikeToPlayAGame=true`,
        method: 'DELETE'
      })
    }),
    getAssetStatuses: builder.query<AssetStatusesResponse, void>({
      query: () => ({
        url: `/intangibleAssetStatuses`
      }),
      transformResponse: (data: AssetStatusesResponse) => ({
        ...data,

        ...{
          data: data.data.sort((a, b) => {
            const valueA = a.description.toLowerCase();
            const valueB = b.description.toLowerCase();

            if (valueA < valueB) {
              return -1;
            }
            if (valueA > valueB) {
              return 1;
            }

            return 0;
          })
        }
      })
    }),
    getAssetTypes: builder.query<AssetStatusesResponse, void>({
      query: () => ({
        url: `/intangibleAssetTypes`
      }),
      transformResponse: (data: AssetStatusesResponse) => ({
        ...data,

        ...{
          data: data.data.sort((a, b) => {
            const valueA = a.description.toLowerCase();
            const valueB = b.description.toLowerCase();

            if (valueA < valueB) {
              return -1;
            }
            if (valueA > valueB) {
              return 1;
            }

            return 0;
          })
        }
      })
    }),
    getVersion: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/version',
        responseHandler: (response): Promise<string> => response.text()
      })
    }),
    exportAllAssets: builder.query<any[], { assetIds: string[] }>({
      async queryFn(args, queryApi, extraOptions, fetchWithBQ): Promise<any> {
        let results: any = [];

        const { assetIds } = args;

        if (!assetIds) return [];

        for (const assetId of assetIds) {
          const response = await fetchWithBQ(`/intangibleAssets/${assetId}/assetAssignments`);

          results = [...results, response.data];
        }

        return { data: results };
      }
    }),
    getApiName: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/apiName',
        responseHandler: (response): Promise<string> => response.text()
      })
    })
    // importIntangibleAssets: builder.mutation<
    //   IntangibleAssetImportPayload[],
    //   { headerItems: { [x: string]: IntangibleAssetImportPayload[] }; assignItems: { [x: string]: IntangibleAssetImportPayload[] } }
    // >({
    //   async queryFn(arg, api, extraOptions, baseQuery) {
    //     const successfulImports: IntangibleAssetImportPayload[] = [];
    //     const errorImports = [];

    //     const headerResponses: IntangibleAssetsResponse[] = [];
    //     const { assignItems, headerItems } = arg;

    //     // Loop and create or get header data
    //     for (const headerKey in headerItems) {
    //       api.dispatch(setCurrentStatus(`Posting header item ${headerItems[headerKey][0].intangibleAssetNumber} - ${headerItems[headerKey][0].assetType}`));
    //       // Create Header or Update
    //       const headerPayload: IntangibleAssetHeaderPayload = {
    //         intangibleAssetNumber: headerItems[headerKey][0].intangibleAssetNumber ? String(headerItems[headerKey][0].intangibleAssetNumber).trim() : '',
    //         assetSku: headerItems[headerKey][0].assetSKU_partNumber ? String(headerItems[headerKey][0].assetSKU_partNumber).trim() : '',
    //         assetOwner: headerItems[headerKey][0].assetOwner ? String(headerItems[headerKey][0].assetOwner).trim() : '',
    //         contractId: headerItems[headerKey][0].contractId ? String(headerItems[headerKey][0].contractId).trim() : '',
    //         note: headerItems[headerKey][0].headerNote ? headerItems[headerKey][0].headerNote.trim() : '',
    //         endDate: headerItems[headerKey][0].endDate ? String(headerItems[headerKey][0].endDate).trim() : '',
    //         isActive: true,
    //         manufacturer: headerItems[headerKey][0].manufacturer ? headerItems[headerKey][0].manufacturer.trim() : '',
    //         startDate: headerItems[headerKey][0].startDate ? String(headerItems[headerKey][0].startDate).trim() : '',
    //         assetStatus: headerItems[headerKey][0].assetStatus ? headerItems[headerKey][0].assetStatus.trim() : '',
    //         totalQuantity: headerItems[headerKey][0].totalQuantity ? headerItems[headerKey][0].totalQuantity : 0,
    //         assetType: headerItems[headerKey][0].assetType ? headerItems[headerKey][0].assetType.trim() : '',
    //         unitCost: headerItems[headerKey][0].unitCost ? headerItems[headerKey][0].unitCost : 0
    //       };

    //       const headerResponse = await baseQuery({
    //         url: 'intangibleAssets',
    //         body: headerPayload,
    //         method: 'POST',
    //         responseHandler: (response): Promise<string | IntangibleAssetsResponse> => (typeof response.body === 'object' ? response.json() : response.text())
    //       });

    //       // Conflict on creating, try to a get instead
    //       if (headerResponse.error?.status === 409) {
    //         // const data = await baseQuery(`intangibleAssets?assetId=${headerPayload.intangibleAssetNumber}&assetType=${headerPayload.assetType}`);

    //         errorImports.push({ ...headerPayload, errorMessage: { errorMessage: 'This asset already exists in the system' } });
    //         continue;
    //       }

    //       // Unable to create or get
    //       if (headerResponse.error && headerResponse.error?.status !== 409) {
    //         // TODO: HANDLE ERRORS WHEN A HEADER FAILS TO BE FOUND. NEED TO PUSH TO ERROR ITEMS AND ALL ITS CHILDREN COMBOINATIONS
    //         errorImports.push({ ...headerPayload, errorMessage: headerResponse.error.data });
    //         continue;
    //       }

    //       // Asset Header Data
    //       successfulImports.push(headerResponse?.data as IntangibleAssetImportPayload);
    //       headerResponses.push(headerResponse.data as IntangibleAssetsResponse);
    //     }
    //     // Loop over assign data and create. Dont care about gets.
    //     for (const assignKey in assignItems) {
    //       for (const assignItem of assignItems[assignKey]) {
    //         api.dispatch(setCurrentStatus(`Posting assignment item ${assignItem.serialNumber}`));
    //         const assignmentPayload: AssetAssignmentPayload = {
    //           serialNumber: assignItem.serialNumber ? String(assignItem.serialNumber).trim() : '',
    //           partNumber: assignItem.physicalPartNumber ? String(assignItem.physicalPartNumber).trim() : '',
    //           port: assignItem.port ? String(assignItem.port).trim() : '',
    //           location: assignItem.assetAssignmentLocation ? assignItem.assetAssignmentLocation.trim() : '',
    //           assignedQuantity: assignItem.assignedQuantity ?? '',
    //           note: assignItem.assetAssignmentNote ? assignItem.assetAssignmentNote.trim() : ''
    //         };

    //         const headerInfo = headerResponses.find(
    //           (data) =>
    //             data.assetType?.toUpperCase()?.trim() === assignItem.assetType?.toUpperCase()?.trim() &&
    //             data.intangibleAssetNumber?.toUpperCase()?.trim() === assignItem.intangibleAssetNumber?.toUpperCase()?.trim()
    //         );

    //         if (!headerInfo) {
    //           // create error
    //           errorImports.push({ ...assignmentPayload, ...{ errorMessage: 'Unable to find header id to import assigned item' } });
    //           continue;
    //         }

    //         const assignmentResponse = await baseQuery({
    //           url: `intangibleAssets/${headerInfo.id}/assetAssignments`,
    //           body: assignmentPayload,
    //           method: 'POST',
    //           responseHandler: (response): Promise<string> => response.text()
    //         });

    //         // Conflict on creating, try to a get instead
    //         if (assignmentResponse.error?.status === 409 || assignmentResponse.error?.status === 400) {
    //           // TODO: Handle validations by serial number & port & quantity.
    //           errorImports.push({ ...assignmentPayload, ...{ errorMessage: 'Bad request or asset has already been created' } });
    //           continue;
    //           // assignmentResponse = await baseQuery(`intangibleAssets?assetId=${assignmentPayload.intangibleAssetNumber}`);
    //           // eslint-disable-next-line no-debugger
    //         }

    //         // Unable to create or get
    //         if (assignmentResponse.error && assignmentResponse.error?.status !== 409 && assignmentResponse.error?.status !== 400) {
    //           // TODO: HANDLE ERRORS WHEN A HEADER FAILS TO BE FOUND. NEED TO PUSH TO ERROR ITEMS AND ALL ITS CHILDREN COMBOINATIONS
    //           continue;
    //         }
    //         successfulImports.push(JSON.parse(assignmentResponse.data as any) as IntangibleAssetImportPayload);
    //       }
    //     }

    //     api.dispatch(setSuccessItems(successfulImports));
    //     api.dispatch(setErrorItems(errorImports));
    //     api.dispatch(setCurrentStatus(''));

    //     return { data: successfulImports } ?? { error: { status: 500, statusText: 'Internal Server Error', data: "Coin landed on it's edge!" } };
    //   },
    //   invalidatesTags: ['Asset', 'Assets', 'Assignments']
    // })
  })
});

export const {
  // useGetIntangibleAssetChartDataQuery,
  useGetIntangibleAssetsQuery,
  useGetIntangibleAssetQuery,
  useGetIntangibleAssetAssignmentsQuery,
  useCreateIntangibleAssetMutation,
  useCreateIntangibleAssetAssignmentsMutation,
  useUpdateIntangibleAssetAssignmentsMutation,
  useUpdateIntangibleAssetMutation,
  useDeleteIntangibleAssetMutation,
  useGetAssetStatusesQuery,
  useGetAssetTypesQuery,
  useGetVersionQuery,
  useGetApiNameQuery,
  // useImportIntangibleAssetsMutation,
  useExportAllAssetsQuery,
  useDeleteIntangibleAssetAssignmentsMutation,
  useLazyExportAllAssetsQuery
} = captainBreakfastApi;
