import { AssetDetails } from 'components/organisms/AssetDetails';
import { AssetItems } from 'components/organisms/AssetItems';
import { MoreDetails } from 'components/organisms/MoreDetails';
import { About } from 'pages/About';
import { AssignInstallBases } from 'pages/AssignInstallBases';
import { AssignIntangibleAssets } from 'pages/AssignIntangibleAssets';
import { CreateAssetPage } from 'pages/CreateAsset';
import { Dashboard } from 'pages/Dashboard';
import { EditAssetPage } from 'pages/EditAsset';
import { EditProfilePage } from 'pages/EditProfile';
import { InstallBase } from 'pages/InstallBase';
import { IntangibleAssets } from 'pages/IntangibleAssets';
import { PartsMaster } from 'pages/PartsMaster';
import { SearchPage } from 'pages/Search';
import { Route, Routes } from 'react-router-dom';

export const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/assets" element={<SearchPage />} />
      <Route path="/parts-master" element={<PartsMaster />} />
      <Route path="/intangible-assets" element={<IntangibleAssets />} />
      <Route path="/intangible-assets/:id/assign" element={<AssignInstallBases />} />
      <Route path="/install-base/:id/assign" element={<AssignIntangibleAssets />} />
      <Route path="/install-base" element={<InstallBase />} />
      {/* <Route path="/install-base/import" element={<ImportAssets />} /> */}
      <Route path="/asset-assignments/create" element={<CreateAssetPage />}>
        <Route index element={<AssetDetails />} />
        <Route path="asset-items" element={<AssetItems />} />
        <Route path="more-details" element={<MoreDetails />} />
      </Route>
      <Route path="/edit/:assetId" element={<EditAssetPage />}>
        <Route index element={<AssetDetails />} />
        <Route path="asset-items" element={<AssetItems />} />
        <Route path="more-details" element={<MoreDetails />} />
      </Route>
      <Route path="/about" element={<About />} />
      <Route path="/profile/" element={<EditProfilePage />} />
    </Routes>
  );
};
