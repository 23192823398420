import { InstallBaseControls } from 'components/molecules/InstallBaseControls';
import { InstallBaseTable } from 'components/organisms/InstallBaseTable';
import { FC } from 'react';

export const InstallBase: FC = () => {
  return (
    <>
      <InstallBaseControls />
      <InstallBaseTable />
    </>
  );
};
