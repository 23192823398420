import { skipToken } from '@reduxjs/toolkit/query';
import { Button, Row, Space, Spin, message } from 'antd';
import { MessageType } from 'antd/es/message/interface';
import { TableRowSelection } from 'antd/es/table/interface';
import { DescriptionsProps } from 'antd/lib';
import { ManufacturerFilter } from 'components/atoms/Filters';
import { IntangibleAssetIdFilter } from 'components/atoms/Filters/IntangibleAssetFilters/IntangibleAssetIdFilter';
import { AssetTypeFilter } from 'components/atoms/Filters/ProductFilters/AssetTypeFilter';
import { CategoryFilter } from 'components/atoms/Filters/ProductFilters/CategoryFilter';
import { ProductNumberFilter } from 'components/atoms/Filters/ProductFilters/ProductNumberFilter';
import { IntangibleAssetCheckBox } from 'components/atoms/IntangibleAssetCheckBox';
import { ColoredCard } from 'components/common/ColoredCard';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { AssignIntangibleAssetTable } from 'components/molecules/AssignIntangibleAssetTable';
import { IntangibleAssetHeaderDetails } from 'components/molecules/IntangibleAssetHeaderDetails';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateAssetAssignmentMutation, useGetAssetAssignmentsQuery } from 'redux/services/captainBreakfast/asset-assignments/assetAssignmentApi';
import { useGetInstallBaseQuery } from 'redux/services/captainBreakfast/install-base/installBaseApi';
import { IntAssetResponse, intangibleAssetsApi } from 'redux/services/captainBreakfast/intangible-assets/intangibleAssetsApi';
import { setSelectedIntangibleAssets, setSelectedRowKeys } from 'redux/slices/selectedEntitiesToAssign';
import { useAppSelector } from 'redux/store';

export const AssignIntangibleAssets = (): JSX.Element => {
  const { id } = useParams();

  const { data: assetData, isLoading: isAssetLoading, isFetching: isAssetFetching } = useGetAssetAssignmentsQuery({ installBaseIdEquals: id }, { skip: !id });
  const { data, isLoading, isFetching } = useGetInstallBaseQuery(id ?? skipToken);
  const [createAssetAssignment, { isLoading: isCreating }] = useCreateAssetAssignmentMutation();
  const { selectedIntangibleAssets } = useAppSelector((state) => state.selectedEntitiesToAssign);
  const [itemCount, setItemCount] = useState<number>(1);
  const [isCellDisabled, setIsCellDisbaled] = useState<string[]>([]);
  const dispatch = useDispatch();
  const nav = useNavigate();

  const { selectedRowKeys } = useAppSelector((state) => state.selectedEntitiesToAssign);

  const handleSubmit = async (): Promise<void | MessageType> => {
    if (!id) return message.error('No intangible asset id found');

    for (const selectedAsset of selectedIntangibleAssets) {
      try {
        await createAssetAssignment({ intangibleAssetId: selectedAsset.id, installBaseId: id }).unwrap();
        setItemCount((prevState) => prevState + 1);
      } catch (err) {
        console.log(err, 'err');
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
        break;
      }
    }
    message.success(`Asset assignments were created successfully`);
    dispatch(intangibleAssetsApi.util.invalidateTags(['Assets']));
    dispatch(setSelectedIntangibleAssets([]));
    dispatch(setSelectedRowKeys([]));
    setItemCount(1);
  };

  useEffect(() => {
    return () => {
      dispatch(setSelectedIntangibleAssets([]));
      dispatch(setSelectedRowKeys([]));
    };
  }, []);

  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Install Base Id',
      children: data?.name
    },
    {
      key: '2',
      label: 'Location Code',
      children: data?.location.code
    },
    {
      key: '3',
      label: 'Manufacturer',
      children: data?.product?.manufacturer?.name
    },
    {
      key: '4',
      label: 'Product Number',
      children: data?.product?.productNumber
    },
    {
      key: '5',
      label: 'Serial Number',
      children: data?.serialNumber
    },
    {
      key: '6',
      label: 'Category',
      children: data?.product?.category?.name
    }
  ];

  const rowSelection: TableRowSelection<IntAssetResponse> = {
    checkStrictly: true,
    onChange: (selectedRowKeys: React.Key[], selectedRows: IntAssetResponse[]): void => {
      dispatch(setSelectedIntangibleAssets(selectedRows));
      dispatch(setSelectedRowKeys(selectedRowKeys));
    },
    getCheckboxProps: (record: IntAssetResponse): { disabled: boolean; name: string } => {
      return {
        disabled: record.availableQuantity <= 0 || isCellDisabled?.includes(record.id) ? true : false,
        name: record.name
      };
    },
    renderCell: (value, record, index, originNode) => {
      return <IntangibleAssetCheckBox setIsCellDisabled={setIsCellDisbaled} type="intangibleAsset" record={record} originNode={originNode} />;
    },
    selectedRowKeys
  };

  if (isLoading) return <LoaderWithMessage loadingMessage="Loading" />;

  return (
    // <FormikProvider value={formik}>
    <Spin
      style={{ display: 'flex', alignItems: 'center' }}
      spinning={isCreating}
      indicator={<LoaderWithMessage loadingMessage={`Creating asset assignment ${itemCount} of ${selectedIntangibleAssets.length}`} />}>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <ColoredCard
          bodyStyle={{ padding: 0 }}
          title="Install Base Details"
          color="rgb(255, 234, 193)"
          icon={<Button onClick={(): void => nav(`/install-base?nameContains=${data?.name}`)}>Back</Button>}>
          <IntangibleAssetHeaderDetails items={items} />
        </ColoredCard>
        <ColoredCard
          bodyStyle={{ padding: 12 }}
          title="Assign Intangible Assets"
          color="rgb(176, 178, 188)"
          icon={
            <Button style={{ background: !selectedIntangibleAssets.length ? 'white' : undefined }} type="primary" disabled={!selectedIntangibleAssets.length} onClick={handleSubmit}>
              Assign Selected ({selectedIntangibleAssets.length})
            </Button>
          }>
          <Space size={28} direction="vertical" style={{ width: '100%' }}>
            <Row style={{ padding: 8 }} justify={'space-between'}>
              <Space>
                <ProductNumberFilter />
                <IntangibleAssetIdFilter />
                <AssetTypeFilter />
                {/* <LocationCodeFilter /> */}
                <ManufacturerFilter />
                <CategoryFilter />
              </Space>
            </Row>
            <Row>
              <AssignIntangibleAssetTable rowSelection={rowSelection} loading={isFetching} />
            </Row>
          </Space>
        </ColoredCard>
      </Space>
    </Spin>
  );
};
