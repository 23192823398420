import { QuestionOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ButtonProps } from 'antd/lib';
import { ColoredCard } from 'components/common/ColoredCard';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import dayjs from 'dayjs';
import { FC, useState } from 'react';
import Lottie from 'react-lottie-player';
import { AssetAssignmentResponse, useDeleteAssetAssignmentMutation, useGetAssetAssignmentsQuery } from 'redux/services/captainBreakfast/asset-assignments/assetAssignmentApi';
import { IntAssetResponse } from 'redux/services/captainBreakfast/intangible-assets/intangibleAssetsApi';
import checkLottie from '../../assets/multiple.json';
import { DeleteRecordButton } from './DeleteRecordButton';

type Props = ButtonProps & {
  record: IntAssetResponse;
};

export const StatusInfoButtonIntangible: FC<Props> = ({ record }) => {
  const { data, isLoading, isFetching } = useGetAssetAssignmentsQuery({ intangibleAssetIdEquals: record.id }, { skip: !record.id });
  const [deleteAssetAssignment] = useDeleteAssetAssignmentMutation();

  const filteredAssignments = data?.data;
  const [isOpen, setIsOpen] = useState(false);
  const [hover, setHover] = useState(false);

  const columns: ColumnsType<AssetAssignmentResponse> = [
    {
      title: 'Install Base Id',
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => <div>{record.installBase.name}</div>
    },
    {
      title: 'Product Number',
      dataIndex: 'productNumber',
      key: 'productNumber',
      render: (value, record) => <div>{record.installBase.product.productNumber}</div>
    },
    {
      title: 'Location Code',
      dataIndex: 'locationCode',
      key: 'locationCode',
      render: (value, record) => <div>{record.installBase.location.code}</div>
    },
    {
      title: 'Serial Number',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      render: (value, record) => <div>{record.installBase.serialNumber}</div>
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (value, record) => <div>{record.installBase.quantity}</div>
    },
    {
      title: 'In Service Date',
      dataIndex: 'inServiceDate',
      key: 'inServiceDate',
      render: (_, record): JSX.Element => {
        const formatedAddress = dayjs(record.installBase.inServiceDate).format('MM-DD-YYYY');

        return <Typography>{formatedAddress}</Typography>;
      }
    },
    {
      title: 'Delete',
      render: (_, record): JSX.Element => {
        return <DeleteRecordButton title="Are you sure you want to delete this asset assignment?" deleteRecord={deleteAssetAssignment} recordToDelete={record.id} />;
      },
      align: 'center'
    }
  ];

  return (
    <>
      <Tooltip title={filteredAssignments?.length ? 'Asset Assignments' : 'No asset assignments'}>
        <Button
          loading={isLoading}
          disabled={!filteredAssignments?.length}
          onMouseEnter={(): void => setHover(true)}
          onMouseLeave={(): void => setHover(false)}
          style={{ color: '#136538', backgroundColor: hover ? '#ECFFF4' : undefined }}
          onClick={(): void => setIsOpen(true)}
          icon={
            filteredAssignments?.length ? (
              <Lottie
                // segments={[5, 63]}
                color="black"
                animationData={checkLottie}
                loop={true}
                goTo={50}
                play={hover}
                style={{ width: 24, height: 24 }}
              />
            ) : (
              <QuestionOutlined disabled />
            )
          }
        />
      </Tooltip>
      <Modal cancelButtonProps={{ style: { visibility: 'hidden' } }} okText="Exit" closable={false} onOk={(): void => setIsOpen(false)} open={isOpen && !!filteredAssignments?.length} width={950}>
        <ColoredCard title={`Asset assignments associated with ${record.name}`} color="rgb(206, 213, 242)">
          {!!filteredAssignments?.length && (
            <Table loading={{ spinning: isFetching, indicator: <LoaderWithMessage loadingMessage="Loading" /> }} pagination={false} dataSource={filteredAssignments} columns={columns} size="small" />
          )}
        </ColoredCard>
      </Modal>
    </>
  );
};
