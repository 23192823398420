import { FancyInput } from 'components/atoms/Inputs';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

export const PhysicalAlternateItemIdFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const [searchParams, setSearchParams] = useSearchParams();

  /* ******************** Functions ******************** */
  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const params = Object.fromEntries([...searchParams]);

    setSearchParams({ ...params, physicalAlternateItemIdContains: value });
    if (!value) {
      searchParams.delete('physicalAlternateItemIdContains');
      setSearchParams(searchParams);
    }
  }, 500);

  return <FancyInput defaultValue={searchParams.get('physicalAlternateItemIdContains') || ''} placeholder="Alternate Item Id" onChange={handleChange} />;
};
