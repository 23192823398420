import { Col, Row, Space } from 'antd';
import { AddProductButton } from 'components/atoms/AddProductButton';
import { AlternateItemIdFilter } from 'components/atoms/Filters/ProductFilters/AlternateItemIdFilter';
import { CategoryFilter } from 'components/atoms/Filters/ProductFilters/CategoryFilter';
import { IsActiveFilter } from 'components/atoms/Filters/ProductFilters/IsActiveFilter';
import { ManufacturerFilter } from 'components/atoms/Filters/ProductFilters/ManufacturerFilter';
import { ProductDescriptionFilter } from 'components/atoms/Filters/ProductFilters/ProductDescriptionFilter';
import { ProductNumberFilter } from 'components/atoms/Filters/ProductFilters/ProductNumberFilter';
import ExportAssetsButton from 'components/atoms/Inputs/ExportAssetsButton';

import { AntPageTitle } from 'components/ui/AntPageTitle';
import { useGetParams } from 'hooks/useGetParams';
import React from 'react';
import { useGetProductsQuery } from 'redux/services/captainBreakfast/products/productsApi';
import { ProductsImportButton } from './ProductsImportButton';

export const ProductControls: React.FC = () => {
  const { params } = useGetParams([
    'productNumberContains',
    'descriptionContains',
    'manufacturerNameContains',
    'categoryNameContains',
    'alternateItemIdContains',
    'includeInactiveData',
    'returnInactiveDataOnly'
  ]);
  const { data, isLoading, isFetching } = useGetProductsQuery(params);

  return (
    <>
      <Row style={{ marginBottom: 16, background: 'rgba(24, 167, 153, 0.518)', padding: 8, borderRadius: 5 }} justify="space-between">
        <Col>
          <AntPageTitle text="Search Parts" />
        </Col>
        <Col>
          <AddProductButton />
        </Col>
      </Row>
      <Row style={{ padding: '24px 16px', background: 'white', marginBottom: 15, justifyContent: 'space-between' }}>
        <Col span={16}>
          <Row gutter={[8, 8]}>
            <Col span={4}>
              <ProductNumberFilter />
            </Col>
            <Col span={4}>
              <AlternateItemIdFilter />
            </Col>
            <Col span={4}>
              <ProductDescriptionFilter />
            </Col>

            <Col span={4}>
              <ManufacturerFilter />
            </Col>
            <Col span={4}>
              <CategoryFilter />
            </Col>
            <Col span={4}>
              <IsActiveFilter />
            </Col>
          </Row>
        </Col>
        <Space align="start">
          <ProductsImportButton />
          <ExportAssetsButton entityName="partsMaster" disabled={!data?.data} loading={isLoading || isFetching} exportDocumentName="parts-master" data={data?.data ?? []} />
        </Space>
      </Row>
    </>
  );
};
