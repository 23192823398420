import { DatePicker, TimeRangePickerProps } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

const { RangePicker } = DatePicker;

dayjs.extend(quarterOfYear);

type Props = RangePickerProps & {
  startPlaceholer: string;
  endPlaceholder: string;
};
type RangeValue = [dayjs.Dayjs | null, dayjs.Dayjs | null] | null;

export const EndDateRangePicker: FC<Props> = ({ startPlaceholer, endPlaceholder, ...rest }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentYear = dayjs().year();
  const currentQuarter = dayjs().quarter();

  const handleChange = (val: RangeValue): void => {
    console.log(val, '??');
    if (!val) {
      searchParams.delete('endDate');
      searchParams.delete('endDateRangeEnd');
      setSearchParams(searchParams);

      return;
    }
    const endDate = dayjs(val[0]).format('MM-DD-YYYY');
    const endDateRangeEnd = dayjs(val[1]).format('MM-DD-YYYY');

    setSearchParams({ ...Object.fromEntries([...searchParams]), endDate, endDateRangeEnd });
  };

  const quarters = {
    lastQ1: [dayjs(`${currentYear - 1}-10-01`), dayjs(`${currentYear - 1}-12-31`)],
    Q1: [dayjs(`${currentYear}-01-01`), dayjs(`${currentYear}-03-31`)],
    Q2: [dayjs(`${currentYear}-04-01`), dayjs(`${currentYear}-06-30`)],
    Q3: [dayjs(`${currentYear}-07-01`), dayjs(`${currentYear}-09-30`)],
    Q4: [dayjs(`${currentYear}-10-01`), dayjs(`${currentYear}-12-31`)]
  };

  const returnLastQuarterRange = (): [dayjs.Dayjs, dayjs.Dayjs] | dayjs.Dayjs[] => {
    if (currentQuarter === 4) return quarters['Q3'];
    if (currentQuarter === 3) return quarters['Q2'];
    if (currentQuarter === 2) return quarters['Q1'];

    return quarters['lastQ1'];
  };
  const returnThisQuarterRange = (): [dayjs.Dayjs, dayjs.Dayjs] | dayjs.Dayjs[] => {
    if (currentQuarter === 4) return quarters['Q4'];
    if (currentQuarter === 3) return quarters['Q3'];
    if (currentQuarter === 2) return quarters['Q2'];

    return quarters['Q1'];
  };

  const rangePresets: TimeRangePickerProps['presets'] = [
    { label: 'This Month', value: [dayjs(), dayjs().add(1, 'M')] },
    { label: 'This Quarter', value: returnThisQuarterRange() as [dayjs.Dayjs, dayjs.Dayjs] },
    { label: 'This Year', value: [dayjs(), dayjs().add(1, 'y')] },
    { label: 'Last Month', value: [dayjs().add(-1, 'M'), dayjs()] },
    { label: 'Last Quarter', value: returnLastQuarterRange() as [dayjs.Dayjs, dayjs.Dayjs] },
    { label: 'Last Year', value: [dayjs().add(-1, 'y'), dayjs()] }
  ];

  return (
    <RangePicker
      defaultValue={searchParams.get('endDate') && searchParams.get('endDateRangeEnd') ? [dayjs(searchParams.get('endDate')), dayjs(searchParams.get('endDateRangeEnd'))] : null}
      placeholder={[startPlaceholer, endPlaceholder]}
      onChange={handleChange}
      {...rest}
      presets={rangePresets}
    />
  );
};
