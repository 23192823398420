import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Tooltip, Typography } from 'antd';
import Upload from 'antd/es/upload/Upload';
import ButtonGroup from 'antd/lib/button/button-group';
import { RcFile } from 'antd/lib/upload';
import dayjs from 'dayjs';
import React from 'react';
import { LineItemErrorObjectAsset } from 'redux/services/captainBreakfast/install-base/installBaseApi';
import { IntAssetPayload, useImportIntangibleAssetsMutation } from 'redux/services/captainBreakfast/intangible-assets/intangibleAssetsApi';
import { useAppSelector } from 'redux/store';
import { read, utils, writeFile } from 'xlsx';

export const IntangibleAssetsImport: React.FC = () => {
  /* ******************** Hooks ******************** */
  const [importIntangibleAssets] = useImportIntangibleAssetsMutation();

  const { isImporting } = useAppSelector((state) => state.import);

  const handleErrorModal = (importErrors: LineItemErrorObjectAsset[]): void => {
    Modal.confirm({
      title: 'Import Errors',
      bodyStyle: { maxHeight: 800 },
      cancelText: 'Export',
      onCancel: () => {
        const worksheeet = utils.json_to_sheet(importErrors);
        const workbook = utils.book_new();

        utils.book_append_sheet(workbook, worksheeet, 'assets');
        writeFile(workbook, 'intangible-assets-import-errors.xlsx');
      },
      width: 1000,
      icon: <></>,
      content: (
        <div style={{ border: '1px solid rgba(0,0,0,.1)', borderRadius: 10, padding: 10, marginTop: 20, overflow: 'scroll', height: 600 }}>
          <Row style={{ marginTop: 20, marginLeft: 5, marginBottom: 5 }}>
            <Col span={5}>
              <Typography.Text style={{ fontSize: 15 }}>Item Id</Typography.Text>
            </Col>
            <Col span={19}>
              <Typography.Text style={{ fontSize: 15 }}>Message</Typography.Text>
            </Col>
          </Row>
          {importErrors.map((error, i) => (
            <Row gutter={[10, 10]} key={error.errorMessage + i} style={{ padding: 10, background: 'rgba(0,0,0,.1)', borderRadius: 10, marginBottom: 10 }}>
              <Col span={5}>{error.intangibleAssetId}</Col>
              <Col span={19}>{error.errorMessage}</Col>
            </Row>
          ))}
        </div>
      )
    });
  };

  const handleImport = async (importData: IntAssetPayload[]): Promise<void> => {
    const res = await importIntangibleAssets(importData).unwrap();

    !!res.lineErrors.length && handleErrorModal(res.lineErrors);
  };

  const parseData = async (file: RcFile): Promise<void> => {
    const reader = new FileReader();

    reader.onload = (e): void => {
      if (e.target && e.target.result !== null) {
        const data = e.target.result;
        const workbook = read(data, { type: 'array' });

        const sheetNameAssetHeader = workbook.SheetNames[0];

        const worksheetAssetHeader = workbook.Sheets[sheetNameAssetHeader];

        const jsonAssetHeader = utils.sheet_to_json(worksheetAssetHeader, { raw: false });

        const payload: IntAssetPayload[] = jsonAssetHeader.map((item: any) => ({
          name: item['Intangible Asset Id'],
          productId: item['Product Number'],
          type: item['Type'],
          status: item['Status'],
          contract: item['Contract'] ?? '',
          quantity: Number(item['Quantity']) ?? 1,
          unitCost: Number(item['Unit Cost']) ?? '',
          startDate: dayjs(item['Start Date']).format('MM-DD-YYYY'),
          endDate: dayjs(item['End Date']).format('MM-DD-YYYY')
        }));

        handleImport(payload);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <ButtonGroup>
      <Upload beforeUpload={parseData} showUploadList={false}>
        <Button disabled={isImporting} loading={isImporting}>
          Import
        </Button>
      </Upload>
      <Tooltip title="Download import template">
        <Button download href={`/MDSi_Intangible_Asset_Import_Template.xlsx`}>
          <DownloadOutlined />
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
};
