import { monthIndexConverter } from 'common/helpers/chartDataGenerator';
import { BarChart } from 'components/atoms/Charts/BarChart';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetIntangibleAssetsQuery } from 'redux/services/captainBreakfast/captainBreakfastApi';
import { useGetIntangibleAssetChartDataQuery } from 'redux/services/captainBreakfast/intangible-assets/intangibleAssetsApi';

export const ExpirationChart = (): JSX.Element => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { data: allAssets } = useGetIntangibleAssetsQuery({
    assetType: undefined,
    assetSkuContains: undefined,
    assetStatus: undefined,
    assetOwnerContains: undefined,
    manufacturerContains: undefined,
    includeDeletedData: undefined,
    includeInactiveData: undefined,
    orderByDirection: undefined,
    orderByField: undefined,
    returnDeletedDataOnly: undefined,
    returnInactiveDataOnly: undefined,
    offset: 0,
    assetId: undefined,
    intangibleAssetNumberContains: undefined,
    expirationStartDate: undefined,
    expirationEndDate: undefined,
    locationContains: undefined,
    serialNumberContains: undefined,
    partNumberContains: undefined,
    take: undefined
  });
  const { data, isLoading, isFetching } = useGetIntangibleAssetChartDataQuery({ take: allAssets?.totalCount, offset: 0 });

  const handleLabelDataClick = (labelIndex: number): void => {
    const labelValue = data?.expiringChart.labels[labelIndex];

    const clickedMonthIndex = monthIndexConverter(labelValue || '');
    const currentMonthIndex = new Date().getMonth();

    const paramYear = currentMonthIndex > clickedMonthIndex ? new Date().getFullYear() + 1 : new Date().getFullYear();

    switch (labelValue) {
      case 'January':
        nav(`/intangible-assets?endDate=01-01-${paramYear}&endDateRangeEnd=01-31-${paramYear}`);
        break;

      case 'February':
        nav(`/intangible-assets?endDate=02-01-${paramYear}&endDateRangeEnd=02-28-${paramYear}`);
        break;

      case 'March':
        nav(`/intangible-assets?endDate=03-01-${paramYear}&endDateRangeEnd=03-31-${paramYear}`);
        break;

      case 'April':
        nav(`/intangible-assets?endDate=04-01-${paramYear}&endDateRangeEnd=04-30-${paramYear}`);
        break;

      case 'May':
        nav(`/intangible-assets?endDate=05-01-${paramYear}&endDateRangeEnd=05-31-${paramYear}`);
        break;

      case 'June':
        nav(`/intangible-assets?endDate=06-01-${paramYear}&endDateRangeEnd=06-30-${paramYear}`);
        break;

      case 'July':
        nav(`/intangible-assets?endDate=07-01-${paramYear}&endDateRangeEnd=07-31-${paramYear}`);
        break;

      case 'August':
        nav(`/intangible-assets?endDate=08-01-${paramYear}&endDateRangeEnd=08-31-${paramYear}`);
        break;

      case 'September':
        nav(`/intangible-assets?endDate=09-01-${paramYear}&endDateRangeEnd=09-30-${paramYear}`);
        break;

      case 'October':
        nav(`/intangible-assets?endDate=10-01-${paramYear}&endDateRangeEnd=10-31-${paramYear}`);
        break;

      case 'November':
        nav(`/intangible-assets?endDate=11-01-${paramYear}&endDateRangeEnd=11-30-${paramYear}`);
        break;

      case 'December':
        nav(`/intangible-assets?endDate=12-01-${paramYear}&endDateRangeEnd=12-31-${paramYear}`);
        break;

      default:
        nav('/intangible-assets');
    }
  };

  return <BarChart chartData={data?.expiringChart || { labels: [], datasets: [] }} chartTitle={'Expiring Soon'} isLoading={isLoading} handleChartClick={handleLabelDataClick} />;
};
