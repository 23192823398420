import { Button } from 'antd';
import { Fragment, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const IsActiveFilter = (): JSX.Element => {
  const [hover, setHover] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Fragment>
      <Button
        onMouseLeave={(): void => setHover('')}
        onMouseEnter={(): void => setHover('active')}
        type={hover === 'active' || (!searchParams.has('includeInactiveData') && !searchParams.has('returnInactiveDataOnly')) ? 'primary' : undefined}
        style={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0
        }}
        value="true"
        onClick={(): void => {
          if (searchParams.has('includeInactiveData')) {
            searchParams.delete('includeInactiveData');

            return setSearchParams({ ...Object.fromEntries([...searchParams]) });
          }

          if (searchParams.has('returnInactiveDataOnly')) {
            searchParams.delete('returnInactiveDataOnly');

            return setSearchParams({ ...Object.fromEntries([...searchParams]) });
          }

          return setSearchParams({ ...Object.fromEntries([...searchParams]), includeInactiveData: 'true' });
        }}>
        Active
      </Button>

      <Button
        onMouseLeave={(): void => setHover('')}
        onMouseEnter={(): void => setHover('inactive')}
        type={hover === 'inactive' || searchParams.has('returnInactiveDataOnly') ? 'primary' : undefined}
        style={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0
        }}
        value={'false'}
        onClick={(): void => {
          if (searchParams.has('includeInactiveData')) {
            searchParams.delete('includeInactiveData');

            return setSearchParams({ ...Object.fromEntries([...searchParams]), returnInactiveDataOnly: 'true' });
          }
          if (searchParams.has('returnInactiveDataOnly')) {
            searchParams.delete('returnInactiveDataOnly');

            return setSearchParams({ ...Object.fromEntries([...searchParams]), includeInactiveData: 'true' });
          }

          return setSearchParams({ ...Object.fromEntries([...searchParams]), returnInactiveDataOnly: 'true' });
        }}>
        Inactive
      </Button>
    </Fragment>
  );
};
