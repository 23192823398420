import { Row, Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { Field, FieldProps } from 'formik';
import { CSSProperties, FC } from 'react';
import { InstallBaseResponse } from 'redux/services/captainBreakfast/install-base/installBaseApi';
import { IntAssetResponse } from 'redux/services/captainBreakfast/intangible-assets/intangibleAssetsApi';
import { ProductResponse } from 'redux/services/captainBreakfast/products/productsApi';
import { Address } from 'redux/services/chuckieSue/models/addresses';
import { FormLabel } from './FormLabel';

interface Props extends SelectProps {
  fieldName: string;
  label: string;
  labelStyle?: CSSProperties;
  optionsArr?: string[];
  addressOptionsArr?: Address[];
  productsOptionsArr?: ProductResponse[];
  installBaseOptionsArr?: InstallBaseResponse[];
  intangibleAssetOptionsArr?: IntAssetResponse[];
  helpIcon?: boolean;
}

export const FieldInputSelect: FC<Props> = ({
  fieldName,
  label,
  labelStyle,
  optionsArr,
  addressOptionsArr,
  productsOptionsArr,
  installBaseOptionsArr,
  intangibleAssetOptionsArr,
  helpIcon,
  ...rest
}) => {
  const addressOptions = addressOptionsArr?.map((address) => ({
    label: `${address.code} -- ${address?.street1} ${address?.city}, ${address?.subdivision?.localCode} ${address?.postalCode}`,
    value: address.id
  }));

  const productsOptions = productsOptionsArr?.map((product) => ({ label: product.productNumber, value: product.id }));
  const installBaseOptions = installBaseOptionsArr?.map((base) => ({ label: base.name, value: base.id }));
  const intangibleAssetOptions = intangibleAssetOptionsArr?.map((asset) => ({ label: asset.name, value: asset.id }));

  const handleFilterOption = (input: string, option: DefaultOptionType | undefined): boolean => {
    if (!!option && typeof option.label === 'string')
      return (
        !!option &&
        !!option.value &&
        typeof option.value === 'string' &&
        typeof option.label === 'string' &&
        (option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0)
      );

    return !!option && !!option.value && typeof option.value === 'string' && option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <Field name={fieldName}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => {
        const handleChange = (val: string): void => {
          setFieldValue(fieldName, val);
        };

        return (
          <Row style={{ width: '100%' }}>
            <FormLabel helpIcon={helpIcon} style={labelStyle} label={label} />

            <Select
              {...field}
              showSearch
              allowClear
              onClear={(): void => {
                setFieldTouched(fieldName, true);
              }}
              filterOption={handleFilterOption}
              onChange={handleChange}
              status={meta.error && meta.touched ? 'error' : undefined}
              onFocus={(): void => {
                setFieldTouched(fieldName, false);
              }}
              onBlur={(): void => {
                setFieldTouched(fieldName, true);
              }}
              style={{ width: '100%' }}
              options={installBaseOptions ?? intangibleAssetOptions ?? productsOptions ?? addressOptions ?? optionsArr?.map((option) => ({ label: option, value: option }))}
              {...rest}
            />
            <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
          </Row>
        );
      }}
    </Field>
  );
};
