import { Button, Modal, Spin, Tooltip, message } from 'antd';
import { ColoredCard } from 'components/common/ColoredCard';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { ProductDetailsForm } from 'components/molecules/ProductDetailsForm';
import { FormikProvider, useFormik } from 'formik';
import { partSchema } from 'models/Product';
import { FC, Fragment, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetCategoriesQuery } from 'redux/services/captainBreakfast/categories/categoriesApi';
import { useGetManufacturersQuery } from 'redux/services/captainBreakfast/manufacturers/manufacturersApi';
import { ProductPayload, ProductResponse, useUpdateProductMutation } from 'redux/services/captainBreakfast/products/productsApi';

type Props = {
  record: ProductResponse;
};

export const EditProductButton: FC<Props> = ({ record }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { data } = useGetManufacturersQuery();
  const { data: catData } = useGetCategoriesQuery();
  const [update, { isLoading: isUpdating }] = useUpdateProductMutation();
  // const [getLazy] = useLazyGetProductsQuery();
  // const [isUpdating, setIsUpdating] = useState(false);

  const isEdit = searchParams.get('isEdit') === 'true';
  const productNumber = searchParams.get('productNumberContains');

  const isProductEdit = Boolean(isEdit && productNumber && productNumber === record?.productNumber);

  const [isOpen, setIsOpen] = useState(false);
  const formik = useFormik<ProductPayload>({
    enableReinitialize: true,
    validationSchema: partSchema,
    initialValues: {
      productNumber: record?.productNumber ?? '',
      description: record?.description ?? '',
      manufacturerId: record?.manufacturer?.name ?? '',
      categoryId: record?.category?.name ?? '',
      alternateItemId: record?.alternateItemId ?? '',
      productType: record?.productType ?? '',
      isActive: true,
      isDeleted: false
    },
    onSubmit: async (values) => {
      const manufacturerId = data?.data.find((manufacturer) => manufacturer.name.toUpperCase() === values.manufacturerId.toUpperCase())?.id;
      const categoryId = catData?.data.find((category) => category.name.toUpperCase() === values.categoryId.toUpperCase())?.id;

      if (!manufacturerId || !categoryId) return message.error('Manufacturer or category not found');

      const payload: ProductPayload = { ...values, manufacturerId, categoryId };

      try {
        // setIsUpdating(true);
        // const getResponse = await getLazy({ productNumberEquals: values.productNumber }).unwrap();

        // if (getResponse.totalCount && values.productNumber.toUpperCase() !== record.productNumber.toUpperCase()) {
        //   setIsUpdating(false);

        //   return message.error('Product number already exists');
        // }
        const resp = await update({ id: record.id, payload }).unwrap();

        message.success(`Product ${resp.productNumber} was updated successfully`);
        setIsOpen(false);
        formik.resetForm();
        searchParams.delete('isEdit');
        // setIsUpdating(false);

        setSearchParams(searchParams);
      } catch (err) {
        console.log(err, 'err');
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
        setIsOpen(false);
        formik.resetForm();
        searchParams.delete('isEdit');
        // setIsUpdating(false);

        setSearchParams(searchParams);
      }
    }
  });

  return (
    <Fragment>
      <Tooltip title={`Description: ${record?.description}`}>
        <Button onClick={(): void => setIsOpen(true)} type="link">
          {record?.productNumber}
        </Button>
      </Tooltip>
      <Modal
        onOk={(): Promise<void> => formik.submitForm()}
        onCancel={(): void => {
          setIsOpen(false);
          searchParams.delete('isEdit');
          setSearchParams(searchParams);
          formik.resetForm();
        }}
        closable={false}
        open={isOpen || isProductEdit}
        okText={'Submit'}>
        <Spin style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} spinning={isUpdating} indicator={<LoaderWithMessage loadingMessage="Updating product" />}>
          <ColoredCard title={`Edit ${record?.productNumber}`} color="rgba(24, 167, 153, 0.518)">
            <FormikProvider value={formik}>
              <ProductDetailsForm isEdit />
            </FormikProvider>
          </ColoredCard>
        </Spin>
      </Modal>
    </Fragment>
  );
};
