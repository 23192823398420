import { DoughnutChart } from 'components/atoms/Charts/DoughnutChart';
import { useNavigate } from 'react-router-dom';
import { useGetAssetAssignmentsChartDataQuery, useGetAssetAssignmentsQuery } from 'redux/services/captainBreakfast/asset-assignments/assetAssignmentApi';

export const AssetTypeChart = (): JSX.Element => {
  const nav = useNavigate();
  const { data: allAssets } = useGetAssetAssignmentsQuery({});
  const { data, isLoading, isFetching } = useGetAssetAssignmentsChartDataQuery({ take: allAssets?.totalCount, offset: 0 });

  const handleLabelDataClick = (labelIndex: number): void => {
    const labelValue = data?.typeChart.labels[labelIndex];

    if (labelValue === 'Leases') nav(`/assets?types=Leased Equipment`);
    else nav(`/assets?types=${labelValue}`);
  };

  return (
    <DoughnutChart
      chartData={data?.typeChart ?? { labels: [], datasets: [] }}
      chartTitle={'Asset Types'}
      isLoading={isLoading}
      chartCircumference={360}
      chartCutout={125}
      handleChartClick={handleLabelDataClick}
    />
  );
};
