import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useGetParams = (paramsArray: string[]): { params: object } => {
  const [searchParams] = useSearchParams();
  const [apiParams, setApiParams] = useState<object>({});

  useEffect(() => {
    paramsArray.forEach((param) => {
      setApiParams((prevState) => {
        if (param === 'includeInactiveData' && searchParams.has(param))
          return {
            ...prevState,
            [param]: searchParams.get(param) === 'true' ? true : false
          };

        return {
          ...prevState,
          [param]: searchParams.get(param) ?? undefined
        };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return { params: apiParams };
};
