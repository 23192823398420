import { Button, Modal, Spin, message } from 'antd';
import { ColoredCard } from 'components/common/ColoredCard';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { IntangibleDetailsForm } from 'components/molecules/IntangibleDetailsForm';
import dayjs from 'dayjs';
import { FormikProvider, useFormik } from 'formik';
import { FC, useState } from 'react';
import {
  IntAssetPayload,
  IntAssetResponse,
  intangibleAssetBaseSchema,
  useGetIntangibleAssetsQuery,
  useLazyGetIntangibleAssetsQuery,
  useUpdateIntangibleAssetMutation
} from 'redux/services/captainBreakfast/intangible-assets/intangibleAssetsApi';

type Props = {
  record: IntAssetResponse;
};

export const EditIntangibleItemButton: FC<Props> = ({ record }) => {
  const startDate = dayjs(record.startDate);
  const endDate = dayjs(record.endDate);
  const { data } = useGetIntangibleAssetsQuery({});

  const [isOpen, setIsOpen] = useState(false);
  const [update] = useUpdateIntangibleAssetMutation();
  const [getLazy] = useLazyGetIntangibleAssetsQuery();
  const [isUpdating, setIsUpdating] = useState(false);

  const formik = useFormik<IntAssetPayload>({
    enableReinitialize: true,
    validationSchema: intangibleAssetBaseSchema,
    initialValues: {
      name: record.name ?? '',
      productId: record?.product?.id ?? '',
      status: record.status ?? '',
      type: record.type ?? '',
      contract: record.contract ?? '',
      quantity: record.quantity ?? 1,
      unitCost: record.unitCost ?? 0,
      totalCost: record.totalCost ?? 0,
      startDate: startDate ?? '',
      endDate: endDate ?? '',
      isActive: true,
      isDeleted: false
    },
    onSubmit: async (values) => {
      // if (values.quantity === 0) return message.error('Quantity cannot be 0');

      // if (filteredIas?.some((ias) => ias.name === values.name)) return message.error('Intangible asset already exists');

      const startDate = dayjs(values.startDate).utc().format('YYYY-MM-DD HH:mm:ss[Z]');
      const endDate = dayjs(values.endDate).utc().format('YYYY-MM-DD HH:mm:ss[Z]');
      const payload = { ...values, startDate, endDate };

      try {
        setIsUpdating(true);
        const get = await getLazy({ nameEquals: values.name }).unwrap();

        if (get.totalCount && values.name.toUpperCase() !== record.name.toUpperCase()) {
          setIsUpdating(false);

          return message.error('Intangible asset already exists');
        }

        const resp = await update({ id: record.id, payload }).unwrap();

        message.success(`Intangible asset ${resp.name} was updated successfully`);
        formik.resetForm();
        setIsOpen(false);
        setIsUpdating(false);
      } catch (err) {
        console.log(err, 'err');
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
        setIsOpen(false);
        formik.resetForm();
        setIsUpdating(false);
      }
    }
  });

  return (
    <>
      <Button type="link" onClick={(): void => setIsOpen(true)}>
        {record.name}
      </Button>
      <Modal
        okText="Submit"
        width={800}
        onOk={(): Promise<void> => formik.submitForm()}
        onCancel={(): void => {
          setIsOpen(false);
          formik.resetForm();
        }}
        closable={false}
        open={isOpen}>
        <Spin style={{ display: 'flex', alignItems: 'center' }} spinning={isUpdating} indicator={<LoaderWithMessage loadingMessage="Updating intangible asset" />}>
          <ColoredCard title={`Edit ${record.name}`} color="rgb(255, 234, 193)">
            <FormikProvider value={formik}>
              <IntangibleDetailsForm isEdit />
            </FormikProvider>
          </ColoredCard>
        </Spin>
      </Modal>
    </>
  );
};
