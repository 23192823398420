import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Table, Typography } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import { DeleteRecordButton } from 'components/atoms/DeleteRecordButton';
import WimsicalError from 'components/atoms/WimsicalError/WimsicalError';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { NoResults } from 'components/common/NoResults';
import { PageLoader } from 'components/common/PageLoader';
import dayjs from 'dayjs';
import { useGetParams } from 'hooks/useGetParams';
import { FC, useState } from 'react';

import { AssetAssignmentResponse, useDeleteAssetAssignmentMutation, useGetAssetAssignmentsQuery } from 'redux/services/captainBreakfast/asset-assignments/assetAssignmentApi';

export const AssetTable: FC<TableProps<AssetAssignmentResponse>> = () => {
  const [take, setTake] = useState(25);
  const [offset, setOffset] = useState(0);
  const { params } = useGetParams([
    'productNumberContains',
    'manufacturerNameContains',
    'categoryNameContains',
    'locationCodeContains',
    'serialNumberContains',
    'inServiceStartDate',
    'inServiceEndDate',
    'startDate',
    'startDateRangeEnd',
    'endDate',
    'endDateRangeEnd',
    'installBaseNameContains',
    'alternateItemIdContains',
    'intangibleAssetNameContains',
    'installBaseIdEquals',
    'intangibleAssetIdEquals',
    'physicalProductNumberContains',
    'intangibleProductNumberContains',
    'physicalAlternateItemIdContains',
    'types'
  ]);
  const { data, isLoading, isFetching, isError, error } = useGetAssetAssignmentsQuery({ ...params, offset, take });
  const [deleteAssetAssignment] = useDeleteAssetAssignmentMutation();

  const totalCount = data?.totalCount ?? 0;

  const handlePageChange = (page: number, pageSize: number): void => {
    setTake(pageSize);
    setOffset((page - 1) * pageSize);
  };

  const cols: ColumnsType<AssetAssignmentResponse> = [
    {
      title: 'Product Number',
      key: 'productNumber',
      dataIndex: 'productNumber',
      render: (_, record) => <div style={{ marginLeft: 3, paddingLeft: 3 }}>{record.installBase.product.productNumber}</div>,
      width: '20ch'
    },
    {
      title: 'Alt Item Id',
      key: 'alternateItemId',
      dataIndex: 'alternateItemId',
      render: (_, record) => <Typography.Text>{record?.installBase?.product?.alternateItemId ?? ''}</Typography.Text>,
      width: 90
    },
    {
      title: 'Manufacturer',
      key: 'manufacturerName',
      dataIndex: 'manufacturerName',
      render: (_, record) => <Typography.Text>{record.installBase.product.manufacturer.name}</Typography.Text>,
      width: 125
    },
    {
      title: 'Category',
      key: 'categoryName',
      dataIndex: 'categoryName',
      render: (_, record) => <Typography.Text>{record.installBase.product.category.name}</Typography.Text>,
      width: 125
    },
    {
      title: 'Serial Number',
      key: 'serialNumber',
      render: (_, record) => <Typography.Text>{record.installBase.serialNumber}</Typography.Text>,
      width: 125
    },
    {
      title: 'In Service Date',
      width: 120,
      render: (_, record): JSX.Element => {
        const formatedAddress = dayjs(record.installBase.inServiceDate).format('MM-DD-YYYY');

        return <Typography.Text>{formatedAddress}</Typography.Text>;
      }
    },
    {
      title: 'Install Base',
      key: 'installBaseId',
      dataIndex: 'installBaseId',
      render: (_, record) => <Typography.Text>{record.installBase.name}</Typography.Text>,
      width: 125
    },
    {
      title: 'Location Code',
      dataIndex: 'locationCode',
      key: 'locationCode',
      render: (_, record) => <Typography.Text>{record.installBase.location.code}</Typography.Text>
    },
    {
      title: 'Intangible Product',
      render: (_, record) => <Typography.Text>{record.intangibleAsset.product.productNumber}</Typography.Text>
    },
    {
      title: 'Type',
      render: (_, record) => <Typography.Text>{record.intangibleAsset.type}</Typography.Text>
    },
    {
      title: 'Start Date',
      render: (_, record): JSX.Element => {
        const formatedAddress = dayjs(record.intangibleAsset.startDate).format('MM-DD-YYYY');

        return <Typography.Text>{formatedAddress}</Typography.Text>;
      },
      width: 100
    },
    {
      title: 'End Date',
      render: (_, record): JSX.Element => {
        const formatedAddress = dayjs(record.intangibleAsset.endDate).format('MM-DD-YYYY');

        return <Typography.Text>{formatedAddress}</Typography.Text>;
      },
      width: 100
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => <Typography>{record.intangibleAsset.status}</Typography>,
      width: 90
    },
    {
      title: 'Actions',
      align: 'center',
      fixed: 'right',
      render: (_, record) => <DeleteRecordButton title="Are you sure you want to delete this asset assignment?" deleteRecord={deleteAssetAssignment} recordToDelete={record.id} />,
      width: 75
    }
  ];

  if (isLoading) return <PageLoader loadingMessage="Loading asset assignments" />;

  if (isError) {
    const errData = error as FetchBaseQueryError;

    return (
      <WimsicalError
        title={(errData.data as string) || 'Oops something went wrong'}
        statusCode={errData.status}
        subTitle={'Please try to reload the app and try again'}
        redirectText={'Reload'}
        redirectUrl={process.env.REACT_APP_REDIRECT_URL}
      />
    );
  }

  return (
    <Table
      dataSource={data?.data}
      loading={{ spinning: isFetching, indicator: <LoaderWithMessage loadingMessage="Loading" /> }}
      locale={{ emptyText: <NoResults title="Nothing here" style={{ width: 350, height: 350 }} /> }}
      columns={cols}
      size="small"
      scroll={{ x: 'max-content' }}
      pagination={{ total: totalCount, defaultPageSize: take, onChange: handlePageChange, pageSizeOptions: ['10', '20', '25', '50', '100'] }}
    />
  );
};
