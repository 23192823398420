import { DashboardButton } from 'components/atoms/Charts/DashboardButton';
import { DoughnutChart } from 'components/atoms/Charts/DoughnutChart';
import { useNavigate } from 'react-router-dom';
import { useGetAssetAssignmentsQuery, useGetUnassignedChartDataQuery } from 'redux/services/captainBreakfast/asset-assignments/assetAssignmentApi';
import { useGetInstallBasesQuery } from 'redux/services/captainBreakfast/install-base/installBaseApi';
import { useGetIntangibleAssetsQuery } from 'redux/services/captainBreakfast/intangible-assets/intangibleAssetsApi';

export const UnasignedEntitiesChart = (): JSX.Element => {
  const { data: bases, isLoading: isBasesLoading } = useGetInstallBasesQuery({});
  const { data: assets, isLoading: isAssetsLoading } = useGetAssetAssignmentsQuery({});
  const { data: ia, isLoading: isAssignLoading } = useGetIntangibleAssetsQuery({});
  const nav = useNavigate();
  const { data, isLoading } = useGetUnassignedChartDataQuery(
    { iaTotalCount: ia?.totalCount as number, installTotalCount: bases?.totalCount as number, assetTotalCount: assets?.totalCount as number },
    { skip: !bases || !assets || !ia }
  );

  const handleLabelDataClick = (labelIndex: number): void => {
    const labelValue = data?.chartData.labels[labelIndex];

    if (labelValue === 'Install Bases') nav(`/install-base`);
    if (labelValue === 'Intangible Assets') nav(`/intangible-assets`);
  };

  return (
    <DoughnutChart
      extraButton={<DashboardButton installBases={data?.installBases} intangibleAssets={data?.intangibleAssets} />}
      chartData={data?.chartData ?? { labels: [], datasets: [] }}
      chartTitle={'Unassigned Install Bases/Intangible Assets'}
      isLoading={isLoading || isBasesLoading || isAssetsLoading || isAssignLoading}
      chartCircumference={360}
      chartCutout={125}
      handleChartClick={handleLabelDataClick}
    />
  );
};
