import { InfoCircleOutlined } from '@ant-design/icons';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Button, Popover, Row, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { AssetLinkButton } from 'components/atoms/AssetLinkButton';
import { AssignInstallBaseButton } from 'components/atoms/AssignInstallBaseButton';
import { DeleteIAButton } from 'components/atoms/DeleteIAButton';
import { EditIntangibleItemButton } from 'components/atoms/EditIntangibleItemButton';
import { StatusInfoButtonIntangible } from 'components/atoms/StatusInfoButtonIntangible';
import WimsicalError from 'components/atoms/WimsicalError/WimsicalError';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { PageLoader } from 'components/common/PageLoader';
import dayjs from 'dayjs';
import { useGetParams } from 'hooks/useGetParams';
import { rest } from 'lodash';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IntAssetResponse, useDeleteIntangibleAssetMutation, useGetIntangibleAssetsQuery } from 'redux/services/captainBreakfast/intangible-assets/intangibleAssetsApi';
import { useAppSelector } from 'redux/store';

export const IntangibleAssetsTable: FC = () => {
  const [take, setTake] = useState(25);
  const [offset, setOffset] = useState(0);

  const { isImporting, importLoadingMessage } = useAppSelector((state) => state.import);

  const nav = useNavigate();

  const { params } = useGetParams([
    'productNumberContains',
    'nameContains',
    'manufacturerNameContains',
    'categoryNameContains',
    'startDate',
    'startDateRangeEnd',
    'endDate',
    'endDateRangeEnd',
    'includeInactiveData',
    'returnInactiveDataOnly',
    'types'
  ]);

  const { data, isLoading, isFetching, isError, error } = useGetIntangibleAssetsQuery({ ...params, offset, take });

  const totalCount = data?.totalCount ?? 0;

  const [deleteIa] = useDeleteIntangibleAssetMutation();

  const handlePageChange = (page: number, pageSize: number): void => {
    setTake(pageSize);
    setOffset((page - 1) * pageSize);
  };

  const columns: ColumnsType<IntAssetResponse> = [
    {
      title: 'Intangible Asset Id',
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => <EditIntangibleItemButton record={record} />
    },
    {
      title: 'Intangible Item Id',
      dataIndex: 'productNumber',
      key: 'productNumber',
      render: (value, record) => (
        <Row align={'middle'}>
          <Popover
            content={
              <Space direction="vertical">
                <Typography>Alternate Item Id: {record?.product?.alternateItemId ?? ''}</Typography>
                <Typography>Manufacturer: {record?.product?.manufacturer?.name ?? ''}</Typography>
                <Typography>Category: {record?.product?.category?.name ?? ''}</Typography>
              </Space>
            }>
            <InfoCircleOutlined style={{ color: 'black' }} />
          </Popover>
          <Button style={{ marginLeft: 3, paddingLeft: 3 }} onClick={(): void => nav(`/parts-master?productNumberContains=${record?.product?.productNumber}&isEdit=true`)} type="link">
            {record?.product?.productNumber}
          </Button>
        </Row>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Contract',
      dataIndex: 'contract',
      key: 'contract'
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'Qty Available',
      dataIndex: 'availableQuantity',
      key: 'availableQuantity',
      align: 'center',
      width: 150
    },
    {
      title: 'Unit Cost',
      dataIndex: 'unitCost',
      key: 'unitCost',
      render: (_, record): JSX.Element => {
        return <Typography>{`$${record.unitCost.toFixed(2)}`}</Typography>;
      }
    },
    {
      title: 'Total Cost',
      dataIndex: 'totalCost',
      key: 'totalCost',
      render: (_, record): JSX.Element => {
        return <Typography>{`$${record.totalCost.toFixed(2)}`}</Typography>;
      }
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (_, record): JSX.Element => {
        const formatedAddress = dayjs(record.startDate).format('MM-DD-YYYY');

        return <Typography>{formatedAddress}</Typography>;
      }
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (_, record): JSX.Element => {
        const formatedAddress = dayjs(record.endDate).format('MM-DD-YYYY');

        return <Typography>{formatedAddress}</Typography>;
      }
    },
    {
      title: 'Actions',
      align: 'center',
      render(value, record): JSX.Element {
        return (
          <Space>
            <StatusInfoButtonIntangible record={record} />
            <AssignInstallBaseButton record={record} />
            <AssetLinkButton record={record} path={`/assets?intangibleAssetIdEquals=${record.id}`} />
            <DeleteIAButton intangibleAssetId={record.id} />
          </Space>
        );
      }
    }
  ];

  if (isLoading) return <PageLoader loadingMessage="Loading intangible assets" />;

  if (isError) {
    const errData = error as FetchBaseQueryError;

    return (
      <WimsicalError
        title={(errData.data as string) || 'Oops something went wrong'}
        statusCode={errData.status}
        subTitle={'Please try to reload the app and try again'}
        redirectText={'Reload'}
        redirectUrl={process.env.REACT_APP_REDIRECT_URL}
      />
    );
  }

  return (
    <Table
      pagination={{ total: totalCount, defaultPageSize: take, onChange: handlePageChange, pageSizeOptions: ['10', '20', '25', '50', '100'] }}
      loading={{ spinning: isFetching || isImporting, indicator: <LoaderWithMessage loadingMessage={isImporting ? importLoadingMessage : 'Loading'} /> }}
      dataSource={data?.data}
      size="small"
      scroll={{ x: 'max-content' }}
      columns={columns}
      rowKey={(item): string => item.id}
      {...rest}
    />
  );
};
