import { Col, Row, Space } from 'antd';
import { AddInstallBaseButton } from 'components/atoms/AddInstallBaseButton';
import { ManufacturerFilter } from 'components/atoms/Filters';
import { LocationCodeFilter } from 'components/atoms/Filters/AssetAssignmentsFilters/LocationCodeFilter';
import { InstallBaseIdContains } from 'components/atoms/Filters/InstallBaseFilters/InstallBaseIdContains';
import { PresetDateRangePicker } from 'components/atoms/Filters/PresetDateRangePicker';
import { CategoryFilter } from 'components/atoms/Filters/ProductFilters/CategoryFilter';
import { IsActiveFilter } from 'components/atoms/Filters/ProductFilters/IsActiveFilter';
import { ProductNumberFilter } from 'components/atoms/Filters/ProductFilters/ProductNumberFilter';
import ExportAssetsButton from 'components/atoms/Inputs/ExportAssetsButton';
import { AntPageTitle } from 'components/ui/AntPageTitle';
import { useGetParams } from 'hooks/useGetParams';
import React from 'react';
import { useGetInstallBasesQuery } from 'redux/services/captainBreakfast/install-base/installBaseApi';
import { InstallBaseImportButton } from './ImportModal';

export const InstallBaseControls: React.FC = () => {
  const { params } = useGetParams([
    'productNumberContains',
    'nameContains',
    'locationCodeContains',
    'includeInactiveData',
    'returnInactiveDataOnly',
    'manufacturerNameContains',
    'categoryNameContains',
    'inServiceStartDate',
    'inServiceEndDate'
  ]);
  const { data: installBaseData, isLoading: isInstallBaseLoading, isFetching: isInstallBaseFetching } = useGetInstallBasesQuery(params);

  return (
    <>
      <Row style={{ marginBottom: 16, background: 'rgb(206, 213, 242)', padding: 8, borderRadius: 5 }} justify="space-between">
        <Col>
          <AntPageTitle text="Search Install Bases" />
        </Col>
        <Col>
          <AddInstallBaseButton />
        </Col>
      </Row>
      <Row style={{ padding: '24px 16px', background: 'white', marginBottom: 15, justifyContent: 'space-between' }}>
        <Col span={16}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Space direction="vertical" style={{ width: '100%' }} size={15}>
                <Row gutter={[8, 8]}>
                  <Col span={4}>
                    <ProductNumberFilter />
                  </Col>
                  <Col span={4}>
                    <InstallBaseIdContains />
                  </Col>
                  <Col span={4}>
                    <LocationCodeFilter />
                  </Col>
                  <Col span={4}>
                    <ManufacturerFilter />
                  </Col>
                  <Col span={4}>
                    <CategoryFilter />
                  </Col>
                </Row>
                <Row>
                  <Row gutter={[8, 8]}>
                    <Col>
                      <PresetDateRangePicker type="inService" style={{ width: 375 }} startPlaceholer="In Service Start Range" endPlaceholder="In Service End Range" />
                    </Col>
                    <Col>
                      <IsActiveFilter />
                    </Col>
                  </Row>
                </Row>
              </Space>
            </Col>
          </Row>
        </Col>
        <Space align="start">
          <InstallBaseImportButton />
          <ExportAssetsButton
            entityName="installBase"
            disabled={!installBaseData}
            loading={isInstallBaseFetching || isInstallBaseLoading}
            exportDocumentName="install-base"
            data={installBaseData?.data ?? []}
          />
        </Space>
      </Row>
    </>
  );
};
