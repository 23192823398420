import { DatePicker, TimeRangePickerProps } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const { RangePicker } = DatePicker;

dayjs.extend(quarterOfYear);

type Props = RangePickerProps & {
  startPlaceholer: string;
  endPlaceholder: string;
  type: 'start' | 'inService' | 'end';
};
type RangeValue = [dayjs.Dayjs | null, dayjs.Dayjs | null] | null;

export const PresetDateRangePicker: FC<Props> = ({ startPlaceholer, endPlaceholder, type, ...rest }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [val, setVal] = useState<any[] | undefined>(undefined);

  const currentYear = dayjs().year();
  const currentQuarter = dayjs().quarter();

  useEffect(() => {
    if (type === 'inService') {
      setVal([dayjs(searchParams.get('inServiceStartDate')), dayjs(searchParams.get('inServiceEndDate'))]);
    }
    if (type === 'start') {
      setVal([dayjs(searchParams.get('startDate')), dayjs(searchParams.get('startDateRangeEnd'))]);
    }
    if (searchParams.get('endDate') && searchParams.get('endDateRangeEnd') && type === 'end') {
      setVal([dayjs(searchParams.get('endDate')), dayjs(searchParams.get('endDateRangeEnd'))]);
    }
  }, [searchParams, type]);

  const handleChange = (val: RangeValue): void => {
    switch (type) {
      case 'inService': {
        if (!val) {
          searchParams.delete('inServiceStartDate');
          searchParams.delete('inServiceEndDate');
          setSearchParams(searchParams);

          return;
        }
        const inServiceStartDate = dayjs(val[0]).format('MM-DD-YYYY');
        const inServiceEndDate = dayjs(val[1]).format('MM-DD-YYYY');

        setSearchParams({ ...Object.fromEntries([...searchParams]), inServiceStartDate, inServiceEndDate });
        break;
      }

      case 'start': {
        if (!val) {
          searchParams.delete('startDate');
          searchParams.delete('startDateRangeEnd');
          setSearchParams(searchParams);

          return;
        }
        const startDate = dayjs(val[0]).format('MM-DD-YYYY');
        const startDateRangeEnd = dayjs(val[1]).format('MM-DD-YYYY');

        setSearchParams({ ...Object.fromEntries([...searchParams]), startDate, startDateRangeEnd });
        break;
      }

      case 'end': {
        if (!val) {
          searchParams.delete('endDate');
          searchParams.delete('endDateRangeEnd');
          setSearchParams(searchParams);

          return;
        }
        const endDate = dayjs(val[0]).format('MM-DD-YYYY');
        const endDateRangeEnd = dayjs(val[1]).format('MM-DD-YYYY');

        setSearchParams({ ...Object.fromEntries([...searchParams]), endDate, endDateRangeEnd });
        break;
      }
    }
  };

  const quarters = {
    lastQ1: [dayjs(`${currentYear - 1}-10-01`), dayjs(`${currentYear - 1}-12-31`)],
    Q1: [dayjs(`${currentYear}-01-01`), dayjs(`${currentYear}-03-31`)],
    Q2: [dayjs(`${currentYear}-04-01`), dayjs(`${currentYear}-06-30`)],
    Q3: [dayjs(`${currentYear}-07-01`), dayjs(`${currentYear}-09-30`)],
    Q4: [dayjs(`${currentYear}-10-01`), dayjs(`${currentYear}-12-31`)]
  };

  const returnLastQuarterRange = (): [dayjs.Dayjs, dayjs.Dayjs] | dayjs.Dayjs[] => {
    if (currentQuarter === 4) return quarters['Q3'];
    if (currentQuarter === 3) return quarters['Q2'];
    if (currentQuarter === 2) return quarters['Q1'];

    return quarters['lastQ1'];
  };
  const returnThisQuarterRange = (): [dayjs.Dayjs, dayjs.Dayjs] | dayjs.Dayjs[] => {
    if (currentQuarter === 4) return quarters['Q4'];
    if (currentQuarter === 3) return quarters['Q3'];
    if (currentQuarter === 2) return quarters['Q2'];

    return quarters['Q1'];
  };

  const rangePresets: TimeRangePickerProps['presets'] = [
    { label: 'This Month', value: [dayjs(), dayjs().add(1, 'M')] },
    { label: 'This Quarter', value: returnThisQuarterRange() as [dayjs.Dayjs, dayjs.Dayjs] },
    { label: 'This Year', value: [dayjs(), dayjs().add(1, 'y')] },
    { label: 'Last Month', value: [dayjs().add(-1, 'M'), dayjs()] },
    { label: 'Last Quarter', value: returnLastQuarterRange() as [dayjs.Dayjs, dayjs.Dayjs] },
    { label: 'Last Year', value: [dayjs().add(-1, 'y'), dayjs()] }
  ];

  return <RangePicker placeholder={[startPlaceholer, endPlaceholder]} onChange={handleChange} {...rest} presets={rangePresets} />;
};
