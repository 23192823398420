import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { ApiMultipleResponse } from 'models/ApiModels';
import { ReduxState } from 'redux/store';

/* ******************** Base Query ******************** */
const baseUrl = process.env.REACT_APP_CAPTAIN_BREAKFAST_BASE_URL;
const functionsKey = process.env.REACT_APP_API_HOST_KEY_CAPTAIN_BREAKFAST;

export type CategoryPayload = {
  name: string;
  description?: string;
};

export type CategoryResponse = {
  createdByUserFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  description: string;
  division: {
    business: {
      dataAreaId: string;
      erpId: string;
      id: string;
      name: string;
    };
    erpId: string;
    id: string;
    name: string;
  };
  documentType: string;
  id: string;
  modifiedByUserFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  name: string;
  partitionKey: string;
  partitionKeyDescription: string;
};

export const captainBreakfastBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const divisionId = (api.getState() as ReduxState).app.acuityContext?.selectedCustomer.id;
  const isDiagnostic = typeof args !== 'string' && (args.url === '/diagnostics/version' || args.url === '/diagnostics/apiName');
  const isDivisionNeeded =
    typeof args !== 'string' && (args.url === '/diagnostics/version' || args.url === '/diagnostics/apiName' || args.url === '/intangibleAssetStatuses' || args.url === '/intangibleAssetTypes');

  if (!divisionId && !isDivisionNeeded) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No division ID received'
      }
    };
  }

  const urlEnd = typeof args === 'string' ? args : args.url;
  const adjustedUrl = isDiagnostic ? args.url : `divisions/${divisionId}/${urlEnd}`;
  const adjustedArgs = typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };

  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as ReduxState).app.accessToken;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('x-functions-key', functionsKey);
        headers.set('Content-Type', 'application/json');
      }

      return headers;
    }
  })(adjustedArgs, api, extraOptions);
};

export const categoriesApi = createApi({
  reducerPath: 'categoriesApi',
  baseQuery: captainBreakfastBaseQuery,
  tagTypes: ['Categories', 'Category'],
  endpoints: (builder) => ({
    getCategories: builder.query<ApiMultipleResponse<CategoryResponse>, void>({
      providesTags: ['Categories'],
      query: () => ({
        url: 'categories'
      })
    }),

    getCategory: builder.query<CategoryResponse, string>({
      providesTags: ['Category'],
      query: (id) => ({
        url: `categories/${id}`
      })
    }),
    createCategory: builder.mutation<CategoryResponse, CategoryPayload>({
      invalidatesTags: ['Categories'],
      query: (payload) => ({
        url: 'categories',
        method: 'POST',
        body: payload
      })
    }),
    updateCategory: builder.mutation<CategoryResponse, { id: string; payload: CategoryPayload }>({
      invalidatesTags: ['Categories'],
      query: ({ id, payload }) => ({
        url: `categories/${id}`,
        method: 'PUT',
        body: payload
      })
    }),
    deleteCategory: builder.mutation<void, string>({
      invalidatesTags: ['Categories'],
      query: (assetID) => ({
        url: `categories/${assetID}/?wouldYouLikeToPlayAGame=true`,
        method: 'DELETE'
      })
    })
  })
});

export const { useCreateCategoryMutation, useDeleteCategoryMutation, useGetCategoriesQuery, useGetCategoryQuery, useUpdateCategoryMutation } = categoriesApi;
