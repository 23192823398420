import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { expiringChartGenerator } from 'common/helpers/chartDataGenerator';
import dayjs from 'dayjs';
import { ApiMultipleResponse } from 'models/ApiModels';
import { Division } from 'models/Division';
import { ChartDataIntangibleAssetModel } from 'models/Reports';
import { setImportLoadingMessage, setIsImporting } from 'redux/slices/importSlice';
import { ReduxState } from 'redux/store';
import * as yup from 'yup';
import { LineItemErrorObjectAsset, Prodizzy, QueryErrorModel } from '../install-base/installBaseApi';
import { ProductResponse } from '../products/productsApi';

/* ******************** Base Query ******************** */
const baseUrl = process.env.REACT_APP_CAPTAIN_BREAKFAST_BASE_URL;
const functionsKey = process.env.REACT_APP_API_HOST_KEY_CAPTAIN_BREAKFAST;

export type IntAssetPayload = {
  name: string;
  productId: string;
  quantity?: number;
  endDate: string | dayjs.Dayjs;
  startDate: string | dayjs.Dayjs;
  contract?: string;
  totalCost?: number;
  type: string;
  unitCost?: number;
  status: string;
  isActive?: boolean;
  isDeleted?: boolean;
};

export type IntAssetParams = {
  productNumberContains?: string;
  nameContains?: string;
  offset?: number;
  take?: number;
  nameEquals?: string;
  overrideSkipTake?: boolean;
};

export type IntAssetResponse = {
  contract: string;
  createdByUserFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  division: Division;
  documentType: string;
  endDate: string;
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  modifiedByUserFullname: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  name: string;
  partitionKey: string;
  partitionKeyDescription: string;
  availableQuantity: number;
  usedQuantity: number;
  product: Prodizzy;
  quantity: number;
  startDate: string;
  status: string;
  totalCost: number;
  type: string;
  unitCost: number;
};

export const intangibleAssetBaseSchema: yup.SchemaOf<IntAssetPayload> = yup.object({
  name: yup.string().label('Intangible Asset Id').required(),
  productId: yup.string().label('Product Number').required(),
  quantity: yup.number().label('Quantity').notRequired(),
  endDate: yup.string().label('End Date').required(),
  startDate: yup.string().label('Start Date').required(),
  contract: yup.string().label('Contract').notRequired(),
  type: yup.string().label('Type').required(),
  unitCost: yup.number().label('Unit Cost').notRequired(),
  totalCost: yup.number().label('Total Cost').notRequired(),
  status: yup.string().label('Status').required(),
  isActive: yup.boolean(),
  isDeleted: yup.boolean()
});

export const captainBreakfastBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const divisionId = (api.getState() as ReduxState).app.acuityContext?.selectedCustomer.id;
  const isDiagnostic = typeof args !== 'string' && (args.url === '/diagnostics/version' || args.url === '/diagnostics/apiName');
  const isDivisionNeeded =
    typeof args !== 'string' && (args.url === '/diagnostics/version' || args.url === '/diagnostics/apiName' || args.url === '/intangibleAssetStatuses' || args.url === '/intangibleAssetTypes');

  if (!divisionId && !isDivisionNeeded) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No division ID received'
      }
    };
  }

  const urlEnd = typeof args === 'string' ? args : args.url;
  const adjustedUrl = isDiagnostic ? args.url : `divisions/${divisionId}/${urlEnd}`;
  const adjustedArgs = typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };

  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as ReduxState).app.accessToken;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('x-functions-key', functionsKey);
        headers.set('Content-Type', 'application/json');
      }

      return headers;
    }
  })(adjustedArgs, api, extraOptions);
};

export const intangibleAssetsApi = createApi({
  reducerPath: 'intangibleAssetsApi',
  baseQuery: captainBreakfastBaseQuery,
  tagTypes: ['Assets', 'Asset', 'ChartData'],
  endpoints: (builder) => ({
    getIntangibleAssets: builder.query<ApiMultipleResponse<IntAssetResponse>, IntAssetParams>({
      providesTags: ['Assets'],
      query: (params) => ({
        url: 'intangibleAssets',
        params,
        responseHandler: (response): Promise<ApiMultipleResponse<IntAssetResponse> | string> => (response.status >= 300 ? response.text() : response.json())
      })
    }),

    getIntangibleAsset: builder.query<IntAssetResponse, string>({
      providesTags: ['Asset'],
      query: (id) => ({
        url: `intangibleAssets/${id}`,
        responseHandler: (response): Promise<ApiMultipleResponse<IntAssetResponse> | string> => (response.status >= 300 ? response.text() : response.json())
      })
    }),
    getIntangibleAssetChartData: builder.query<ChartDataIntangibleAssetModel, IntAssetParams>({
      providesTags: ['ChartData'],
      query: (params) => ({
        url: 'intangibleAssets',
        params
      }),
      transformResponse: (data: ApiMultipleResponse<IntAssetResponse>) => {
        // console.log(data.data, 'look');
        // const assignmentChart = assignmentsChartGenerator(data.data);
        // const typeChart = typeChartGenerator(data.data);
        // const statusChart = statusChartGenerator(data.data);
        // const ownerChart = ownerChartGenerator(data.data);
        const expiringChart = expiringChartGenerator(data.data);
        const fullData = data.data;

        return { fullData, expiringChart };
      }
    }),
    importIntangibleAssets: builder.mutation<{ data: string; lineErrors: LineItemErrorObjectAsset[] }, IntAssetPayload[]>({
      invalidatesTags: ['Assets'],
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        queryApi.dispatch(setIsImporting(true));

        const lineErrors: LineItemErrorObjectAsset[] = [];

        for await (const [idx, payloadLine] of arg.entries()) {
          queryApi.dispatch(setImportLoadingMessage(`Importing Intangible Asset Line Item ${idx + 1} of ${arg.length}`));

          try {
            await intangibleAssetBaseSchema.validate(payloadLine);
          } catch (err) {
            console.log(err);
            lineErrors.push({ intangibleAssetId: payloadLine.name ?? 'Error', errorMessage: (err as { message: string }).message });

            continue;
          }

          const getProductResult = await baseQuery(`/products?productNumberContains=${payloadLine.productId}`);

          if (getProductResult.error) {
            const err = getProductResult.error as QueryErrorModel;

            console.log(getProductResult.error, 'product number error');
            lineErrors.push({ intangibleAssetId: payloadLine.name, errorMessage: err.data.errorMessage });

            continue;
          }

          if ((getProductResult.data as ApiMultipleResponse<ProductResponse>).totalCount === 0) {
            lineErrors.push({ intangibleAssetId: payloadLine.name, errorMessage: 'Product Number not found' });

            continue;
          }

          const currentProduct = (getProductResult.data as ApiMultipleResponse<ProductResponse>).data.find((item) => item?.productNumber === payloadLine.productId);

          const payload: IntAssetPayload = {
            ...payloadLine,
            productId: currentProduct?.id ?? '',
            startDate: dayjs(payloadLine.startDate).format('YYYY-MM-DD'),
            endDate: dayjs(payloadLine.endDate).format('YYYY-MM-DD'),
            unitCost: Number(payloadLine?.unitCost?.toFixed(2)) ?? 0,
            totalCost: Number(payloadLine.unitCost?.toFixed(2)) * (payloadLine.quantity ?? 0) ?? 1
          };

          const createResult = await baseQuery({ url: `/intangibleAssets`, method: 'POST', body: payload });

          if (createResult.error) {
            const err = createResult.error as QueryErrorModel;

            console.log(createResult.error, 'create error');

            lineErrors.push({ intangibleAssetId: payloadLine.name, errorMessage: err.data.errorMessage });

            continue;
          }
        }

        queryApi.dispatch(setIsImporting(false));

        return { data: { data: 'Success', lineErrors } };
      }
    }),
    createIntangibleAsset: builder.mutation<IntAssetResponse, IntAssetPayload>({
      invalidatesTags: ['Assets'],
      query: (payload) => ({
        url: 'intangibleAssets',
        method: 'POST',
        body: payload
      })
    }),
    updateIntangibleAsset: builder.mutation<IntAssetResponse, { id: string; payload: IntAssetPayload }>({
      invalidatesTags: ['Assets'],
      query: ({ id, payload }) => ({
        url: `intangibleAssets/${id}`,
        method: 'PUT',
        body: payload
      })
    }),
    deleteIntangibleAsset: builder.mutation<void, string>({
      invalidatesTags: ['Assets'],
      query: (assetID) => ({
        url: `intangibleAssets/${assetID}/?wouldYouLikeToPlayAGame=true`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useCreateIntangibleAssetMutation,
  useDeleteIntangibleAssetMutation,
  useGetIntangibleAssetQuery,
  useGetIntangibleAssetsQuery,
  useUpdateIntangibleAssetMutation,
  useLazyGetIntangibleAssetsQuery,
  useGetIntangibleAssetChartDataQuery,
  useImportIntangibleAssetsMutation
} = intangibleAssetsApi;
