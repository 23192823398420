import { CloseCircleFilled, DeleteOutlined, DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Modal, Row, Select, SelectProps, Space, Spin, Tooltip, Typography, message } from 'antd';
import { MessageType } from 'antd/es/message/interface';
import { BaseOptionType } from 'antd/es/select';
import { SizedLoader } from 'components/common/SizedLoader';
import { FieldInput } from 'components/ui/FormItems/FieldInput';
import { FormLabel } from 'components/ui/FormItems/FormLabel';
import { Field, FieldProps, FormikErrors, FormikProvider, useFormik, useFormikContext } from 'formik';
import { CSSProperties, FC, useState } from 'react';
import * as yup from 'yup';

import { CategoryPayload, useCreateCategoryMutation, useDeleteCategoryMutation, useGetCategoriesQuery, useUpdateCategoryMutation } from 'redux/services/captainBreakfast/categories/categoriesApi';
import { useGetProductsQuery } from 'redux/services/captainBreakfast/products/productsApi';

interface Props extends SelectProps {
  fieldName: string;
  label: string;
  labelStyle?: CSSProperties;
}

export const categorySchema: yup.SchemaOf<CategoryPayload> = yup.object({
  name: yup.string().label('Name').required(),
  description: yup.string().label('Description').notRequired()
});

export const CategorySelect: FC<Props> = ({ fieldName, label, labelStyle }) => {
  const { data, isLoading, isFetching } = useGetCategoriesQuery();
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const { data: productsData } = useGetProductsQuery({});

  const isDeleteDisabled = (categoryName: string): boolean => {
    return productsData?.data.some((product) => product.category?.name === categoryName) ?? false;
  };

  const [create, { isLoading: isCreating }] = useCreateCategoryMutation();
  const [deleteCat, { isLoading: isDeleting }] = useDeleteCategoryMutation();
  const [update, { isLoading: isUpating }] = useUpdateCategoryMutation();

  const [isOpen, setIsOpen] = useState(false);
  const [hover, setHover] = useState('');
  const [labelHover, setLabelHover] = useState('');
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [editingValue, setEditingValue] = useState('');
  const [selectHover, setSelectHover] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [isAddOpen, setIsAddOpen] = useState(false);

  const formik = useFormik<CategoryPayload>({
    enableReinitialize: true,
    validationSchema: categorySchema,
    initialValues: {
      name: '',
      description: ''
    },
    onSubmit: async (values) => {
      if (data?.data.some((cat) => cat.name === values.name)) return message.error('Category already exists');
      try {
        const resp = await create(values).unwrap();

        setIsAddOpen(false);
        message.success(`Category ${resp.name} created successfully`);
        setFieldValue(fieldName, resp.name);
      } catch (error) {
        console.log(error);
        setIsAddOpen(false);
        message.error((error as { data: { errorMessage: string } }).data.errorMessage);
      }

      formik.resetForm();
    }
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEditingValue(e.target.value);
  };

  const finishEdit = async (categoryName: string): Promise<void | MessageType> => {
    const findId = data?.data.find((cat) => cat.name === categoryName);

    if (!findId) return message.error('Category not found');
    if (data?.data.some((cat) => cat.name === editingValue)) return message.error('Category already exists');
    try {
      const resp = await update({ id: findId.id, payload: { ...findId, name: editingValue } }).unwrap();

      setFieldValue(fieldName, resp.name);
      setIsAddOpen(false);
      setEditingKey(null);
      setLabelHover('');
      message.success(`Category ${resp.name} updated successfully`);
    } catch (error) {
      console.log(error);
      setIsAddOpen(false);
      message.error((error as { data: { errorMessage: string } }).data.errorMessage);
      setEditingKey(null);
      setLabelHover('');
    }
  };

  const handleAddCategory = (): void => {
    setFieldTouched(fieldName, false);
    setIsAddOpen(true);
  };

  const handleDeleteCategory = async (e: React.MouseEvent<HTMLElement, MouseEvent>, categoryName: string, fieldValue: string): Promise<void | MessageType> => {
    e.stopPropagation();
    e.preventDefault();
    setFieldTouched(fieldName, false);
    const findId = data?.data.find((cat) => cat.name === categoryName);

    if (!findId) return message.error('Category not found');
    if (findId.name === fieldValue) return message.error('Cannot delete category that is currently selected');

    try {
      await deleteCat(findId.id).unwrap();

      setIsAddOpen(false);
      message.success(`Category deleted successfully`);
    } catch (error) {
      console.log(error);
      setIsAddOpen(false);
      message.error((error as { data: { errorMessage: string } }).data.errorMessage);
    }
  };

  const renderOptions = (): BaseOptionType[] | undefined => {
    if (searchVal && data)
      return data.data.filter((opt) => opt.name.toLowerCase().includes(searchVal.toLowerCase())).map((category) => ({ key: category.id, label: category.name, value: category.name }));

    return data?.data.map((category) => ({ key: category.id, label: category.name, value: category.name }));
  };

  const filterOptions = (val: string): void => {
    setSearchVal(val);
  };

  // if (isLoading || isFetching || isCreating) return <div>Loading...</div>;

  return (
    <>
      <Field name={fieldName}>
        {({
          field, // { name, value, onChange, onBlur }
          form: { setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta
        }: FieldProps): JSX.Element => {
          return (
            <Spin style={{ width: '100%' }} spinning={isLoading || isFetching} indicator={<SizedLoader small />}>
              <Row style={{ width: '100%' }}>
                <FormLabel style={labelStyle} label={label} />
                <Select
                  {...field}
                  showSearch
                  searchValue={searchVal}
                  onDropdownVisibleChange={(open): void => {
                    setEditingKey(null);
                    setLabelHover('');
                    if (isAddOpen) {
                      setFieldTouched(fieldName, false);

                      return;
                    }
                    setIsOpen(open);
                  }}
                  open={isOpen}
                  onSearch={filterOptions}
                  onMouseEnter={(): void => setSelectHover(true)}
                  onMouseLeave={(): void => setSelectHover(false)}
                  suffixIcon={
                    selectHover && (meta.value || searchVal) ? (
                      <CloseCircleFilled
                        onClick={(): void => {
                          setFieldValue(fieldName, '');
                          setSearchVal('');
                        }}
                      />
                    ) : (
                      <DownOutlined />
                    )
                  }
                  dropdownRender={(): JSX.Element => (
                    <div className="options-container" style={{ maxHeight: 350, overflowY: 'scroll' }}>
                      <Spin spinning={isDeleting} indicator={<SizedLoader small loadingMessage="Deleting category" />}>
                        {renderOptions()?.map((option) => {
                          if (editingKey === option.key) {
                            return (
                              <Spin spinning={isUpating} key={option.key} indicator={<SizedLoader small />} style={{ padding: '4px 8px' }}>
                                <Input
                                  onFocus={(): Promise<void | FormikErrors<unknown>> => setFieldTouched(fieldName, false)}
                                  value={editingValue}
                                  onChange={handleInputChange}
                                  // onBlur={(): Promise<void | MessageType> => finishEdit(option.value)}
                                  onPressEnter={(): Promise<void | MessageType> => finishEdit(option.value)}
                                />
                              </Spin>
                            );
                          }

                          return (
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                            <Row
                              justify={'space-between'}
                              key={option.key}
                              onMouseEnter={(): void => setHover(option.key)}
                              onMouseLeave={(): void => setHover('')}
                              style={{ padding: '4px 8px', marginBottom: 2, background: hover === option.key ? 'rgba(0, 0, 0, 0.04)' : undefined, borderRadius: 5, cursor: 'pointer', height: 32 }}
                              onClick={(): void => {
                                setFieldValue(fieldName, option.value.toUpperCase());
                                setIsOpen(false);
                              }}>
                              <Typography.Text
                                style={{ padding: '1px 4px', marginBottom: 2, cursor: 'text', border: labelHover === option.key ? '1px solid #1890ff' : undefined, borderRadius: 5 }}
                                onMouseEnter={(): void => setLabelHover(option.key)}
                                onMouseLeave={(): void => setLabelHover('')}
                                onClick={(e): void => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setEditingKey(option.key);
                                  setEditingValue(option.label);
                                  setFieldTouched(fieldName, false);
                                }}>
                                {option.label}
                              </Typography.Text>
                              <Tooltip title={isDeleteDisabled(option.value) ? 'Cannot delete a category that is assigned to a product' : undefined}>
                                <Button
                                  loading={isDeleting && hover === option.key}
                                  onClick={(e): Promise<void | MessageType> => handleDeleteCategory(e, option.value, meta.value)}
                                  disabled={isDeleteDisabled(option.value)}
                                  size="small"
                                  icon={<DeleteOutlined style={{ color: isDeleteDisabled(option.value) ? 'gray' : 'red' }} />}
                                />
                              </Tooltip>
                            </Row>
                          );
                        })}
                      </Spin>
                      <Divider style={{ margin: '8px 0' }} />
                      <Button type="text" onClick={handleAddCategory} icon={<PlusOutlined />}>
                        Add item
                      </Button>
                    </div>
                  )}
                  status={meta.error && meta.touched ? 'error' : undefined}
                  onFocus={(): void => {
                    setFieldTouched(fieldName, false);
                  }}
                  onBlur={(): void => {
                    setFieldTouched(fieldName, true);
                  }}
                  style={{ width: '100%' }}
                />
                <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
              </Row>
            </Spin>
          );
        }}
      </Field>
      <Modal
        onCancel={(): void => {
          setIsAddOpen(false);
          formik.resetForm();
        }}
        confirmLoading={isCreating}
        title="Create Category"
        onOk={async (): Promise<void> => await formik.submitForm()}
        open={isAddOpen}>
        <Divider />
        <div style={{ marginBottom: 32 }}>
          <Spin spinning={isCreating} indicator={<SizedLoader medium loadingMessage="Creating category" />}>
            <FormikProvider value={formik}>
              <Space style={{ width: '100%' }} size={8} direction="vertical">
                <FieldInput forceCaps fieldName="name" label="Name" />
                <FieldInput fieldName="description" label="Description" />
              </Space>
            </FormikProvider>
          </Spin>
        </div>
      </Modal>
    </>
  );
};
