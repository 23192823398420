import { DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Tooltip, Typography } from 'antd';
import Upload from 'antd/es/upload/Upload';
import ButtonGroup from 'antd/lib/button/button-group';
import { RcFile } from 'antd/lib/upload';
import React from 'react';
import { LineItemErrorObjectProduct } from 'redux/services/captainBreakfast/install-base/installBaseApi';
import { ProductPayload, useImportProductMutation } from 'redux/services/captainBreakfast/products/productsApi';
import { useAppSelector } from 'redux/store';
import { read, utils, writeFile } from 'xlsx';

export const ProductsImportButton: React.FC = () => {
  /* ******************** Hooks ******************** */
  const [importProduct] = useImportProductMutation();

  const { isImporting } = useAppSelector((state) => state.import);

  const handleErrorModal = (importErrors: LineItemErrorObjectProduct[]): void => {
    Modal.confirm({
      title: 'Import Errors',
      bodyStyle: { maxHeight: 800 },
      cancelText: 'Export',
      onCancel: () => {
        const worksheeet = utils.json_to_sheet(importErrors);
        const workbook = utils.book_new();

        utils.book_append_sheet(workbook, worksheeet, 'assets');
        writeFile(workbook, 'intangible-assets-import-errors.xlsx');
      },
      width: 1000,
      icon: <></>,
      content: (
        <div style={{ border: '1px solid rgba(0,0,0,.1)', borderRadius: 10, padding: 10, marginTop: 20, overflow: 'scroll', height: 600 }}>
          <Row style={{ marginTop: 20, marginLeft: 5, marginBottom: 5 }}>
            <Col span={5}>
              <Typography.Text style={{ fontSize: 15 }}>Item Id</Typography.Text>
            </Col>
            <Col span={19}>
              <Typography.Text style={{ fontSize: 15 }}>Message</Typography.Text>
            </Col>
          </Row>
          {importErrors.map((error, i) => (
            <Row gutter={[10, 10]} key={error.errorMessage + i} style={{ padding: 10, background: 'rgba(0,0,0,.1)', borderRadius: 10, marginBottom: 10 }}>
              <Col span={5}>{error.productNumber}</Col>
              <Col span={19}>{error.errorMessage}</Col>
            </Row>
          ))}
        </div>
      )
    });
  };

  const handleImport = async (importData: ProductPayload[]): Promise<void> => {
    const res = await importProduct(importData).unwrap();

    !!res.lineErrors.length && handleErrorModal(res.lineErrors);
  };

  const parseData = async (file: RcFile): Promise<void> => {
    const reader = new FileReader();

    reader.onload = (e): void => {
      if (e.target && e.target.result !== null) {
        const data = e.target.result;
        const workbook = read(data, { type: 'array' });

        const sheetNameAssetHeader = workbook.SheetNames[0];

        const worksheetAssetHeader = workbook.Sheets[sheetNameAssetHeader];

        const jsonAssetHeader = utils.sheet_to_json(worksheetAssetHeader, { raw: false });

        const payload: ProductPayload[] = jsonAssetHeader.map((item: any) => ({
          productNumber: item['Product Number'],
          alternateItemId: item['Alternate Item Id'],
          description: item['Product Description'],
          manufacturerId: item['Manufacturer'] ?? '',
          categoryId: item['Category'] ?? 1,
          productType: item['Product Type'] ?? ''
        }));

        handleImport(payload);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <ButtonGroup>
      <Upload beforeUpload={parseData} showUploadList={false}>
        <Button disabled={isImporting} loading={isImporting}>
          Import
        </Button>
      </Upload>
      <Tooltip title="Download import template">
        <Button download href={`/MDSi_Products_Import_Template.xlsx`}>
          <DownloadOutlined />
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
};
