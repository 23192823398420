import { Card, Col, Collapse, Row, Typography } from 'antd';
import { AssetDetailsForm } from 'components/molecules/AssetDetailsForm';
import { IntangibleItemDetailsForm } from 'components/molecules/IntangibleItemDetailsForm';
import { InlineStylesModel } from 'models/InlineStylesModel';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetIntangibleAssetQuery } from 'redux/services/captainBreakfast/captainBreakfastApi';

const styles: InlineStylesModel = {
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#84859a',
    justifyContent: 'space-between',
    width: '100%',
    padding: 24,
    borderBottom: '2px solid rgba(0,0,0,.1)'
  },
  headerTitle: {
    margin: 0,
    padding: 0,
    fontWeight: 'normal'
  },
  container: {
    color: 'rgba(0,0,0, 0.85)',
    width: '100%',
    marginBottom: 5
  }
};

export const AssetDetails: React.FC = () => {
  const { Panel } = Collapse;
  const nav = useNavigate();
  const { assetId } = useParams();
  const { pathname } = useLocation();
  const isEditPage = pathname.includes('/edit');
  const { data: toggledAsset, isLoading } = useGetIntangibleAssetQuery(assetId as string);

  return (
    <Row gutter={[10, 0]}>
      <Col span={12}>
        <Card
          headStyle={{ background: 'rgb(255, 234, 193)' }}
          title={
            <Row justify="space-between" align="middle">
              <Typography.Title level={4} style={styles.headerTitle}>
                Asset Details
              </Typography.Title>
              {/* <Button onClick={(): void => (isEditPage ? nav(`/edit/${assetId}/asset-items`) : nav('/create/asset-items'))} style={styles.nextButton}>
              Continue to Assignments <RightCircleFilled />
            </Button> */}
            </Row>
          }>
          <AssetDetailsForm />
        </Card>
      </Col>
      <Col span={12}>
        <Card
          headStyle={{ background: 'rgba(24, 167, 153, 0.518)' }}
          title={
            <Row justify="space-between" align="middle">
              <Typography.Title level={4} style={styles.headerTitle}>
                Intangible Item Details
              </Typography.Title>
              {/* <Button onClick={(): void => (isEditPage ? nav(`/edit/${assetId}/asset-items`) : nav('/create/asset-items'))} style={styles.nextButton}>
              Continue to Assignments <RightCircleFilled />
            </Button> */}
            </Row>
          }>
          <IntangibleItemDetailsForm />
        </Card>
      </Col>

      {/* {isEditPage ? (
        <Collapse>
          <Panel style={{ background: 'rgb(255, 234, 193)' }} key="1" header="Audit History">
            <List dataSource={toggledAsset?.auditMessages} renderItem={(item): JSX.Element => <List.Item>{item}</List.Item>} />
          </Panel>
        </Collapse>
      ) : null} */}
    </Row>
  );
};
