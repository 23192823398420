import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IntangibleAssetImportPayload } from 'models/IntangibleAssets';
import { InstallBasePayload } from 'redux/services/captainBreakfast/install-base/installBaseApi';

interface InitialState {
  successItems: IntangibleAssetImportPayload[];
  importItems: { [x: string]: IntangibleAssetImportPayload[] };
  headerItems: { [x: string]: IntangibleAssetImportPayload[] };
  assignItems: { [x: string]: IntangibleAssetImportPayload[] };
  errorItems: any[];
  importTotal: number;
  importLoadingMessage: string;
  installBaseImportPayload: InstallBasePayload[];
  isImporting: boolean;
}

const initialState: InitialState = {
  successItems: [],
  errorItems: [],
  headerItems: {},
  assignItems: {},
  importItems: {},
  importTotal: 0,
  importLoadingMessage: '',
  installBaseImportPayload: [],
  isImporting: false
};

export const importSlice = createSlice({
  name: 'import',
  initialState,
  reducers: {
    setSuccessItems: (state, { payload }: PayloadAction<InitialState['successItems']>) => {
      state.successItems = payload;
    },
    setErrorItems: (state, { payload }: PayloadAction<InitialState['errorItems']>) => {
      state.errorItems = payload;
    },
    addSuccessItem: (state, { payload }: PayloadAction<InitialState['successItems'][number]>) => {
      state.successItems = [...state.successItems, payload];
    },
    addErrorItem: (state, { payload }: PayloadAction<InitialState['errorItems'][number]>) => {
      state.errorItems = [...state.errorItems, payload];
    },
    setImportTotal: (state, { payload }: PayloadAction<InitialState['importTotal']>) => {
      state.importTotal = payload;
    },
    setImportItems: (state, { payload }: PayloadAction<InitialState['importItems']>) => {
      state.importItems = payload;
    },
    setHeaderItems: (state, { payload }: PayloadAction<InitialState['headerItems']>) => {
      state.headerItems = payload;
    },
    setAssignItems: (state, { payload }: PayloadAction<InitialState['assignItems']>) => {
      state.assignItems = payload;
    },
    setInstallBaseImportPayload: (state, { payload }: PayloadAction<InitialState['installBaseImportPayload']>) => {
      state.installBaseImportPayload = payload;
    },
    setIsImporting: (state, { payload }: PayloadAction<InitialState['isImporting']>) => {
      state.isImporting = payload;
    },
    setImportLoadingMessage: (state, { payload }: PayloadAction<InitialState['importLoadingMessage']>) => {
      state.importLoadingMessage = payload;
    }
  }
});

export const {
  setErrorItems,
  setSuccessItems,
  addErrorItem,
  addSuccessItem,
  setImportTotal,
  setImportItems,
  setHeaderItems,
  setAssignItems,
  setInstallBaseImportPayload,
  setImportLoadingMessage,
  setIsImporting
} = importSlice.actions;
