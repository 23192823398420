import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InstallBaseResponse } from 'redux/services/captainBreakfast/install-base/installBaseApi';
import { IntAssetResponse } from 'redux/services/captainBreakfast/intangible-assets/intangibleAssetsApi';

interface InitialState {
  selectedInstallBases: InstallBaseResponse[];
  selectedIntangibleAssets: IntAssetResponse[];
  selectedRowKeys: React.Key[];
}

const initialState: InitialState = {
  selectedInstallBases: [],
  selectedIntangibleAssets: [],
  selectedRowKeys: []
};

export const selectedEntitiesToAssign = createSlice({
  name: 'selectedEntitiesToAssign',
  initialState,
  reducers: {
    setSelectedInstallBases: (state, { payload }: PayloadAction<InstallBaseResponse[]>) => {
      state.selectedInstallBases = payload;
    },
    setSelectedIntangibleAssets: (state, { payload }: PayloadAction<IntAssetResponse[]>) => {
      state.selectedIntangibleAssets = payload;
    },
    setSelectedRowKeys: (state, { payload }: PayloadAction<React.Key[]>) => {
      state.selectedRowKeys = payload;
    }
  }
});

export const { setSelectedInstallBases, setSelectedRowKeys, setSelectedIntangibleAssets } = selectedEntitiesToAssign.actions;
