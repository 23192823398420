import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, Dropdown, Menu, Row, Space, Spin, message } from 'antd';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { AntPageTitle } from 'components/ui/AntPageTitle';
import { FormikProvider, useFormik } from 'formik';
import { ErrorModel } from 'models/ErrorModel';
import { IntangibleAssetPayload, createIntangibleAssetPayload } from 'models/IntangibleAssets';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import {
  useCreateIntangibleAssetAssignmentsMutation,
  useGetIntangibleAssetAssignmentsQuery,
  useGetIntangibleAssetQuery,
  useUpdateIntangibleAssetAssignmentsMutation,
  useUpdateIntangibleAssetMutation
} from 'redux/services/captainBreakfast/captainBreakfastApi';

export const EditAssetPage: React.FC = () => {
  /* ******************** Hooks ******************** */
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { assetId } = useParams();

  const { data: toggledAsset, isLoading } = useGetIntangibleAssetQuery(assetId ?? skipToken);
  const { data: toggledAssetAssignments, isLoading: isAssignmentsLoading } = useGetIntangibleAssetAssignmentsQuery(assetId ?? skipToken);

  const [updateIntangibleAsset, { isLoading: isUpdating }] = useUpdateIntangibleAssetMutation();
  const [createIntangibleAssetAssignment, { isLoading: isCreating }] = useCreateIntangibleAssetAssignmentsMutation();
  const [updateIntangibleAssetAssignments, { isLoading: isUpdatingAssignments }] = useUpdateIntangibleAssetAssignmentsMutation();

  /* ******************** API Hooks ******************** */
  //

  const formik = useFormik<IntangibleAssetPayload>({
    enableReinitialize: true,
    validationSchema: createIntangibleAssetPayload,
    initialValues: {
      assetAssignments: toggledAssetAssignments ? toggledAssetAssignments.data : [],
      assetOwner: toggledAsset ? toggledAsset.assetOwner : '',
      assetSku: toggledAsset ? toggledAsset.assetSku : '',
      assetStatus: toggledAsset ? toggledAsset.assetStatus : '',
      location: toggledAsset ? toggledAsset.location : '',
      assetType: toggledAsset ? toggledAsset.assetType : '',
      contractId: toggledAsset ? toggledAsset.contractId : '',
      endDate: toggledAsset ? toggledAsset.endDate : '',
      intangibleAssetNumber: toggledAsset ? toggledAsset.intangibleAssetNumber : '',
      manufacturer: toggledAsset ? toggledAsset.manufacturer : '',
      note: toggledAsset ? toggledAsset.note : '',
      startDate: toggledAsset ? toggledAsset.startDate : '',
      totalQuantity: toggledAsset ? toggledAsset.totalQuantity : 0,
      unitCost: toggledAsset ? toggledAsset.unitCost : 0
    },
    onSubmit: async (values) => {
      console.log('form submitted attempt');
    }
  });

  const isValidSave = formik.values.intangibleAssetNumber && formik.values.assetStatus && formik.values.assetType ? true : false;
  /* ******************** Functions ******************** */

  const handleClose = (): void => {
    navigate('/search');
  };

  const handleSave = async (close?: boolean): Promise<void> => {
    try {
      const intangibleHeader = { ...formik.values, isActive: true, id: toggledAsset?.id };

      delete intangibleHeader.assetAssignments;

      const data = await updateIntangibleAsset(intangibleHeader).unwrap();

      if (formik.values.assetAssignments && toggledAsset) {
        for (const assignment of formik.values.assetAssignments) {
          if (assignment.id) {
            //console.log('updating..');
            await updateIntangibleAssetAssignments({ ...assignment, assetId: toggledAsset.id }).unwrap();
          } else {
            //console.log('creating..');
            await createIntangibleAssetAssignment({ ...assignment, assetId: toggledAsset.id }).unwrap();
          }
        }
      }
      message.success('Successfully Updated!');
      if (close) {
        handleClose();
      }
    } catch (error) {
      console.log(error);
      message.error((error as ErrorModel).data.errorMessage);
    }
  };

  const menuJSX = (
    <Menu disabledOverflow={true}>
      <Menu.Item key="1" onClick={(): Promise<void> => handleSave(false)}>
        Save
      </Menu.Item>
    </Menu>
  );

  if (isLoading || isAssignmentsLoading) return <LoaderWithMessage />;

  return (
    <FormikProvider value={formik}>
      <Spin spinning={isCreating || isUpdating || isUpdatingAssignments} indicator={<LoaderWithMessage loadingMessage="Updating Instangible Asset" />}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Row justify="space-between">
            <AntPageTitle text={`Edit Asset: ${toggledAsset?.intangibleAssetNumber || ''}`} />
            <Space>
              <Button onClick={handleClose} icon={<ArrowLeftOutlined />}>
                Back to Search
              </Button>
              <Dropdown.Button disabled={!isValidSave} overlay={menuJSX} onClick={(): Promise<void> => handleSave(true)} loading={formik.isSubmitting} type="primary" icon={<SaveOutlined />}>
                Save and Exit
              </Dropdown.Button>
            </Space>
          </Row>
          {/* <StepControls /> */}

          <Outlet />
        </Space>
      </Spin>
    </FormikProvider>
  );
};
