import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { FancyInput } from '../Inputs';

export const SerialNumberFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const [searchParams, setSearchParams] = useSearchParams();

  /* ******************** Functions ******************** */
  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const params = Object.fromEntries([...searchParams]);

    setSearchParams({ ...params, serialNumberContains: value });
    if (!value) {
      searchParams.delete('serialNumberContains');
      setSearchParams(searchParams);
    }
  }, 500);

  return <FancyInput defaultValue={searchParams.get('serialNumberContains') || ''} placeholder="Serial Number" onChange={handleChange} />;
};
