import { Button, Modal, Spin, message } from 'antd';
import { ColoredCard } from 'components/common/ColoredCard';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { InstallBaseForm } from 'components/molecules/InstallBaseForm';
import dayjs from 'dayjs';
import { FormikProvider, useFormik } from 'formik';
import { installBaseSchema } from 'models/InstallBase';
import { FC, useState } from 'react';
import {
  InstallBasePayload,
  InstallBaseResponse,
  useGetInstallBasesQuery,
  useLazyGetInstallBasesQuery,
  useUpdateInstallBaseMutation
} from 'redux/services/captainBreakfast/install-base/installBaseApi';

type Props = {
  record: InstallBaseResponse;
};

export const EditInstallBaseButton: FC<Props> = ({ record }) => {
  const dateFormatted = dayjs(record.inServiceDate);
  const [update] = useUpdateInstallBaseMutation();
  const { data: installBaseData } = useGetInstallBasesQuery({});
  const [getLazy] = useLazyGetInstallBasesQuery();
  const [isUpdating, setIsUpdating] = useState(false);

  // console.log(test.format('YYYY-MM-DD'));
  const [isOpen, setIsOpen] = useState(false);
  const formik = useFormik<InstallBasePayload>({
    enableReinitialize: true,
    validationSchema: installBaseSchema,
    initialValues: {
      name: record.name ?? '',
      productId: record.product.id ?? '',
      locationId: record.location.id ?? '',
      macAddress: record.macAddress ?? '',
      serialNumber: record.serialNumber ?? '',
      inServiceDate: dateFormatted ?? '',
      ipAddress: record.ipAddress ?? '',
      quantity: record.quantity ?? 1,
      status: record.status ?? '',
      // tenantId: '',
      isActive: true,
      isDeleted: false
    },
    onSubmit: async (values) => {
      // if (values.quantity === 0) return message.error('Quantity cannot be 0');
      // if (filteredBases?.some((installBase) => installBase.name.toUpperCase() === values.name.toUpperCase())) return message.error('Install base already exists');
      // if (filteredBases?.some((installBase) => installBase.serialNumber === values.serialNumber)) return message.error('Serial number already exists');
      const date = dayjs(values.inServiceDate).utc().format('YYYY-MM-DD HH:mm:ss[Z]');

      const payload = { ...values, inServiceDate: date };

      try {
        setIsUpdating(true);
        const getResponse = await getLazy({ nameEquals: values.name }).unwrap();

        if (getResponse.totalCount && values.name.toUpperCase() !== record.name.toUpperCase()) {
          setIsUpdating(false);

          return message.error('Install base already exists');
        }
        const resp = await update({ id: record.id, payload }).unwrap();

        message.success(`Install base ${resp.name} was updated successfully`);
        formik.resetForm();
        setIsOpen(false);
        setIsUpdating(false);
      } catch (err) {
        console.log(err, 'err');
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
        setIsOpen(false);
        formik.resetForm();
        setIsUpdating(false);
      }
    }
  });

  return (
    <>
      <Button type="link" onClick={() => setIsOpen(true)}>
        {record.name}
      </Button>
      <Modal
        okText="Submit"
        width={800}
        onOk={() => formik.submitForm()}
        onCancel={(): void => {
          setIsOpen(false);
          formik.resetForm();
        }}
        closable={false}
        open={isOpen}>
        <Spin style={{ display: 'flex', alignItems: 'center' }} spinning={isUpdating} indicator={<LoaderWithMessage loadingMessage="Updating install base" />}>
          <ColoredCard title={`Edit ${record.name}`} color="rgb(206, 213, 242)">
            <FormikProvider value={formik}>
              <InstallBaseForm isEdit />
            </FormikProvider>
          </ColoredCard>
        </Spin>
      </Modal>
    </>
  );
};
