import { AssetTable } from 'components/organisms/AssetTable';
import { HomeControls } from 'components/organisms/HomeControls';
import { Fragment } from 'react';

export const SearchPage = (): JSX.Element => {
  return (
    <Fragment>
      <HomeControls />
      <AssetTable />
    </Fragment>
  );
};
