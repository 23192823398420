import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, MutationDefinition } from '@reduxjs/toolkit/query';
import { Button, Modal, Tooltip, Typography, message } from 'antd';
import { ButtonProps } from 'antd/lib';
import { FC, useState } from 'react';
import Lottie from 'react-lottie-player';
import { useDispatch } from 'react-redux';
import { installBaseApi } from 'redux/services/captainBreakfast/install-base/installBaseApi';
import { intangibleAssetsApi } from 'redux/services/captainBreakfast/intangible-assets/intangibleAssetsApi';
import { productsApi } from 'redux/services/captainBreakfast/products/productsApi';
import deleteLottie from '../../assets/trash.json';

type Props = ButtonProps & {
  recordToDelete: string;
  title: string;
  tooltip?: string;
  deleteRecord: MutationTrigger<
    MutationDefinition<
      string,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
      'Products' | 'Product' | 'Bases' | 'Base' | 'Asset' | 'Assets' | 'Assignments' | 'Assignment' | 'ChartData',
      void,
      'productsApi' | 'installBaseApi' | 'intangibleAssetsApi' | 'assetAssignmentApi'
    >
  >;
  isDisabled?: boolean;
  isDeleting?: boolean;
};

export const DeleteRecordButton: FC<Props> = ({ recordToDelete, title, deleteRecord, tooltip, isDisabled, ...rest }) => {
  const [hover, setHover] = useState(false);
  const dispatch = useDispatch();
  const handleDelete = async (): Promise<void> => {
    console.log(recordToDelete);

    try {
      await deleteRecord(recordToDelete).unwrap();
      message.success('Record deleted successfully');
      dispatch(productsApi.util.invalidateTags(['Products']));
      dispatch(intangibleAssetsApi.util.invalidateTags(['Assets']));
      dispatch(installBaseApi.util.invalidateTags(['Bases']));
    } catch (err) {
      console.log(err);
      message.error('Error deleting record');
    }
  };

  return (
    <Tooltip title={tooltip ?? 'Delete'}>
      <Button
        disabled={isDisabled}
        onMouseEnter={(): void => setHover(true)}
        onMouseLeave={(): void => setHover(false)}
        style={{ backgroundColor: hover ? '#FFEBEB' : undefined }}
        onClick={(): void => {
          Modal.confirm({
            title: <Typography.Text>{title}</Typography.Text>,
            icon: <WarningOutlined style={{ color: 'red' }} />,
            onOk: handleDelete
          });
        }}
        icon={isDisabled ? <DeleteOutlined /> : <Lottie loop goTo={65} play={hover} style={{ width: 22, height: 22 }} animationData={deleteLottie} />}
        {...rest}
      />
    </Tooltip>
  );
};
