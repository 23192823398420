import { Col, Form, Row } from 'antd';
import { FieldInputSelect } from 'components/ui/FormItems/FieldInputSelect';
import { ReadOnlyField } from 'components/ui/FormItems/ReadOnlyField';
import dayjs from 'dayjs';
import { useField } from 'formik';
import { useGetIntangibleAssetsQuery } from 'redux/services/captainBreakfast/intangible-assets/intangibleAssetsApi';

export const IntangibleItemDetailsForm = (): JSX.Element => {
  const { data: intangibleAssetData, isLoading: isIaLoading } = useGetIntangibleAssetsQuery({ overrideSkipTake: true });
  const [{ value }] = useField('intangibleAssetId');

  const intangibleAsset = intangibleAssetData?.data.find((item) => item.id === value);

  const formatedStartDate = intangibleAsset ? dayjs(intangibleAsset.startDate).format('MM-DD-YYYY') : '';
  const formatedEndDate = intangibleAsset ? dayjs(intangibleAsset.endDate).format('MM-DD-YYYY') : '';

  return (
    <Form layout="vertical">
      <Row gutter={[16, 32]}>
        <Col span={24}>
          <FieldInputSelect loading={isIaLoading} intangibleAssetOptionsArr={intangibleAssetData?.data} fieldName="intangibleAssetId" label="Intangible Asset Id" />
        </Col>
        <Col span={24}>
          <ReadOnlyField label={'Type'} value={intangibleAsset?.type} />
        </Col>
        <Col span={24}>
          <ReadOnlyField label={'Start Date'} value={formatedStartDate} />
        </Col>
        <Col span={24}>
          <ReadOnlyField label={'End Date'} value={formatedEndDate} />
        </Col>
        <Col span={24}>
          <ReadOnlyField label={'Contract'} value={intangibleAsset?.contract} />
        </Col>
        <Col span={24}>
          <ReadOnlyField label={'Unit Cost'} value={intangibleAsset?.unitCost} />
        </Col>
      </Row>
    </Form>
  );
};
