import { Spin, Tooltip } from 'antd';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetAssetAssignmentsQuery } from 'redux/services/captainBreakfast/asset-assignments/assetAssignmentApi';
import { InstallBaseResponse } from 'redux/services/captainBreakfast/install-base/installBaseApi';
import { IntAssetResponse } from 'redux/services/captainBreakfast/intangible-assets/intangibleAssetsApi';

type Props = {
  originNode: React.ReactNode;
  record: IntAssetResponse | InstallBaseResponse;
  setIsCellDisabled: React.Dispatch<React.SetStateAction<string[]>>;
  type?: 'intangibleAsset';
};

export const IntangibleAssetCheckBox: FC<Props> = ({ originNode, record, type, setIsCellDisabled }) => {
  const { id } = useParams();

  const { data: assetData, isLoading, isFetching } = useGetAssetAssignmentsQuery({ intangibleAssetIdEquals: record.id, installBaseIdEquals: id }, { skip: !id || !record.id });

  useEffect(() => {
    if (assetData?.data.length) setIsCellDisabled((prev) => [...prev, record.id]);
  }, [assetData?.data.length]);

  return (
    <Spin size="small" spinning={isLoading || isFetching}>
      <Tooltip
        title={assetData?.totalCount ? 'Asset assignment already exists' : type === 'intangibleAsset' && (record as IntAssetResponse).availableQuantity <= 0 ? 'Quantity available is 0' : undefined}>
        {originNode}
      </Tooltip>
    </Spin>
  );
};
