import { Col, Row, Space } from 'antd';
import { AddIntangibleAssetButton } from 'components/atoms/AddIntangibleAssetButton';
import { ManufacturerFilter } from 'components/atoms/Filters';
import { EndDateRangePicker } from 'components/atoms/Filters/EndDateRangePicker';
import { IntangibleAssetIdFilter } from 'components/atoms/Filters/IntangibleAssetFilters/IntangibleAssetIdFilter';
import { PresetDateRangePicker } from 'components/atoms/Filters/PresetDateRangePicker';
import { AssetTypeFilter } from 'components/atoms/Filters/ProductFilters/AssetTypeFilter';
import { CategoryFilter } from 'components/atoms/Filters/ProductFilters/CategoryFilter';
import { IsActiveFilter } from 'components/atoms/Filters/ProductFilters/IsActiveFilter';
import { ProductNumberFilter } from 'components/atoms/Filters/ProductFilters/ProductNumberFilter';
import ExportAssetsButton from 'components/atoms/Inputs/ExportAssetsButton';
import { AntPageTitle } from 'components/ui/AntPageTitle';
import { useGetParams } from 'hooks/useGetParams';
import React from 'react';
import { useGetIntangibleAssetsQuery } from 'redux/services/captainBreakfast/intangible-assets/intangibleAssetsApi';
import { IntangibleAssetsImport } from './IntangibleAssetsImport';

export const IntangibleItemControls: React.FC = () => {
  // const { pathname } = useLocation();
  // const isRecycleBin = pathname.includes('recycling-bin');

  const { params } = useGetParams([
    'productNumberContains',
    'nameContains',
    'manufacturerNameContains',
    'categoryNameContains',
    'startDate',
    'startDateRangeEnd',
    'endDate',
    'endDateRangeEnd',
    'types'
  ]);
  const { data, isLoading, isFetching } = useGetIntangibleAssetsQuery(params);

  return (
    <>
      <Row style={{ marginBottom: 16, background: 'rgb(255, 234, 193)', padding: 8, borderRadius: 5 }} justify="space-between">
        <Col>
          <AntPageTitle text="Search Intangible Assets" />
        </Col>
        <Col>
          <AddIntangibleAssetButton />
        </Col>
      </Row>
      <Row style={{ padding: '24px 16px', background: 'white', marginBottom: 15, justifyContent: 'space-between' }}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Space direction="vertical" style={{ width: '100%' }} size={15}>
              <Row gutter={[8, 8]}>
                <Col>
                  <ProductNumberFilter />
                </Col>
                <Col>
                  <IntangibleAssetIdFilter />
                </Col>
                <Col>
                  <AssetTypeFilter />
                </Col>
                <Col>
                  <ManufacturerFilter />
                </Col>
                <Col>
                  <CategoryFilter />
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col>
                  <PresetDateRangePicker type="start" style={{ width: 315 }} startPlaceholer="Start Date Range" endPlaceholder="Start Date Range" />
                </Col>
                <Col>
                  <EndDateRangePicker style={{ width: 315 }} startPlaceholer="End Date Range" endPlaceholder="End Date Range" />
                </Col>
                <Col>
                  <IsActiveFilter />
                </Col>
              </Row>
            </Space>
          </Col>
        </Row>
        <Space align="start">
          <IntangibleAssetsImport />
          <ExportAssetsButton loading={isLoading || isFetching} entityName="intangibleAssets" exportDocumentName="intangible-assets" data={data?.data ?? []} />
        </Space>
      </Row>
    </>
  );
};
