import { colors, toRgb, toRgba } from 'common/styles/colors';
import { BarChartModel } from 'models/Reports';
import { AssetAssignmentResponse } from 'redux/services/captainBreakfast/asset-assignments/assetAssignmentApi';
import { InstallBaseResponse } from 'redux/services/captainBreakfast/install-base/installBaseApi';
import { IntAssetResponse } from 'redux/services/captainBreakfast/intangible-assets/intangibleAssetsApi';

interface ChartDataStager {
  name: string;
  totalCount: number;
}

const random_RGBA = (): string => {
  const o = Math.round,
    r = Math.random,
    s = 255;

  return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + '.6)';
};

// const { data } = useLazyGetma({});

export const monthConverter = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const monthIndexConverter = (monthName: string): number => {
  switch (monthName) {
    case 'January':
      return 0;

    case 'February':
      return 1;

    case 'March':
      return 2;

    case 'April':
      return 3;

    case 'May':
      return 4;

    case 'June':
      return 5;

    case 'July':
      return 6;

    case 'August':
      return 7;

    case 'September':
      return 8;

    case 'October':
      return 9;

    case 'November':
      return 10;

    case 'December':
      return 11;

    default:
      return -1;
  }
};

export const manufacturerChartGenerator = (allAssets: AssetAssignmentResponse[]): BarChartModel => {
  const groupedByManufacturer = allAssets.reduce((accumulator: { [key: string]: AssetAssignmentResponse[] }, item) => {
    const key = item.installBase.product.manufacturer.name;

    if (!accumulator[key]) {
      accumulator[key] = [];
    }

    accumulator[key] = [...accumulator[key], item];

    return accumulator;
  }, {});

  const generatedColors = Object.values(groupedByManufacturer).map(() => random_RGBA());

  const assetManufacturerChartData = {
    labels: Object.keys(groupedByManufacturer),
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
      {
        label: 'Total Count',
        data: Object.values(groupedByManufacturer).map((val) => val.length),
        backgroundColor: generatedColors,
        borderWidth: 3,
        borderColor: generatedColors
      }
    ]
  };

  return assetManufacturerChartData;
};
export const unassignedChartGenerator = (
  assetAssignments: AssetAssignmentResponse[],
  installBases: InstallBaseResponse[],
  intangibleAssets: IntAssetResponse[]
): { chartData: BarChartModel; installBases: InstallBaseResponse[]; intangibleAssets: IntAssetResponse[] } => {
  const openInstallBases = installBases.filter((base) => !assetAssignments.some((asset) => asset.installBase.id === base.id));
  const openIntangibleAssets = intangibleAssets.filter((base) => !assetAssignments.some((asset) => asset.intangibleAsset.id === base.id));

  const unassignedChartData = {
    labels: ['Install Bases', 'Intangible Assets'],
    datasets: [
      {
        label: 'Total Count',
        data: [openInstallBases.length, openIntangibleAssets.length],
        backgroundColor: [toRgba(colors.illuminatingEmerald, 0.7), toRgba(colors.royalBlueLight, 0.7)],
        borderWidth: 3,
        borderColor: [toRgba(colors.illuminatingEmerald, 0.7), toRgba(colors.royalBlueLight, 0.7)]
      }
    ]
  };

  return { chartData: unassignedChartData, installBases: openInstallBases, intangibleAssets: openIntangibleAssets };
};
export const categoryChartGenerator = (allAssets: AssetAssignmentResponse[]): BarChartModel => {
  const groupedByManufacturer = allAssets.reduce((accumulator: { [key: string]: AssetAssignmentResponse[] }, item) => {
    const key = item.installBase.product.category.name;

    if (!accumulator[key]) {
      accumulator[key] = [];
    }

    accumulator[key] = [...accumulator[key], item];

    return accumulator;
  }, {});

  const generatedColors = Object.values(groupedByManufacturer).map(() => random_RGBA());

  const assetCategoryChartData = {
    labels: Object.keys(groupedByManufacturer),
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
      {
        label: 'Total Count',
        data: Object.values(groupedByManufacturer).map((val) => val.length),
        backgroundColor: generatedColors,
        borderWidth: 3,
        borderColor: generatedColors
      }
    ]
  };

  return assetCategoryChartData;
};

export const typeChartGenerator = (allAssets: AssetAssignmentResponse[]): BarChartModel => {
  const assetTypeChartData = {
    labels: ['Leases', 'License', 'Maintenance', 'Subscription'],
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
      {
        label: 'Total Count',
        data: [0, 0, 0, 0], // Leased, License, Maintenance, Subscription
        // you can set indiviual colors for each bar
        backgroundColor: [toRgba(colors.illuminatingEmerald, 0.7), toRgba(colors.royalBlueLight, 0.7), toRgba(colors.orangeWeb, 0.7), toRgba(colors.robinEggBlue, 0.7)],
        borderWidth: 3,
        borderColor: [toRgb(colors.illuminatingEmerald), toRgb(colors.royalBlueLight), toRgb(colors.orangeWeb), toRgb(colors.robinEggBlue)]
      }
    ]
  };

  allAssets?.map((eachAsset) => {
    // By AssetType -----------------------------------------
    if (eachAsset.intangibleAsset.type.toUpperCase() === 'LEASED EQUIPMENT') {
      assetTypeChartData.datasets[0].data[0] += 1;
    } else if (eachAsset.intangibleAsset.type.toUpperCase() === 'LICENSE') {
      assetTypeChartData.datasets[0].data[1] += 1;
    } else if (eachAsset.intangibleAsset.type.toUpperCase() === 'MAINTENANCE') {
      assetTypeChartData.datasets[0].data[2] += 1;
    } else if (eachAsset.intangibleAsset.type.toUpperCase() === 'SUBSCRIPTION') {
      assetTypeChartData.datasets[0].data[3] += 1;
    }
  });

  return assetTypeChartData;
};

export const statusChartGenerator = (allAssets: IntAssetResponse[]): BarChartModel => {
  const assetStatusChartData = {
    labels: ['Active', 'Cancelled', 'Expired'],
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
      {
        label: 'Total Count',
        data: [0, 0, 0], // Active, Cancelled, Expired
        // you can set indiviual colors for each bar
        backgroundColor: [toRgba(colors.illuminatingEmerald, 0.7), toRgba(colors.minimumYellow, 0.7), toRgba(colors.redSalsa, 0.7)],
        borderWidth: 3,
        borderColor: [toRgb(colors.illuminatingEmerald), toRgb(colors.minimumYellow), toRgb(colors.redSalsa)]
      }
    ]
  };

  allAssets?.map((eachAsset) => {
    // By Asset Status -----------------------------------------
    if (eachAsset.status.toUpperCase() === 'ACTIVE') {
      assetStatusChartData.datasets[0].data[0] += 1;
    } else if (eachAsset.status.toUpperCase() === 'CANCELLED') {
      assetStatusChartData.datasets[0].data[1] += 1;
    } else if (eachAsset.status.toUpperCase() === 'EXPIRED') {
      assetStatusChartData.datasets[0].data[2] += 1;
    }
  });

  return assetStatusChartData;
};

// export const ownerChartGenerator = (allAssets: IntAssetResponse[]): BarChartModel => {
//   const ownerList: string[] = [];
//   const allOwners: ChartDataStager[] = [];

//   allAssets?.map((eachAsset) => {
//     // By AssetOwner -----------------------------------------
//     if (ownerList.includes(eachAsset.product.) === false && eachAsset.assetOwner) {
//       ownerList.push(eachAsset.assetOwner);
//       allOwners.push({ name: eachAsset.assetOwner, totalCount: 1 });
//     } else {
//       allOwners.map((each) => {
//         if (each.name === eachAsset.assetOwner) each.totalCount += 1;
//       });
//     }
//   });

//   return {
//     labels: allOwners.map((each) => each.name),
//     // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object rgba(132,133,154,.6)
//     datasets: [
//       {
//         label: 'Total Count',
//         data: allOwners.map((each) => each.totalCount),
//         // you can set indiviual colors for each bar
//         backgroundColor: allOwners.map((each) => random_RGBA()),
//         borderColor: allOwners.map((each) => 'rgba(0,0,0,.2)')
//       }
//     ]
//   };
// };

export const expiringChartGenerator = (allAssets: IntAssetResponse[]): BarChartModel => {
  const currentMonth = new Date().getMonth();

  const expirationDateChartData = {
    labels: [...Array(12)].map((each, index) => {
      if (currentMonth + index < 12) {
        return monthConverter[currentMonth + index];
      } else {
        return monthConverter[currentMonth + index - 12];
      }
    }),
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
      {
        label: 'Total Expiring Count',
        data: [...Array(12)].map(() => 0),
        // you can set indiviual colors for each bar
        backgroundColor: [...Array(12)].map((each, index) => {
          if (index === 0) return toRgba(colors.redSalsa, 0.5);
          else if (index === 1 || index === 2) return toRgba(colors.orangeWeb, 0.5);
          else return toRgba(colors.romanSilver, 0.5);
        }),
        borderWidth: 3
      }
    ]
  };

  const currentYear = new Date().getFullYear();
  const newYearMonths = currentMonth - 1;

  allAssets?.map((eachAsset) => {
    const assetExpirationYear = new Date(eachAsset.endDate).getFullYear();
    const assetExpirationMonthIndex = new Date(eachAsset.endDate).getMonth();

    if (assetExpirationMonthIndex > newYearMonths && currentYear === assetExpirationYear) {
      //populates THIS year's months data
      const dataIndex = assetExpirationMonthIndex - currentMonth;

      expirationDateChartData.datasets[0].data[dataIndex] += 1;
    } else if (assetExpirationMonthIndex <= newYearMonths && currentYear + 1 === assetExpirationYear) {
      //populates NEXT year's months data
      const dataIndex = 11 - newYearMonths + assetExpirationMonthIndex;

      expirationDateChartData.datasets[0].data[dataIndex] += 1;
    }
  });

  return expirationDateChartData;
};
