import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Spin, message } from 'antd';
import { ColoredCard } from 'components/common/ColoredCard';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { AssetDetailsForm } from 'components/molecules/AssetDetailsForm';
import { IntangibleItemDetailsForm } from 'components/molecules/IntangibleItemDetailsForm';
import { FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { AssetAssignmentPayload, useCreateAssetAssignmentMutation } from 'redux/services/captainBreakfast/asset-assignments/assetAssignmentApi';
import { assetAssignmentSchema } from './AssignIntangibleAssetButton';

export const AddAssetButton = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [createAssetAssignment, { isLoading }] = useCreateAssetAssignmentMutation();
  const formik = useFormik<AssetAssignmentPayload>({
    enableReinitialize: true,
    validationSchema: assetAssignmentSchema,
    initialValues: {
      installBaseId: '',
      intangibleAssetId: ''
    },
    onSubmit: async (values) => {
      console.log(values);
      // logic here to create product when api is ready
      try {
        const resp = await createAssetAssignment(values).unwrap();

        message.success(`Asset assignment was created successfully`);
        formik.resetForm();
        setIsOpen(false);
      } catch (err) {
        console.log(err, 'err');
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
        setIsOpen(false);
        formik.resetForm();
      }

      formik.resetForm();
    }
  });

  return (
    <>
      <Button onClick={(): void => setIsOpen(true)} icon={<PlusCircleOutlined />}>
        Add Asset Assignment
      </Button>
      <Modal
        okText="Submit"
        width={1200}
        onOk={() => formik.submitForm()}
        onCancel={(): void => {
          setIsOpen(false);
          formik.resetForm();
        }}
        closable={false}
        open={isOpen}>
        <Spin style={{ display: 'flex', alignItems: 'center' }} spinning={isLoading} indicator={<LoaderWithMessage loadingMessage="Creating asset assignment" />}>
          <FormikProvider value={formik}>
            <Row gutter={[16, 0]} style={{ height: '100%', width: '100%' }}>
              <Col span={12}>
                <ColoredCard title="Install Base Details" color="rgb(206, 213, 242)">
                  <AssetDetailsForm />
                </ColoredCard>
              </Col>
              <Col span={12}>
                <ColoredCard title="Intangible Asset Details" color="rgb(176, 178, 188)">
                  <IntangibleItemDetailsForm />
                </ColoredCard>
              </Col>
            </Row>
          </FormikProvider>
        </Spin>
      </Modal>
    </>
  );
};
