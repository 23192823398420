import { Col, Form, Row, Spin } from 'antd';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { CurrencyInput } from 'components/ui/FormItems/CurrencyInput';
import { FieldInput } from 'components/ui/FormItems/FieldInput';
import { FieldInputDatePicker } from 'components/ui/FormItems/FieldInputDatePicker';
import { FieldInputNumber } from 'components/ui/FormItems/FieldInputNumber';
import { FieldInputSelect } from 'components/ui/FormItems/FieldInputSelect';
import { FC } from 'react';
import { useGetProductsQuery } from 'redux/services/captainBreakfast/products/productsApi';

type Props = {
  isEdit?: boolean;
};

export const IntangibleDetailsForm: FC<Props> = ({ isEdit }) => {
  const { data: productsData, isLoading: isProdLoading } = useGetProductsQuery({ overrideSkipTake: true });
  const filteredProducts = productsData?.data?.filter((product) => product.productType === 'Intangible Asset');

  return (
    <Form layout="vertical" style={{ marginBottom: 32 }}>
      <Spin spinning={isProdLoading} indicator={<LoaderWithMessage loadingMessage="Loading" />}>
        <Row gutter={[16, 32]}>
          <Col span={24}>
            <FieldInput forceCaps fieldName="name" label={'Intangible Asset Id'} placeholder="Intangible Asset Id" />
          </Col>
          <Col span={24}>
            <FieldInputSelect disabled={isEdit} productsOptionsArr={filteredProducts} fieldName="productId" label="Intangible Item Id" placeholder={'Intangible Item Id'} />
          </Col>
          <Col span={24}>
            <FieldInputSelect optionsArr={['License', 'Subscription', 'Maintenance', 'Leased Equipment']} fieldName="type" label={'Type'} placeholder={'Type'} />
          </Col>
          <Col span={24}>
            <FieldInputSelect optionsArr={['Active', 'Expired', 'Cancelled']} fieldName="status" label={'Status'} placeholder={'Status'} />
          </Col>
          <Col span={24}>
            <FieldInput fieldName="contract" label={'Contract'} placeholder={'Contract'} />
          </Col>
          <Col span={24}>
            <FieldInputNumber fieldName="quantity" label={'Quantity'} placeholder={'Quantity'} />
          </Col>
          <Col span={24}>
            <CurrencyInput fieldName="unitCost" label={'Unit Cost'} placeholder={'Unit Cost'} />
          </Col>
          <Col span={24}>
            <CurrencyInput disabled fieldName="totalCost" label={'Total Cost'} placeholder={'Total Cost'} />
          </Col>
          <Col span={24}>
            <FieldInputDatePicker fieldName="startDate" label={'Start Date'} />
          </Col>
          <Col span={24}>
            <FieldInputDatePicker fieldName="endDate" label={'End Date'} />
          </Col>
        </Row>
      </Spin>
    </Form>
  );
};
