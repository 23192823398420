import { Col, Form, Row } from 'antd';
import { CategorySelect } from 'components/atoms/CategorySelect';
import { ManufacturerSelect } from 'components/atoms/ManufacturerSelect';
import { FieldInput } from 'components/ui/FormItems/FieldInput';
import { FieldInputSelect } from 'components/ui/FormItems/FieldInputSelect';
import { FC } from 'react';

type Props = {
  isEdit?: boolean;
};

export const ProductDetailsForm: FC<Props> = ({ isEdit }) => {
  return (
    <Form layout="vertical" style={{ marginBottom: 32 }}>
      <Row gutter={[16, 32]}>
        <Col span={24}>
          <FieldInput forceCaps disabled={isEdit} fieldName="productNumber" label={'Product Number'} placeholder={'Product Number'} />
        </Col>
        <Col span={24}>
          <FieldInput fieldName="alternateItemId" label={'Alternate Item Id'} placeholder={'Alternate Item Id'} />
        </Col>
        <Col span={24}>
          <FieldInput fieldName="description" label={'Product Description'} placeholder={'Product Description'} />
        </Col>
        <Col span={24}>
          <ManufacturerSelect fieldName="manufacturerId" label={'Manufacturer'} placeholder={'Manufacturer'} />
        </Col>
        <Col span={24}>
          <CategorySelect fieldName="categoryId" label={'Category'} placeholder={'Category'} />
        </Col>
        <Col span={24}>
          <FieldInputSelect disabled={isEdit} optionsArr={['Physical Part', 'Intangible Asset']} fieldName="productType" label={'Product Type'} placeholder={'Product Type'} />
        </Col>
      </Row>
    </Form>
  );
};
