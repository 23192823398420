import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  rowsEpxanded: boolean;
}

const initialState: InitialState = {
  rowsEpxanded: false
};

export const rowsEpxanded = createSlice({
  name: 'rowsExpanded',
  initialState: initialState,
  reducers: {
    setRowsExpanded: (state, { payload }: PayloadAction<boolean>) => {
      state.rowsEpxanded = payload;
    }
  }
});

export const { setRowsExpanded } = rowsEpxanded.actions;
