import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Spin, message } from 'antd';
import { ColoredCard } from 'components/common/ColoredCard';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { InstallBaseForm } from 'components/molecules/InstallBaseForm';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // import plugin
import { FormikProvider, useFormik } from 'formik';
import { installBaseSchema } from 'models/InstallBase';
import { useState } from 'react';
import { InstallBasePayload, useCreateInstallBaseMutation, useGetInstallBasesQuery, useLazyGetInstallBasesQuery } from 'redux/services/captainBreakfast/install-base/installBaseApi';
dayjs.extend(utc); // use plugin

export const AddInstallBaseButton = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [create] = useCreateInstallBaseMutation();
  const { data: installBaseData } = useGetInstallBasesQuery({ overrideSkipTake: false });
  const [getLazy] = useLazyGetInstallBasesQuery();
  const [isUpdating, setIsUpdating] = useState(false);

  const formik = useFormik<InstallBasePayload>({
    enableReinitialize: true,
    validationSchema: installBaseSchema,
    initialValues: {
      name: '',
      productId: '',
      locationId: '',
      macAddress: '',
      serialNumber: '',
      inServiceDate: '',
      ipAddress: '',
      // tenantId: '',
      quantity: 1
    },
    onSubmit: async (values) => {
      // if (values.quantity === 0) return message.error('Quantity cannot be 0');

      // if (installBaseData?.data.some((installBase) => installBase.name.toUpperCase() === values.name.toUpperCase())) return message.error('Install base already exists');
      if (installBaseData?.data.some((installBase) => installBase.serialNumber.toUpperCase() === values.serialNumber.toUpperCase() && values.productId === installBase.product.id))
        return message.error('That product and serial number combo already exists');
      const date = dayjs(values.inServiceDate).utc().format('YYYY-MM-DD HH:mm:ss[Z]');

      const payload = { ...values, inServiceDate: date };

      try {
        setIsUpdating(true);
        const getResponse = await getLazy({ nameContains: values.name }).unwrap();

        if (getResponse.data.some((installBase) => installBase.name.toUpperCase() === values.name.toUpperCase())) {
          setIsUpdating(false);

          return message.error('Install base already exists');
        }

        const resp = await create(payload).unwrap();

        message.success(`Install base ${resp.name} was created successfully`);
        formik.resetForm();
        setIsOpen(false);
        setIsUpdating(false);
      } catch (err) {
        console.log(err, 'err');
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
        setIsOpen(false);
        formik.resetForm();
        setIsUpdating(false);
      }
    }
  });

  return (
    <>
      <Button onClick={(): void => setIsOpen(true)} icon={<PlusCircleOutlined />}>
        Add Install Base
      </Button>
      <Modal
        okText="Submit"
        width={800}
        onOk={() => formik.submitForm()}
        onCancel={(): void => {
          setIsOpen(false);
          formik.resetForm();
        }}
        closable={false}
        open={isOpen}>
        <Spin style={{ display: 'flex', alignItems: 'center' }} spinning={isUpdating} indicator={<LoaderWithMessage loadingMessage="Creating install base" />}>
          <ColoredCard title="Add Install Base" color="rgb(206, 213, 242)">
            <FormikProvider value={formik}>
              <InstallBaseForm />
            </FormikProvider>
          </ColoredCard>
        </Spin>
      </Modal>
    </>
  );
};
