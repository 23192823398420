import { FancyInput } from 'components/atoms/Inputs';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

export const PhysicalProductNumberFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const [searchParams, setSearchParams] = useSearchParams();

  /* ******************** Functions ******************** */
  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const params = Object.fromEntries([...searchParams]);

    setSearchParams({ ...params, physicalProductNumberContains: value });
    if (!value) {
      searchParams.delete('physicalProductNumberContains');
      setSearchParams(searchParams);
    }
  }, 500);

  return <FancyInput defaultValue={searchParams.get('physicalProductNumberContains') || ''} placeholder="Product Number" onChange={handleChange} />;
};
