import { Button, Tooltip } from 'antd';
import { ButtonProps } from 'antd/lib';
import { FC, useState } from 'react';
import Lottie from 'react-lottie-player';
import { useNavigate } from 'react-router-dom';
import { AssetAssignmentPayload } from 'redux/services/captainBreakfast/asset-assignments/assetAssignmentApi';
import { IntAssetResponse } from 'redux/services/captainBreakfast/intangible-assets/intangibleAssetsApi';
import * as yup from 'yup';
import assignLottie from '../../assets/assign.json';

export const assetAssignmentSchema: yup.SchemaOf<AssetAssignmentPayload> = yup.object({
  installBaseId: yup.string().label('Install Base Id').required(),
  intangibleAssetId: yup.string().label('Intangible Asset Id').required()
});

type Props = ButtonProps & {
  record: IntAssetResponse;
};
export const AssignInstallBaseButton: FC<Props> = ({ record }) => {
  const [hover, setHover] = useState(false);
  const nav = useNavigate();

  return (
    <Tooltip title="Add Asset Assignment">
      <Button
        onMouseEnter={(): void => setHover(true)}
        onMouseLeave={(): void => setHover(false)}
        style={{ backgroundColor: hover ? '#FFF7E5' : undefined }}
        icon={
          <Lottie
            // segments={[5, 63]}
            animationData={assignLottie}
            loop={true}
            goTo={81}
            play={hover}
            style={{ width: 24, height: 24 }}
          />
        }
        onClick={(): void => nav(`/intangible-assets/${record.id}/assign`)}
      />
    </Tooltip>
  );
};
