import { DoughnutChart } from 'components/atoms/Charts/DoughnutChart';
import { useNavigate } from 'react-router-dom';
import { useGetAssetAssignmentsChartDataQuery, useGetAssetAssignmentsQuery } from 'redux/services/captainBreakfast/asset-assignments/assetAssignmentApi';

export const AssetCategoryChart = (): JSX.Element => {
  const nav = useNavigate();
  const { data: allAssets } = useGetAssetAssignmentsQuery({});
  const { data, isLoading, isFetching } = useGetAssetAssignmentsChartDataQuery({ take: allAssets?.totalCount, offset: 0 });

  const handleLabelDataClick = (labelIndex: number): void => {
    const labelValue = data?.catChart.labels[labelIndex];

    nav(`/assets?categoryNameContains=${labelValue}`);
  };

  return <DoughnutChart chartData={data?.catChart ?? { labels: [], datasets: [] }} chartTitle={'Asset Categories'} isLoading={isLoading} chartCutout={125} handleChartClick={handleLabelDataClick} />;
};
