import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Spin, message } from 'antd';
import { ColoredCard } from 'components/common/ColoredCard';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { ProductDetailsForm } from 'components/molecules/ProductDetailsForm';
import { FormikProvider, useFormik } from 'formik';
import { partSchema } from 'models/Product';
import { useState } from 'react';
import { useGetCategoriesQuery } from 'redux/services/captainBreakfast/categories/categoriesApi';
import { useGetManufacturersQuery } from 'redux/services/captainBreakfast/manufacturers/manufacturersApi';
import { ProductPayload, useCreateProductMutation, useGetProductsQuery, useLazyGetProductsQuery } from 'redux/services/captainBreakfast/products/productsApi';

export const AddProductButton = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const { data } = useGetManufacturersQuery();
  const { data: catData } = useGetCategoriesQuery();
  const { data: partsData } = useGetProductsQuery({});
  const [create] = useCreateProductMutation();
  const [getLazy] = useLazyGetProductsQuery();
  const [isUpdating, setIsUpdating] = useState(false);

  const formik = useFormik<ProductPayload>({
    enableReinitialize: true,
    validationSchema: partSchema,
    initialValues: {
      productNumber: '',
      description: '',
      manufacturerId: '',
      categoryId: '',
      alternateItemId: '',
      productType: '',
      isActive: true,
      isDeleted: false
    },
    onSubmit: async (values) => {
      const manufacturerId = data?.data.find((manufacturer) => manufacturer.name.toUpperCase() === values.manufacturerId.toUpperCase())?.id;
      const categoryId = catData?.data.find((category) => category.name.toUpperCase() === values.categoryId.toUpperCase())?.id;

      // if (partsData?.data.some((val) => val.productNumber.toUpperCase() === values.productNumber.toUpperCase())) return message.error('Product number already exists');

      if (!manufacturerId || !categoryId) return message.error('Manufacturer or category not found');

      const payload: ProductPayload = { ...values, manufacturerId, categoryId };

      try {
        setIsUpdating(true);
        const getResponse = await getLazy({ productNumberEquals: values.productNumber }).unwrap();

        if (getResponse.totalCount) {
          setIsUpdating(false);

          return message.error('Product number already exists');
        }
        const resp = await create(payload).unwrap();

        message.success(`Product ${resp.productNumber} was created successfully`);
        formik.resetForm();
        setIsOpen(false);
        setIsUpdating(false);
      } catch (err) {
        console.log(err, 'err');
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
        setIsOpen(false);
        formik.resetForm();
        setIsUpdating(false);
      }
    }
  });

  return (
    <>
      <Button onClick={(): void => setIsOpen(true)} icon={<PlusCircleOutlined />}>
        Add Part
      </Button>
      <Modal
        bodyStyle={{ padding: 0, margin: 0 }}
        okText={'Submit'}
        onOk={(): Promise<void> => formik.submitForm()}
        onCancel={(): void => {
          setIsOpen(false);
          formik.resetForm();
        }}
        closable={false}
        open={isOpen}>
        <Spin style={{ display: 'flex', alignItems: 'center' }} spinning={isUpdating} indicator={<LoaderWithMessage loadingMessage="Creating product" />}>
          <ColoredCard title="Add Product" color="rgba(24, 167, 153, 0.518)">
            <FormikProvider value={formik}>
              <ProductDetailsForm />
            </FormikProvider>
          </ColoredCard>
        </Spin>
      </Modal>
    </>
  );
};
