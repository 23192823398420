import { DeleteOutlined, ExclamationCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, Card, Form, Input, InputNumber, Space, Table, Tooltip, Typography, message } from 'antd';
import { Rule } from 'antd/lib/form';
import { useFormikContext } from 'formik';
import { AssetAssignmentPayload, IntangibleAssetPayload } from 'models/IntangibleAssets';
import { AlignType, FixedType } from 'rc-table/lib/interface';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDeleteIntangibleAssetAssignmentsMutation, useGetIntangibleAssetQuery } from 'redux/services/captainBreakfast/captainBreakfastApi';
import * as xlsx from 'xlsx';

const newDataPayload: AssetAssignmentPayload = {
  key: '',
  serialNumber: '',
  partNumber: '',
  port: '',
  location: '',
  assignedQuantity: 1,
  note: 'enter notes here'
};

interface ExportData {
  serialNumber: string;
  partNumber: string;
  port: string | number;
  location: string | null;
  assignedQuantity: number;
  note: string;
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: 'number' | 'text';
  record: AssetAssignmentPayload;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, title, record, children, ...restProps }) => {
  const inputNode = dataIndex === 'assignedQuantity' || dataIndex === 'port' ? <InputNumber datatype="" name={dataIndex} /> : <Input name={dataIndex} />;

  const { values } = useFormikContext<IntangibleAssetPayload>();
  const findMax = useCallback(
    (record: AssetAssignmentPayload) => {
      let max = undefined;

      if (!values.assetAssignments) return;

      values.assetAssignments.map((item) => {
        if (record.id === item.id && item.serialNumber) {
          max = 1;
        }
      });

      return max;
    },
    [values]
  );

  const findRules = (record: AssetAssignmentPayload): Rule[] => {
    if (dataIndex !== 'assignedQuantity') {
      return [
        {
          required: true,
          message: `Please Input ${title}!`
        }
      ];
    } else {
      return [
        {
          required: true,
          message: `Please Input a ${title}!`
        },
        {
          type: 'number',
          max: findMax(record),
          message: (
            <Tooltip title="If the assignment is serialized the quantity cannot exceed 1">
              <ExclamationCircleOutlined style={{ fontSize: 14, marginLeft: 6 }} />
            </Tooltip>
          )
        }
      ];
    }
  };

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }} rules={findRules(record)}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const LineItemTable: React.FC = () => {
  const { pathname } = useLocation();
  const isEditPage = pathname.includes('/edit');
  const { setFieldValue, values } = useFormikContext<IntangibleAssetPayload>();
  const [form] = Form.useForm();

  const { assetId } = useParams();

  const { data: assetData, isLoading: isAssetLoading } = useGetIntangibleAssetQuery(assetId ? assetId : skipToken);

  const [deleteAssignment, { isLoading: isDeleteing }] = useDeleteIntangibleAssetAssignmentsMutation();

  const [data, setData] = useState(values.assetAssignments || []);
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record: AssetAssignmentPayload): boolean => record.key === editingKey;

  const edit = (record: AssetAssignmentPayload): void => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key || '');
  };

  const cancel = (): void => {
    setEditingKey('');
    setFieldValue('assetAssignments', [...data]);
  };

  const save = async (key: React.Key): Promise<void> => {
    try {
      const row = (await form.getFieldsValue()) as AssetAssignmentPayload;

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];

        newData.splice(index, 1, {
          ...item,
          ...row
        });
        setData(newData);
      } else {
        newData.push(row);
        setData(newData);
      }
      setFieldValue('assetAssignments', [...newData]);
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleAddNew = (): void => {
    if (values.assetAssignments && assetData && values?.assetAssignments.length >= assetData?.totalQuantity) {
      message.error('Total quantity reached. No more assets can be assigned');

      return;
    }
    const newDataIndex = data.length.toString();
    const dataStager = { ...newDataPayload, key: newDataIndex };
    const newData = [...data, dataStager];

    setData(newData);
    setFieldValue('assetAssignments', newData);
  };

  const handleDelete = async (record: AssetAssignmentPayload): Promise<void> => {
    const stager = values.assetAssignments;

    if (!stager) {
      message.error('Cannot find asset, please try again later');

      return;
    }

    if (Object.keys(record).includes('id') && record.id && assetId) {
      try {
        await deleteAssignment({ assetId, assignmentId: record.id });
        const newData = stager.filter((item) => item?.key !== record.key);

        setData(newData);
        setFieldValue('assetAssignments', newData);
        message.success('Assignment successfully deleted');
      } catch (err) {
        console.log(err);
        message.error('Failed to be deleted');
      }
    } else {
      const newData = stager.filter((item) => item?.key !== record.key);

      setData(newData);
      setFieldValue('assetAssignments', newData);
      // message.success('Assignment successfully deleted');
    }
    form.setFieldsValue({ ...record });
    setEditingKey('');
  };

  const handleExportLineItems = (): void => {
    const dataForExport = data.map(({ serialNumber, partNumber, port, location, assignedQuantity, note }: ExportData) => ({
      serialNumber: serialNumber ?? '',
      partNumber: partNumber ?? '',
      port: port ?? '',
      location: location ?? '',
      assignedQuantity: assignedQuantity ?? '',
      note: note ?? ''
    }));

    const worksheeet = xlsx.utils.json_to_sheet(dataForExport);
    const workbook = xlsx.utils.book_new();

    xlsx.utils.book_append_sheet(workbook, worksheeet, 'asset-assignments');
    xlsx.writeFile(workbook, `${assetData?.intangibleAssetNumber}-asset-assignments.xlsx`);
  };

  const columns = [
    {
      title: 'Serial Number',
      dataIndex: 'serialNumber',
      width: '15%',
      editable: true,
      render: (value: string): JSX.Element => {
        return <Typography.Link style={{ textAlign: 'left', color: 'black' }}>{typeof value === 'undefined' ? null : value}</Typography.Link>;
      }
    },
    {
      title: 'Part Number',
      dataIndex: 'partNumber',
      width: '15%',
      editable: true,
      render: (value: string): JSX.Element => {
        return <Typography.Link style={{ textAlign: 'left', color: 'black' }}>{typeof value === 'undefined' ? null : value}</Typography.Link>;
      }
    },
    {
      title: 'Port',
      dataIndex: 'port',
      width: '10%',
      editable: true,
      render: (value: string): JSX.Element => {
        return <Typography.Link style={{ textAlign: 'left', color: 'black' }}>{typeof value === 'undefined' ? null : value}</Typography.Link>;
      }
    },
    {
      title: 'Location',
      dataIndex: 'location',
      width: '10%',
      editable: true,
      render: (value: string): JSX.Element => {
        return <Typography.Link style={{ textAlign: 'left', color: 'black' }}>{typeof value === 'undefined' ? null : value}</Typography.Link>;
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'assignedQuantity',
      width: '8%',
      editable: true,
      render: (value: string): JSX.Element => {
        return <Typography.Link style={{ textAlign: 'left', color: 'black' }}>{typeof value === 'undefined' ? null : value}</Typography.Link>;
      }
    },
    {
      title: 'Notes',
      dataIndex: 'note',
      width: '30%',
      editable: true,
      render: (value: string): JSX.Element => {
        return <Typography.Link style={{ textAlign: 'left', color: 'black' }}>{typeof value === 'undefined' ? null : value}</Typography.Link>;
      }
    },
    {
      title: 'Actions',
      key: 'action',
      width: '10ch',
      align: 'center' as AlignType,
      fixed: 'right' as FixedType,
      render: (_: string, record: AssetAssignmentPayload): JSX.Element => (
        <Button loading={isDeleteing} onClick={(): Promise<void> => handleDelete(record)} icon={<DeleteOutlined />} style={{ color: 'rgba(255,0,0.7)' }} />
      )
    }
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: AssetAssignmentPayload): { record: AssetAssignmentPayload; inputType: string; dataIndex: string; title: string; editing: boolean; onChange: () => void } => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        onChange: (): void => {
          if (record.key) save(record.key);
        }
      })
    };
  });

  useEffect(() => {
    if (values.assetAssignments) {
      setData(values.assetAssignments);
    }
  }, [values.assetAssignments]);

  return (
    <Form form={form} component={false}>
      <Card
        extra={
          <Space>
            <Button onClick={handleAddNew} icon={<PlusCircleOutlined />}>
              Add New Asset Assignment
            </Button>
            {isEditPage && (
              <Button type="primary" loading={isAssetLoading} onClick={handleExportLineItems}>
                Export Assignments
              </Button>
            )}
          </Space>
        }
        headStyle={{ background: 'rgba(24, 167, 153, 0.518)' }}
        title={
          <Typography.Title style={{ margin: 0 }} level={4}>
            {isEditPage ? 'Split' : 'New'} Asset Assignments
          </Typography.Title>
        }>
        <Table
          components={{
            body: {
              cell: EditableCell
            }
          }}
          bordered
          scroll={{ x: 'max-content' }}
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel
          }}
          onRow={(record: AssetAssignmentPayload): { onClick: () => void } => {
            return {
              onClick: (): void => {
                edit(record);
              }
            };
          }}
        />
      </Card>
    </Form>
  );
};

export default LineItemTable;
