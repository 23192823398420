import { ProductControls } from 'components/molecules/ProductControls';
import { PartsMasterTable } from 'components/organisms/PartsMasterTable';
import { FC } from 'react';

export const PartsMaster: FC = () => {
  return (
    <>
      <ProductControls />
      <PartsMasterTable />
    </>
  );
};
