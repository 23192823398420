import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IntangibleAssetParams } from 'models/IntangibleAssets';

const initialState: IntangibleAssetParams = {
  assetType: undefined,
  assetSkuContains: undefined,
  assetStatus: undefined,
  assetOwnerContains: undefined,
  manufacturerContains: undefined,
  includeDeletedData: undefined,
  includeInactiveData: undefined,
  orderByDirection: undefined,
  orderByField: undefined,
  returnDeletedDataOnly: undefined,
  returnInactiveDataOnly: undefined,
  offset: 0,
  assetId: undefined,
  intangibleAssetNumberContains: undefined,
  expirationStartDate: undefined,
  expirationEndDate: undefined,
  locationContains: undefined,
  serialNumberContains: undefined,
  partNumberContains: undefined,
  take: undefined
};

export const captainBreakfastParams = createSlice({
  name: 'captainBreakfastParams',
  initialState,
  reducers: {
    setIncludeDeletedData: (state, { payload }: PayloadAction<IntangibleAssetParams['includeDeletedData']>) => {
      state.includeDeletedData = payload;
    },
    setIncludeInactiveData: (state, { payload }: PayloadAction<IntangibleAssetParams['includeInactiveData']>) => {
      state.includeInactiveData = payload;
    },
    setReturnDeletedDataOnly: (state, { payload }: PayloadAction<IntangibleAssetParams['returnDeletedDataOnly']>) => {
      state.returnDeletedDataOnly = payload;
    },
    setReturnInactiveDataOnly: (state, { payload }: PayloadAction<IntangibleAssetParams['returnInactiveDataOnly']>) => {
      state.returnInactiveDataOnly = payload;
    },
    setAssetId: (state, { payload }: PayloadAction<IntangibleAssetParams['assetId']>) => {
      state.assetId = payload;
    },
    setAssetIdContains: (state, { payload }: PayloadAction<IntangibleAssetParams['intangibleAssetNumberContains']>) => {
      state.intangibleAssetNumberContains = payload;
    },
    setAssetSkuContains: (state, { payload }: PayloadAction<IntangibleAssetParams['assetSkuContains']>) => {
      state.assetSkuContains = payload;
    },
    setAssetStatus: (state, { payload }: PayloadAction<IntangibleAssetParams['assetStatus']>) => {
      state.assetStatus = payload;
    },
    setAssetOwnerContains: (state, { payload }: PayloadAction<IntangibleAssetParams['assetOwnerContains']>) => {
      state.assetOwnerContains = payload;
    },
    setAssetType: (state, { payload }: PayloadAction<IntangibleAssetParams['assetType']>) => {
      state.assetType = payload;
    },
    setExpirationStartDate: (state, { payload }: PayloadAction<IntangibleAssetParams['expirationStartDate']>) => {
      state.expirationStartDate = payload;
    },
    setExpirationEndDate: (state, { payload }: PayloadAction<IntangibleAssetParams['expirationEndDate']>) => {
      state.expirationEndDate = payload;
    },
    setManufacturerContains: (state, { payload }: PayloadAction<IntangibleAssetParams['manufacturerContains']>) => {
      state.manufacturerContains = payload;
    },
    setOrderByDirection: (state, { payload }: PayloadAction<IntangibleAssetParams['orderByDirection']>) => {
      state.orderByDirection = payload;
    },
    setOrderByField: (state, { payload }: PayloadAction<IntangibleAssetParams['orderByField']>) => {
      state.orderByField = payload;
    },
    setLocationContains: (state, { payload }: PayloadAction<IntangibleAssetParams['locationContains']>) => {
      state.locationContains = payload;
    },
    setSerialNumberContains: (state, { payload }: PayloadAction<IntangibleAssetParams['serialNumberContains']>) => {
      state.serialNumberContains = payload;
    },
    setPhysicalPartNumberContains: (state, { payload }: PayloadAction<IntangibleAssetParams['partNumberContains']>) => {
      state.partNumberContains = payload;
    },
    increaseOffset: (state) => {
      state.offset += 25;
    },
    setOffset: (state, { payload }) => {
      state.offset = payload;
    },
    setTake: (state, { payload }) => {
      state.take = payload;
    },
    resetParams: (state) => {
      Object.assign(state, initialState);
    }
  }
});

export const {
  setIncludeDeletedData,
  setIncludeInactiveData,
  setOrderByDirection,
  increaseOffset,
  setAssetId,
  setAssetSkuContains,
  setAssetStatus,
  setAssetIdContains,
  setAssetOwnerContains,
  setManufacturerContains,
  setAssetType,
  setExpirationStartDate,
  setExpirationEndDate,
  setOrderByField,
  setLocationContains,
  setSerialNumberContains,
  setPhysicalPartNumberContains,
  setReturnDeletedDataOnly,
  setReturnInactiveDataOnly,
  setOffset,
  setTake,
  resetParams
} = captainBreakfastParams.actions;
