import { Space, Typography } from 'antd';
import { FC } from 'react';
import Lottie, { LottieProps } from 'react-lottie-player';
import noResultsLottie from '../../assets/no-result.json';

type Props = LottieProps & {
  title: string;
};

export const NoResults: FC<Props> = ({ title, ...rest }) => {
  return (
    <Space align="center" direction="vertical" size={0}>
      <Lottie loop animationData={noResultsLottie} {...rest} play />
      <Typography.Text style={{ fontSize: 17 }}>{title}</Typography.Text>
    </Space>
  );
};
