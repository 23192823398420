import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Modal, Tooltip, Typography, message } from 'antd';
import { ButtonProps } from 'antd/lib';
import { FC, useState } from 'react';
import Lottie from 'react-lottie-player';
import { useGetAssetAssignmentsQuery } from 'redux/services/captainBreakfast/asset-assignments/assetAssignmentApi';
import { useDeleteInstallBaseMutation } from 'redux/services/captainBreakfast/install-base/installBaseApi';
import deleteLottie from '../../assets/trash.json';

type Props = ButtonProps & {
  installBaseId: string;
};

export const DeleteInstallBaseButton: FC<Props> = ({ installBaseId, ...rest }) => {
  const [deleteInstallBase] = useDeleteInstallBaseMutation();
  const { data: assetData, isLoading, isFetching } = useGetAssetAssignmentsQuery({ installBaseIdEquals: installBaseId });

  const [hover, setHover] = useState(false);
  const handleDelete = async (): Promise<void> => {
    console.log(installBaseId);

    try {
      await deleteInstallBase(installBaseId).unwrap();
      message.success('Record deleted successfully');
    } catch (err) {
      console.log(err);
      message.error('Error deleting record');
    }
  };

  return (
    <Tooltip title={assetData?.data.length ? 'Cannot delete an install base that is currently in use by an asset assignment' : 'Delete'}>
      <Button
        disabled={!!assetData?.data.length}
        loading={isLoading || isFetching}
        onMouseEnter={(): void => setHover(true)}
        onMouseLeave={(): void => setHover(false)}
        style={{ backgroundColor: hover ? '#FFEBEB' : undefined }}
        onClick={(): void => {
          Modal.confirm({
            title: <Typography.Text>{'Are you sure you want to delete this install base?'}</Typography.Text>,
            icon: <WarningOutlined style={{ color: 'red' }} />,
            onOk: handleDelete
          });
        }}
        icon={assetData?.data.length ? <DeleteOutlined /> : <Lottie loop goTo={65} play={hover} style={{ width: 22, height: 22 }} animationData={deleteLottie} />}
        {...rest}
      />
    </Tooltip>
  );
};
