import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { categoryChartGenerator, manufacturerChartGenerator, typeChartGenerator, unassignedChartGenerator } from 'common/helpers/chartDataGenerator';
import { ApiMultipleResponse } from 'models/ApiModels';
import { Division } from 'models/Division';
import { BarChartModel, ChartDataAssetAssignmentModel } from 'models/Reports';
import { ReduxState } from 'redux/store';
import { InstallBaseResponse } from '../install-base/installBaseApi';
import { IntAssetResponse } from '../intangible-assets/intangibleAssetsApi';

/* ******************** Base Query ******************** */
const baseUrl = process.env.REACT_APP_CAPTAIN_BREAKFAST_BASE_URL;
const functionsKey = process.env.REACT_APP_API_HOST_KEY_CAPTAIN_BREAKFAST;

export type AssetAssignmentPayload = {
  installBaseId: string;
  intangibleAssetId: string;
};

export type AssetAssignmentParams = {
  productNumberContains?: string;
  manufacturerNameContains?: string;
  categoryNameContains?: string;
  locationCodeContains?: string;
  serialNumberContains?: string;
  inServiceStartDate?: string;
  inServiceEndDate?: string;
  startDate?: string;
  endDateRangeEnd?: string;
  endDate?: string;
  startDateRangeEnd?: string;
  installBaseNameContains?: string;
  alternateItemIdContains?: string;
  installBaseIdEquals?: string;
  intangibleAssetIdEquals?: string;
  offset?: number;
  take?: number;
};

export type AssetAssignmentResponse = {
  createdByUserFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  division: Division;
  documentType: string;
  endDate: string;
  id: string;
  installBase: InstallBaseResponse;
  intangibleAsset: IntAssetResponse;
  isActive: boolean;
  isDeleted: boolean;
  modifiedByUserFullname: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  partitionKey: string;
  partitionKeyDescription: string;
  startDate: string;
  status: string;
};

export const captainBreakfastBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const divisionId = (api.getState() as ReduxState).app.acuityContext?.selectedCustomer.id;
  const isDiagnostic = typeof args !== 'string' && (args.url === '/diagnostics/version' || args.url === '/diagnostics/apiName');
  const isDivisionNeeded =
    typeof args !== 'string' && (args.url === '/diagnostics/version' || args.url === '/diagnostics/apiName' || args.url === '/intangibleAssetStatuses' || args.url === '/intangibleAssetTypes');

  if (!divisionId && !isDivisionNeeded) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No division ID received'
      }
    };
  }

  const urlEnd = typeof args === 'string' ? args : args.url;
  const adjustedUrl = isDiagnostic ? args.url : `divisions/${divisionId}/${urlEnd}`;
  const adjustedArgs = typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };

  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as ReduxState).app.accessToken;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('x-functions-key', functionsKey);
        headers.set('Content-Type', 'application/json');
      }

      return headers;
    }
  })(adjustedArgs, api, extraOptions);
};

export const assetAssignmentApi = createApi({
  reducerPath: 'assetAssignmentApi',
  baseQuery: captainBreakfastBaseQuery,
  tagTypes: ['Assignments', 'Assignment', 'ChartData'],
  endpoints: (builder) => ({
    getAssetAssignments: builder.query<ApiMultipleResponse<AssetAssignmentResponse>, AssetAssignmentParams>({
      providesTags: ['Assignments'],
      query: (params) => ({
        url: 'intangibleAssetAssignments',
        params,
        responseHandler: (response): Promise<ApiMultipleResponse<AssetAssignmentResponse> | string> => (response.status >= 300 ? response.text() : response.json())
      })
    }),
    getAssetAssignmentsChartData: builder.query<ChartDataAssetAssignmentModel, AssetAssignmentParams>({
      providesTags: ['ChartData'],
      query: (params) => ({
        url: 'intangibleAssetAssignments',
        params
      }),
      transformResponse: (data: ApiMultipleResponse<AssetAssignmentResponse>) => {
        // const assignmentChart = assignmentsChartGenerator(data.data);
        const typeChart = typeChartGenerator(data.data);
        const manuChart = manufacturerChartGenerator(data.data);
        const catChart = categoryChartGenerator(data.data);
        // const ownerChart = ownerChartGenerator(data.data);
        // const expiringChart = expiringChartGenerator(data.data);
        const fullData = data.data;

        return { fullData, typeChart, manuChart, catChart };
      }
    }),
    getUnassignedChartData: builder.query<
      { chartData: BarChartModel; installBases: InstallBaseResponse[]; intangibleAssets: IntAssetResponse[] },
      { installTotalCount: number; iaTotalCount: number; assetTotalCount: number }
    >({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const { assetTotalCount, iaTotalCount, installTotalCount } = arg;
        const installBaseRes = await baseQuery(`installBases?take=${installTotalCount ?? 0}`);
        const intangibleResp = await baseQuery(`intangibleAssets?take=${iaTotalCount ?? 0}`);
        const assignResp = await baseQuery(`intangibleAssetAssignments?take=${assetTotalCount ?? 0}`);

        if (installBaseRes.error) return { error: installBaseRes.error };
        if (intangibleResp.error) return { error: intangibleResp.error };
        if (assignResp.error) return { error: assignResp.error };

        const unassignedChartData = unassignedChartGenerator(
          (assignResp.data as ApiMultipleResponse<AssetAssignmentResponse>).data,
          (installBaseRes.data as ApiMultipleResponse<InstallBaseResponse>).data,
          (intangibleResp.data as ApiMultipleResponse<IntAssetResponse>).data
        );

        return {
          data: {
            chartData: unassignedChartData.chartData,
            installBases: unassignedChartData.installBases as InstallBaseResponse[],
            intangibleAssets: unassignedChartData.intangibleAssets as IntAssetResponse[]
          }
        };
      }
    }),
    getAssetAssignment: builder.query<AssetAssignmentResponse, string>({
      providesTags: ['Assignment'],
      query: (id) => ({
        url: `intangibleAssetAssignments/${id}`,
        responseHandler: (response): Promise<ApiMultipleResponse<AssetAssignmentResponse> | string> => (response.status >= 300 ? response.text() : response.json())
      })
    }),
    createAssetAssignment: builder.mutation<AssetAssignmentResponse, AssetAssignmentPayload>({
      invalidatesTags: ['Assignments'],
      query: (payload) => ({
        url: 'intangibleAssetAssignments',
        method: 'POST',
        body: payload
      })
    }),
    updateAssetAssignment: builder.mutation<AssetAssignmentResponse, { id: string; payload: AssetAssignmentPayload }>({
      invalidatesTags: ['Assignments'],
      query: ({ id, payload }) => ({
        url: `intangibleAssetAssignments/${id}`,
        method: 'PUT',
        body: payload
      })
    }),
    deleteAssetAssignment: builder.mutation<void, string>({
      invalidatesTags: ['Assignments'],
      query: (assetID) => ({
        url: `intangibleAssetAssignments/${assetID}/?wouldYouLikeToPlayAGame=true`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useCreateAssetAssignmentMutation,
  useDeleteAssetAssignmentMutation,
  useGetAssetAssignmentQuery,
  useGetAssetAssignmentsQuery,
  useUpdateAssetAssignmentMutation,
  useGetAssetAssignmentsChartDataQuery,
  useGetUnassignedChartDataQuery
} = assetAssignmentApi;
