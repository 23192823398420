import { Col, Row, Space } from 'antd';
import { AssetCategoryChart } from 'components/organisms/AssetCategoryChart';
import { AssetStatusChart } from 'components/organisms/AssetStatusChart';
import { AssetTypeChart } from 'components/organisms/AssetTypeChart';
import { ExpirationChart } from 'components/organisms/ExpirationChart';
import { UnasignedEntitiesChart } from 'components/organisms/UnasignedEntitiesChart';
import { AntPageTitle } from 'components/ui/AntPageTitle';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetParams } from 'redux/services/captainBreakfast/captainBrekfastParams';

export const Dashboard = (): JSX.Element => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetParams());
  }, []);

  return (
    <Space size={16} direction="vertical">
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <AntPageTitle text="Intangible Assets Dashboard" />
        </Col>
        <Col span={24} style={{ height: '100%' }}>
          <ExpirationChart />
        </Col>
        <Col span={8}>
          <AssetTypeChart />
        </Col>
        <Col span={8}>
          <AssetStatusChart />
        </Col>
        <Col span={8}>
          <AssetCategoryChart />
        </Col>
        <Col span={8}>
          <UnasignedEntitiesChart />
        </Col>
        {/* <Col span={8}>
          <AssetStatusChart />
        </Col>
        <Col span={8}>
          <AssetCategoryChart />
        </Col> */}
        <Col span={8}>{/* <AssetOwnerChart /> */}</Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col span={24} style={{ height: '100%' }}>
          {/* <AssignmentsChart /> */}
        </Col>
      </Row>
    </Space>
  );
};
