import { InstallBasePayload } from 'redux/services/captainBreakfast/install-base/installBaseApi';
import * as yup from 'yup';

export type InstallBase = {
  installBaseId: string;
  productNumber: string;
  locationCode: string;
  serialNumber: string;
  macAddress: string;
  quantity: number;
  ipAddress: string;
  inServiceDate: string;
  status?: string;
};

export const installBaseSchema: yup.SchemaOf<InstallBasePayload> = yup.object({
  name: yup.string().label('Install Base Id').required(),
  productId: yup.string().label('Product Number').required(),
  locationId: yup.string().label('Location Code').required(),
  // tenantId: yup.string().label('Tenant'),
  serialNumber: yup.string().label('Serial Number').required(),
  quantity: yup.number().label('Category').required(),
  macAddress: yup.string().label('Mac Address').notRequired(),
  ipAddress: yup.string().label('Ip Address').notRequired(),
  status: yup.string().label('Status').notRequired(),
  inServiceDate: yup.string().label('In Service Date').required(),
  isActive: yup.boolean(),
  isDeleted: yup.boolean()
});
