import { FancyInput } from 'components/atoms/Inputs';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

export const IntangibleProductNumberFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const [searchParams, setSearchParams] = useSearchParams();

  /* ******************** Functions ******************** */
  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const params = Object.fromEntries([...searchParams]);

    setSearchParams({ ...params, intangibleProductNumberContains: value });
    if (!value) {
      searchParams.delete('intangibleProductNumberContains');
      setSearchParams(searchParams);
    }
  }, 500);

  return <FancyInput width={220} defaultValue={searchParams.get('intangibleProductNumberContains') || ''} placeholder="Intangible Product Number" onChange={handleChange} />;
};
