import { Button, Col, Row, Space } from 'antd';
import { AddAssetButton } from 'components/atoms/AddAssetButton';
import { ClearIdFilter } from 'components/atoms/ClearIdFilter';
import { ManufacturerFilter } from 'components/atoms/Filters';
import { InstallBaseIdContains } from 'components/atoms/Filters/AssetAssignmentsFilters/InstallBaseIdContains';
import { IntangibleProductNumberFilter } from 'components/atoms/Filters/AssetAssignmentsFilters/IntangibleProductNumberFilter';
import { LocationCodeFilter } from 'components/atoms/Filters/AssetAssignmentsFilters/LocationCodeFilter';
import { PhysicalAlternateItemIdFilter } from 'components/atoms/Filters/AssetAssignmentsFilters/PhysicalAlternateItemIdFilter';
import { PhysicalProductNumberFilter } from 'components/atoms/Filters/AssetAssignmentsFilters/PhysicalProductNumberFilter';
import { PresetDateRangePicker } from 'components/atoms/Filters/PresetDateRangePicker';
import { AssetTypeFilter } from 'components/atoms/Filters/ProductFilters/AssetTypeFilter';
import { CategoryFilter } from 'components/atoms/Filters/ProductFilters/CategoryFilter';
import { SerialNumberFilter } from 'components/atoms/Filters/SerialNumberFilter';
import ExportAssetsButton from 'components/atoms/Inputs/ExportAssetsButton';
import { AntPageTitle } from 'components/ui/AntPageTitle';
import { useGetParams } from 'hooks/useGetParams';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetAssetAssignmentsQuery } from 'redux/services/captainBreakfast/asset-assignments/assetAssignmentApi';

export const HomeControls: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { params } = useGetParams([
    'productNumberContains',
    'manufacturerNameContains',
    'categoryNameContains',
    'locationCodeContains',
    'serialNumberContains',
    'inServiceStartDate',
    'inServiceEndDate',
    'startDate',
    'startDateRangeEnd',
    'endDate',
    'endDateRangeEnd',
    'installBaseNameContains',
    'alternateItemIdContains',
    'intangibleAssetNameContains'
  ]);
  const { data, isLoading, isFetching } = useGetAssetAssignmentsQuery(params);

  return (
    <>
      <Row style={{ marginBottom: 16, background: 'rgb(176, 178, 188)', borderRadius: 5, padding: 8 }} justify="space-between">
        <Col>
          <AntPageTitle text="Search Assets" />
        </Col>
        <Col>
          <AddAssetButton />
        </Col>
      </Row>
      <Row style={{ padding: '24px 16px', background: 'white', marginBottom: 15, justifyContent: 'space-between' }}>
        <Col>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <Row gutter={[10, 10]}>
              {(searchParams.get('installBaseIdEquals') || searchParams.get('intangibleAssetIdEquals')) && (
                <Col>
                  <ClearIdFilter />
                </Col>
              )}
              <Col>
                <PhysicalProductNumberFilter />
              </Col>
              <Col>
                <PhysicalAlternateItemIdFilter />
              </Col>
              <Col>
                <ManufacturerFilter />
              </Col>
              <Col>
                <CategoryFilter />
              </Col>
              <Col>
                <AssetTypeFilter />
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col>
                <LocationCodeFilter />
              </Col>
              <Col>
                <SerialNumberFilter />
              </Col>
              <Col>
                <InstallBaseIdContains />
              </Col>
              <Col>
                <IntangibleProductNumberFilter />
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col>
                <PresetDateRangePicker style={{ width: 375 }} type="inService" startPlaceholer="In Service Start Range" endPlaceholder="In Service End Range" />
              </Col>
              <Col>
                <PresetDateRangePicker style={{ width: 315 }} type="start" startPlaceholer="Start Date Range" endPlaceholder="Start Date Range" />
              </Col>
              <Col>
                <PresetDateRangePicker style={{ width: 315 }} type="end" startPlaceholer="End Date Range" endPlaceholder="End Date Range" />
              </Col>
            </Row>
          </Space>
        </Col>
        <Space align="start">
          <Button disabled>Import</Button>
          <ExportAssetsButton disabled={!data?.data} loading={isLoading || isFetching} exportDocumentName="asset-assignments" data={data?.data ?? []} />
        </Space>
      </Row>
    </>
  );
};
