import { ButtonProps, Descriptions } from 'antd';
import { DescriptionsProps } from 'antd/lib';
import { FC } from 'react';

type Props = ButtonProps & {
  items: DescriptionsProps['items'];
};

export const IntangibleAssetHeaderDetails: FC<Props> = ({ items }) => {
  return <Descriptions layout="vertical" items={items} size="small" bordered column={6} labelStyle={{ color: 'black' }} />;
};
