import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import dayjs from 'dayjs';
import { ApiMultipleResponse } from 'models/ApiModels';
import { Division } from 'models/Division';
import { installBaseSchema } from 'models/InstallBase';
import { Address } from 'redux/services/chuckieSue/models/addresses';
import { setImportLoadingMessage, setIsImporting } from 'redux/slices/importSlice';
import { ReduxState } from 'redux/store';
import { ProductResponse } from '../products/productsApi';

/* ******************** Base Query ******************** */
const baseUrl = process.env.REACT_APP_CAPTAIN_BREAKFAST_BASE_URL;
const functionsKey = process.env.REACT_APP_API_HOST_KEY_CAPTAIN_BREAKFAST;

export type LineItemErrorObject = {
  installBaseId: string;
  errorMessage: string;
};
export type LineItemErrorObjectAsset = {
  intangibleAssetId: string;
  errorMessage: string;
};
export type LineItemErrorObjectProduct = {
  productNumber: string;
  errorMessage: string;
};

export type InstallBasePayload = {
  name: string;
  productId: string;
  locationId: string;
  serialNumber: string;
  quantity: number | null;
  macAddress?: string;
  ipAddress?: string;
  status?: string;
  inServiceDate: string | dayjs.Dayjs;
  isActive?: boolean;
  isDeleted?: boolean;
  // tenantId?: string;
};

export type InstallBaseParams = {
  productNumberContains?: string;
  nameContains?: string;
  locationCodeContains?: string;
  offset?: number;
  take?: number;
  nameEquals?: string;
  overrideSkipTake?: boolean;
};

export type Prodizzy = {
  alternateItemId: string;
  category: {
    description: string;
    documentType: string;
    id: string;
    name: string;
  };
  id: string;
  isActive: boolean;
  description: string;
  productNumber: string;
  manufacturer: {
    description: string;
    documentType: string;
    id: string;
    name: string;
  };
  productType: string;
};

export type InstallBaseResponse = {
  createdByUserFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  division: Division;
  documentType: string;
  id: string;
  inServiceDate: string;
  ipAddress: string;
  isActive: boolean;
  isDeleted: boolean;
  location: {
    city: string;
    code: string;
    country: string;
    id: string;
    name: string;
    postalCode: string;
    state: string;
    street1: string;
    street2: string | null;
  };
  macAddress: string;
  modifiedByUserFullname: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  name: string;
  partitionKey: string;
  partitionKeyDescription: string;
  product: {
    category: {
      description: string;
      documentType: string;
      id: string;
      name: string;
    };
    id: string;
    isActive: boolean;
    description: string;
    productNumber: string;
    alternateItemId: string;
    manufacturer: {
      description: string;
      documentType: string;
      id: string;
      name: string;
    };
    productType: string;
  };
  quantity: number;
  serialNumber: string;
  status: string;
};

export type QueryErrorModel = FetchBaseQueryError & { data: { errorMessage: string } };

export const captainBreakfastBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const divisionId = (api.getState() as ReduxState).app.acuityContext?.selectedCustomer.id;
  const isDiagnostic = typeof args !== 'string' && (args.url === '/diagnostics/version' || args.url === '/diagnostics/apiName');
  const isDivisionNeeded =
    typeof args !== 'string' && (args.url === '/diagnostics/version' || args.url === '/diagnostics/apiName' || args.url === '/intangibleAssetStatuses' || args.url === '/intangibleAssetTypes');

  if (!divisionId && !isDivisionNeeded) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No division ID received'
      }
    };
  }

  const urlEnd = typeof args === 'string' ? args : args.url;
  const adjustedUrl = isDiagnostic ? args.url : `divisions/${divisionId}/${urlEnd}`;
  const adjustedArgs = typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };

  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as ReduxState).app.accessToken;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('x-functions-key', functionsKey);
        headers.set('Content-Type', 'application/json');
      }

      return headers;
    }
  })(adjustedArgs, api, extraOptions);
};

export const installBaseApi = createApi({
  reducerPath: 'installBaseApi',
  baseQuery: captainBreakfastBaseQuery,
  tagTypes: ['Bases', 'Base'],
  endpoints: (builder) => ({
    getInstallBases: builder.query<ApiMultipleResponse<InstallBaseResponse>, InstallBaseParams>({
      providesTags: ['Bases'],
      query: (params) => ({
        url: 'installBases',
        params,
        responseHandler: (response): Promise<ApiMultipleResponse<InstallBaseResponse> | string> => (response.status >= 300 ? response.text() : response.json())
      })
    }),

    getInstallBase: builder.query<InstallBaseResponse, string>({
      providesTags: ['Base'],
      query: (id) => ({
        url: `installBases/${id}`,
        responseHandler: (response): Promise<ApiMultipleResponse<InstallBaseResponse> | string> => (response.status >= 300 ? response.text() : response.json())
      })
    }),
    createInstallBase: builder.mutation<InstallBaseResponse, InstallBasePayload>({
      invalidatesTags: ['Bases'],
      query: (payload) => ({
        url: 'installBases',
        method: 'POST',
        body: payload
      })
    }),
    updateInstallBase: builder.mutation<InstallBaseResponse, { id: string; payload: InstallBasePayload }>({
      invalidatesTags: ['Bases'],
      query: ({ id, payload }) => ({
        url: `installBases/${id}`,
        method: 'PUT',
        body: payload
      })
    }),
    deleteInstallBase: builder.mutation<void, string>({
      invalidatesTags: ['Bases'],
      query: (assetID) => ({
        url: `installBases/${assetID}/?wouldYouLikeToPlayAGame=true`,
        method: 'DELETE'
      })
    }),
    importInstallBase: builder.mutation<{ data: string; lineErrors: LineItemErrorObject[] }, InstallBasePayload[]>({
      invalidatesTags: ['Bases'],
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const token = (queryApi.getState() as ReduxState).app.accessToken;
        const acuityContext = (queryApi.getState() as ReduxState).app.acuityContext;
        const businessId = acuityContext?.selectedCustomer.business.id;

        queryApi.dispatch(setIsImporting(true));

        const lineErrors: LineItemErrorObject[] = [];

        for await (const [idx, payloadLine] of arg.entries()) {
          queryApi.dispatch(setImportLoadingMessage(`Importing Install Base Line Item ${idx + 1} of ${arg.length}`));

          try {
            await installBaseSchema.validate(payloadLine);
          } catch (err) {
            console.log(err);
            lineErrors.push({ installBaseId: payloadLine.name ?? 'Error', errorMessage: (err as { message: string }).message });
            continue;
          }

          const getProductResult = await baseQuery(`/products?productNumberContains=${payloadLine.productId}`);

          if (getProductResult.error) {
            const err = getProductResult.error as QueryErrorModel;

            console.log(getProductResult.error, 'product number error');
            lineErrors.push({ installBaseId: payloadLine.name, errorMessage: err.data.errorMessage });
            continue;
          }

          if ((getProductResult.data as ApiMultipleResponse<ProductResponse>).totalCount === 0) {
            lineErrors.push({ installBaseId: payloadLine.name, errorMessage: 'Product Number not found' });
            continue;
          }

          const locationsResult = await fetch(`${process.env.REACT_APP_CHUCKIE_SUE_BASE_URL}/businesses/${businessId}/addresses/?addressCodeContains=${payloadLine.locationId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'x-functions-key': `${process.env.REACT_APP_API_HOST_KEY_CHUCKIE_SUE}`,
              authorization: `Bearer ${token}`,
              'Access-Control-Allow-Origin': '*'
            }
          });

          if (!locationsResult.ok) {
            lineErrors.push({ installBaseId: payloadLine.name, errorMessage: 'Unknown error validating location, the team has been notified' });
            continue;
          }
          const locationData = await locationsResult.json();

          if (locationData.totalCount === 0) {
            lineErrors.push({ installBaseId: payloadLine.name, errorMessage: 'Location not found' });
            continue;
          }

          const currentLocation = locationData.data.find((item: Address) => item.code === payloadLine.locationId);

          const currentProduct = (getProductResult.data as ApiMultipleResponse<ProductResponse>).data.find((item) => item?.productNumber === payloadLine.productId);

          const payload = {
            ...payloadLine,
            productId: currentProduct?.id,
            locationId: currentLocation?.id,
            inServiceDate: dayjs(payloadLine.inServiceDate).utc().format('YYYY-MM-DD HH:mm:ss[Z]')
          };

          const createResult = await baseQuery({ url: `/installBases`, method: 'POST', body: payload });

          if (createResult.error) {
            const err = createResult.error as QueryErrorModel;

            console.log(createResult.error, 'create error');

            lineErrors.push({ installBaseId: payloadLine.name, errorMessage: err.data.errorMessage });

            continue;
          }
        }

        queryApi.dispatch(setIsImporting(false));

        return { data: { data: 'Success', lineErrors } };
      }
    })
  })
});

export const {
  useGetInstallBasesQuery,
  useCreateInstallBaseMutation,
  useDeleteInstallBaseMutation,
  useUpdateInstallBaseMutation,
  useGetInstallBaseQuery,
  useLazyGetInstallBasesQuery,
  useImportInstallBaseMutation
} = installBaseApi;
