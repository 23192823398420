import { skipToken } from '@reduxjs/toolkit/query';
import { Button, Row, Space, Spin, message } from 'antd';
import { MessageType } from 'antd/es/message/interface';
import { DescriptionsProps } from 'antd/lib';
import { ManufacturerFilter } from 'components/atoms/Filters';
import { LocationCodeFilter } from 'components/atoms/Filters/AssetAssignmentsFilters/LocationCodeFilter';
import { InstallBaseIdContains } from 'components/atoms/Filters/InstallBaseFilters/InstallBaseIdContains';
import { AlternateItemIdFilter } from 'components/atoms/Filters/ProductFilters/AlternateItemIdFilter';
import { CategoryFilter } from 'components/atoms/Filters/ProductFilters/CategoryFilter';
import { ProductNumberFilter } from 'components/atoms/Filters/ProductFilters/ProductNumberFilter';
import { ColoredCard } from 'components/common/ColoredCard';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import { AssignInstallBaseTable } from 'components/molecules/AssignInstallBaseTable';
import { IntangibleAssetHeaderDetails } from 'components/molecules/IntangibleAssetHeaderDetails';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateAssetAssignmentMutation } from 'redux/services/captainBreakfast/asset-assignments/assetAssignmentApi';
import { intangibleAssetsApi, useGetIntangibleAssetQuery } from 'redux/services/captainBreakfast/intangible-assets/intangibleAssetsApi';
import { setSelectedInstallBases, setSelectedRowKeys } from 'redux/slices/selectedEntitiesToAssign';
import { useAppSelector } from 'redux/store';

export const AssignInstallBases = (): JSX.Element => {
  const { id } = useParams();
  const { data, isLoading, isFetching } = useGetIntangibleAssetQuery(id ?? skipToken);
  const [createAssetAssignment, { isLoading: isCreating }] = useCreateAssetAssignmentMutation();
  const { selectedInstallBases } = useAppSelector((state) => state.selectedEntitiesToAssign);
  const [itemCount, setItemCount] = useState<number>(1);
  const dispatch = useDispatch();

  const nav = useNavigate();

  const handleSubmit = async (): Promise<void | MessageType> => {
    if (!id) return message.error('No intangible asset id found');

    for (const selectedBase of selectedInstallBases) {
      try {
        await createAssetAssignment({ intangibleAssetId: id, installBaseId: selectedBase.id }).unwrap();
        setItemCount((prevState) => prevState + 1);
      } catch (err) {
        console.log(err, 'err');
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
        break;
      }
    }
    message.success(`Asset assignments were created successfully`);
    dispatch(intangibleAssetsApi.util.invalidateTags(['Assets', 'Asset']));
    dispatch(setSelectedInstallBases([]));
    dispatch(setSelectedRowKeys([]));
    setItemCount(1);
  };

  useEffect(() => {
    return () => {
      dispatch(setSelectedInstallBases([]));
      dispatch(setSelectedRowKeys([]));
    };
  }, []);

  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Intangible Asset',
      children: data?.name
    },
    {
      key: '2',
      label: 'Type',
      children: data?.type
    },
    {
      key: '3',
      label: 'Quantity',
      children: data?.quantity
    },
    {
      key: '4',
      label: 'Quantity Available',
      children: data?.availableQuantity
    },
    {
      key: '5',
      label: 'Product Number',
      children: data?.product?.productNumber
    },
    {
      key: '6',
      label: 'Manufacturer',
      children: data?.product?.manufacturer?.name
    }
  ];

  if (isLoading) return <LoaderWithMessage loadingMessage="Loading" />;

  return (
    // <FormikProvider value={formik}>
    <Spin
      style={{ display: 'flex', alignItems: 'center' }}
      spinning={isCreating}
      indicator={<LoaderWithMessage loadingMessage={`Creating asset assignment ${itemCount} of ${selectedInstallBases.length}`} />}>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <ColoredCard
          bodyStyle={{ padding: 0 }}
          title="Intangible Asset Details"
          color="rgb(255, 234, 193)"
          icon={<Button onClick={(): void => nav(`/intangible-assets?nameContains=${data?.name}`)}>Back</Button>}>
          <IntangibleAssetHeaderDetails loading={isFetching} items={items} />
        </ColoredCard>
        <ColoredCard
          bodyStyle={{ padding: 12 }}
          title="Assign Install Bases"
          color="rgb(176, 178, 188)"
          icon={
            <Button style={{ background: !selectedInstallBases.length ? 'white' : undefined }} disabled={!selectedInstallBases.length} type="primary" onClick={handleSubmit}>
              Assign Selected ({selectedInstallBases.length})
            </Button>
          }>
          <Space size={28} direction="vertical" style={{ width: '100%' }}>
            <Row style={{ padding: 8 }} justify={'space-between'}>
              <Space>
                <InstallBaseIdContains />
                <AlternateItemIdFilter />
                <LocationCodeFilter />
                <ProductNumberFilter />
                <ManufacturerFilter />
                <CategoryFilter />
              </Space>
            </Row>
            <Row>
              <AssignInstallBaseTable quantity={data?.availableQuantity} />
            </Row>
          </Space>
        </ColoredCard>
      </Space>
    </Spin>
  );
};
