import { Table, Typography } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import { LoaderWithMessage } from 'components/common/LoaderWithMessage';
import dayjs from 'dayjs';
import { useGetParams } from 'hooks/useGetParams';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { IntAssetResponse, useGetIntangibleAssetsQuery } from 'redux/services/captainBreakfast/intangible-assets/intangibleAssetsApi';
import { setSelectedIntangibleAssets, setSelectedRowKeys } from 'redux/slices/selectedEntitiesToAssign';
import { useAppSelector } from 'redux/store';

export const AssignIntangibleAssetTable: FC<TableProps<IntAssetResponse>> = ({ ...rest }): JSX.Element => {
  const { params } = useGetParams(['productNumberContains', 'nameContains', 'manufacturerNameContains', 'categoryNameContains', 'types']);
  const { data, isLoading, isFetching } = useGetIntangibleAssetsQuery({ ...params, overrideSkipTake: true });
  const dispatch = useDispatch();
  const { selectedRowKeys } = useAppSelector((state) => state.selectedEntitiesToAssign);

  const columns: ColumnsType<IntAssetResponse> = [
    {
      title: 'Intangible Asset Id',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Product Number',
      dataIndex: 'productNumber',
      key: 'productNumber',
      render: (_, record) => <div>{record.product.productNumber}</div>
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => <div>{record.type}</div>
    },
    {
      title: 'Manufacturer',
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      render: (_, record) => <div>{record.product.manufacturer.name}</div>
    },
    {
      title: 'Contract',
      dataIndex: 'contract',
      key: 'contract'
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center'
    },
    {
      title: 'Qty Available',
      dataIndex: 'availableQuantity',
      key: 'availableQuantity',
      align: 'center'
    },
    {
      title: 'Start Date',
      dataIndex: 'startDAte',
      key: 'startDAte',
      render: (_, record): JSX.Element => {
        const formatedAddress = dayjs(record.startDate).format('MM-DD-YYYY');

        return <Typography>{formatedAddress}</Typography>;
      }
    },
    {
      title: 'End Date',
      dataIndex: 'endDAte',
      key: 'endDAte',
      render: (_, record): JSX.Element => {
        const formatedAddress = dayjs(record.endDate).format('MM-DD-YYYY');

        return <Typography>{formatedAddress}</Typography>;
      }
    }
  ];

  const rowSelection = {
    checkStrictly: true,
    onChange: (selectedRowKeys: React.Key[], selectedRows: IntAssetResponse[]): void => {
      dispatch(setSelectedIntangibleAssets(selectedRows));
      dispatch(setSelectedRowKeys(selectedRowKeys));
    },
    getCheckboxProps: (record: IntAssetResponse): { disabled: boolean; name: string } => ({
      disabled: record.availableQuantity <= 0 ? true : false,
      name: record.name
    }),
    selectedRowKeys
  };

  if (isLoading) return <LoaderWithMessage loadingMessage="Loading" />;

  return (
    <Table
      loading={{ spinning: isFetching, indicator: <LoaderWithMessage loadingMessage="Loading" /> }}
      dataSource={data?.data}
      size="small"
      style={{ width: '100%' }}
      columns={columns}
      rowKey={(item): string => item.id}
      {...rest}
    />
  );
};
