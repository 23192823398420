import { SettingOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';
import { ColoredCard } from 'components/common/ColoredCard';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { InstallBaseResponse } from 'redux/services/captainBreakfast/install-base/installBaseApi';
import { IntAssetResponse } from 'redux/services/captainBreakfast/intangible-assets/intangibleAssetsApi';

type Props = {
  installBases?: InstallBaseResponse[];
  intangibleAssets?: IntAssetResponse[];
};

export const DashboardButton: FC<Props> = ({ installBases, intangibleAssets }) => {
  const nav = useNavigate();
  const handleClick = (): void => {
    Modal.info({
      content: (
        <ColoredCard title="Unassigned Entities" color="rgb(206, 213, 242)">
          <Row>
            <Col span={12}>
              <h3>Install Bases</h3>

              {installBases?.map((base) => (
                <Row key={base.id}>
                  <Button
                    onClick={(): void => {
                      nav(`/install-base?nameContains=${base.name}`);
                      Modal.destroyAll();
                    }}
                    type="link">
                    {base.name}
                  </Button>
                </Row>
              ))}
            </Col>
            <Col span={12}>
              <h3>Intangible Assets</h3>
              {intangibleAssets?.map((base) => (
                <Row key={base.id}>
                  <Button
                    onClick={(): void => {
                      nav(`/intangible-assets?nameContains=${base.name}`);
                      Modal.destroyAll();
                    }}
                    type="link">
                    {base.name}
                  </Button>
                </Row>
              ))}
            </Col>
          </Row>
        </ColoredCard>
      ),
      width: 750,
      icon: null,
      okText: 'Close'
    });
  };

  return <Button onClick={handleClick} icon={<SettingOutlined />} />;
};
